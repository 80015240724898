<template>
  <div class="w-[20%] h-full border border-zinc-500/30 rounded-md mx-2 my-4 p-3 flex flex-col items-center justify-between">
    <div class="w-full flex flex-col justify-center items-center">
      <div class="bg-zinc-700 py-0 px-2 border border-white rounded-sm text-sm text-yellow-500">
        {{ text('ui.rank') }}: <span class="font-bold">#{{ props.opponent.player_inn_submode_state.current_rank }}</span>
      </div>
      <div class="mt-1 w-full text-center text-xs">
        <UserNameplate :username="props.opponent.player_display_name || 'Anonymous' "/>
      </div>
    </div>
    <div
      :id="`inn_opponent_squad_view_${props.opponent_index}`"
      class="grow w-full"
    />
    <DTButton
      type="error"
      @click="onBurgleClick"
      class="-mb-6"
    >
      <div class="py-1 px-2 flex items-center">
        <div class="text-base">
          {{ text('ui.burgle') }}
        </div>
        <div class="ml-2 px-1 bg-white border border-red-500 rounded-sm text-sm text-black ">
          {{ state.tickers.num_peanuts_to_steal }}
        </div>
      </div>
    </DTButton>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, reactive } from 'vue';
import Balance from 'dt-common/constants/Balance';
import getNumHeroesInSubmode from 'dt-common/isomorphic-helpers/getNumHeroesInSubmode';
import { InnActions } from '~/flux/actions';
import { GameStateStore, UIStore } from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import { EngagedSquadView } from '~/view/components/common/canvas';
import { DTButton, UserNameplate } from '~/view/components/common/DOM';

let _squad_view;
let _tickers_interval;

const props = defineProps({
  opponent: {
    type: Object,
    required: true,
  },
  opponent_index: {
    type: Number,
    required: true,
  },
});

const state = reactive({
  tickers: {},
});

function calcTickers() {
  const { current_rank, last_num_peanuts, last_rank_change_at } = props.opponent.player_inn_submode_state;
  const now = Date.now();

  // calc & rendernum peanuts
  const ms_elapsed_since_last_rank_change = now - last_rank_change_at;
  const num = Math.floor(
    last_num_peanuts + ms_elapsed_since_last_rank_change * Balance.getInnPeanutsPerMSByRank(current_rank),
  );
  state.tickers.num_peanuts_to_steal = Tools.formatGameCurrency(num / 10);
}

onMounted(() => {
  makeEngagedSquadView();
  _tickers_interval = setInterval(calcTickers, 1000);
  calcTickers();
});

onBeforeUnmount(() => {
  disposeCanvas();
  clearInterval(_tickers_interval);
});

function disposeCanvas() {
  _squad_view?.dispose();
}

function makeEngagedSquadView({ do_transition_in = true } = {}) {
  _squad_view?.dispose();

  const num_heroes = getNumHeroesInSubmode(UIStore.getAll().current_game_submode);
  let puppet_scale = 1
  switch(num_heroes) {
    case 1: puppet_scale = 1.42; break;
    case 2: puppet_scale = 1.27; break;
    case 3: puppet_scale = 0.92; break;
    case 5:
    case 7:
      puppet_scale = 0.69; break;
    // case 11: puppet_scale = 0.25; break;
  }

  const width_coefficient = window.innerWidth * 0.087;

  _squad_view = new EngagedSquadView({
    hero_builds: props.opponent.hero_builds,
    is_opponent: true,
    loadout_squad_list: props.opponent.loadout.filter(hero => !!hero.engagement),
    roster_heroes: props.opponent.roster_heroes,
    puppet_scale,
    do_transition_in,
    getHorizontalPuppetSpacing: ({ puppet_index }) => {
      switch(num_heroes) {
        case 1: return puppet_scale * 23;
        case 2: return 78 - puppet_index * puppet_scale * 70 + 5;
        case 3: return 105 - puppet_index * puppet_scale * 65 + 5;
        case 5: {
          const MAGIC = 55;
          switch (puppet_index) {
            case 1: return 17 + MAGIC - MAGIC * 0.9 * puppet_scale;
            case 3: return 17 + MAGIC + MAGIC * 0.9 * puppet_scale;
            case 0: return 17 + MAGIC - MAGIC * 1.8 * puppet_scale;
            case 2: return 15 + MAGIC;
            case 4: return 15 + MAGIC + MAGIC * 1.8 * puppet_scale;
          }
        }
        case 7: {
          const MAGIC = 110;
          switch (puppet_index) {
            case 5: return width_coefficient * -0.225 + MAGIC - MAGIC * 0.9 * puppet_scale;
            case 3: return width_coefficient * -0.225 + MAGIC;
            case 1: return width_coefficient * -0.225 + MAGIC + MAGIC * 0.9 * puppet_scale;
            case 6: return width_coefficient * -0.225 + MAGIC - MAGIC * 1.35 * puppet_scale;
            case 4: return width_coefficient * -0.225 + MAGIC - MAGIC * 0.45 * puppet_scale;
            case 2: return width_coefficient * -0.225 + MAGIC + MAGIC * 0.45 * puppet_scale;
            case 0: return width_coefficient * -0.225 + MAGIC + MAGIC * 1.35 * puppet_scale;
          }
        }
        default: return puppet_index * puppet_scale * 60;
      }
    },
    getVerticalPuppetSpacing: ({ puppet_index }) => {
      switch(num_heroes) {
        case 1: 
          return DT_CANVAS_GLOBALS.spacing * 5;
        case 2:
          return DT_CANVAS_GLOBALS.spacing * 6 - puppet_index * DT_CANVAS_GLOBALS.spacing * 3
        case 3:
          switch (puppet_index) {
            case 0: return puppet_scale * 15;
            case 1: return puppet_scale * 80;
            case 2: return puppet_scale * 15;
          }
        case 5: {
          switch (puppet_index) {
            case 1: return puppet_scale * 90;
            case 0: return puppet_scale * -15;
            case 4: return puppet_scale * -15 + DT_CANVAS_GLOBALS.spacing * 6;
            case 3: return puppet_scale * 90 + DT_CANVAS_GLOBALS.spacing * 3;
            case 2: return puppet_scale * -15 + DT_CANVAS_GLOBALS.spacing * 3;
          }
        }
        case 7: {
          switch (puppet_index) {
            case 5: return puppet_scale * 65;
            case 3: return puppet_scale * 65 + DT_CANVAS_GLOBALS.spacing * 3;
            case 1: return puppet_scale * 65 + DT_CANVAS_GLOBALS.spacing * 6;
            case 6: return puppet_scale * -40;
            case 4: return puppet_scale * -40 + DT_CANVAS_GLOBALS.spacing * 3;
            case 2: return puppet_scale * -40 + DT_CANVAS_GLOBALS.spacing * 6;
            case 0: return puppet_scale * -40 + DT_CANVAS_GLOBALS.spacing * 9;
          }
        }
      }
    },
  });
  const dom_anchor = document.getElementById(`inn_opponent_squad_view_${props.opponent_index}`);
  const { x, y, width, height } = dom_anchor.getBoundingClientRect();
  _squad_view.x = x + width / 2 - 50 / puppet_scale;
  _squad_view.y = y + height * 0.333;
  DT_CANVAS_GLOBALS.stage.addChild(_squad_view);
}

function onBurgleClick() {
  const { loadouts } = GameStateStore.getAll().gameState;
  const { current_game_mode, current_game_submode } = UIStore.getAll();
  InnActions.startAttackPrep({
    attack_loadout: loadouts[current_game_mode][current_game_submode], // default to defense squad
    opponent: props.opponent,
  });
}
</script>