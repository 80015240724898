import * as PIXI from 'pixi.js';
import FaerieSprite from '~/view/game-screens//battle/canvas/game_board/piece_sprites/FaerieSprite';

const CanvasTools = {

  getBriefString: function(value) {
    value = Math.floor(value);

    if (value >= 1000000000000) {
      return (value/1000000000000).toFixed(1)+'t';
    } else if (value >= 1000000000) {
      return (value/1000000000).toFixed(1)+'b';
    } else if (value >= 1000000) {
      return (value/1000000).toFixed(1)+'m';
    } else if (value >= 1000) {
      return (value/1000).toFixed(1)+'k';
    } else {
      return value.toFixed(0);
    }
  },

  dynamicFontSizeString: function(maxSize) {
    return Math.max(6, Math.min(maxSize, maxSize*(window.innerWidth/1000))).toString()+'px';
  },

  addBackFill: function(container, color, alpha=1.0, to_zero_index=true) {
    var bg = new PIXI.Graphics();
    bg.beginFill(color, alpha);
    bg.drawRect(0, 0, container._width, container._height);
    bg.endFill();
    if (to_zero_index) {
      container.addChildAt(bg, 0);
    } else {
      container.addChild(bg);
    }
    return bg;
  },

  addBorder: function(container, topTexPath, rightTexPath, bottomTexPath, leftTexPath, padding) {
    if (container.hasBorder) {
      return;
    }

    container.borderTiles = [];

    var tile1, tile2;
    var texPath;

    if (topTexPath || bottomTexPath) {
      // do horizontal border tiling
      const widthToTile = container._width - padding*2;
      var tiledWidth = 0;

      const addHorizTile = (top) => {
        if (top) {
          texPath = topTexPath;
        } else {
          texPath = bottomTexPath;
        }

        if (!texPath) {
          return null;
        }

        const tile = new PIXI.Sprite(PIXI.utils.TextureCache[texPath]);
        tile.x = Math.round(padding + tiledWidth);
        tile.y = Math.round(top? padding : container._height - padding - tile.height);
        container.addChild(tile);
        container.borderTiles.push(tile);
        return tile;
      };

      const addHorizMask = (tile) => {
        var mask = new PIXI.Graphics();
        mask.drawRect(0, -tile.height/2, tile.width-tiledWidth+widthToTile, tile.height*2);
        tile.addChild(mask);
        tile.mask = mask;
      };

      while (tiledWidth < widthToTile) {
        tile1 = addHorizTile(true);
        tile2 = addHorizTile(false);

        tiledWidth += tile1.width;

        if (tiledWidth > widthToTile) {
          if (topTexPath) addHorizMask(tile1);
          if (bottomTexPath) addHorizMask(tile2);
        }
      }
    }

    if (leftTexPath || rightTexPath) {
      // do vertical border tiling
      const heightToTile = container._height - padding*2;
      var tiledHeight = 0;

      const addVertTile = (left) => {
        if (left) {
          texPath = leftTexPath;
        } else {
          texPath = rightTexPath;
        }

        if (!texPath) {
          return null;
        }

        var tile = new PIXI.Sprite()
        tile.texture = PIXI.utils.TextureCache[texPath];
        tile.x = Math.round(left? padding : container._width - padding - tile.width);
        tile.y = Math.round(padding + tiledHeight);
        container.addChild(tile);
        container.borderTiles.push(tile);
        return tile;
      };

      const addVertMask = (tile) => {
        var mask = new PIXI.Graphics();
        mask.drawRect(-tile.width/2, 0, tile.width*2, tile.height-tiledHeight+heightToTile);
        tile.addChild(mask);
        tile.mask = mask;
      };

      while (tiledHeight < heightToTile) {
        tile1 = addVertTile(true);
        tile2 = addVertTile(false);

        tiledHeight += tile1.height;

        if (tiledHeight > heightToTile) {
          if (leftTexPath) addVertMask(tile1);
          if (rightTexPath) addVertMask(tile2);
        }
      }
    }

    container.hasBorder = true;
    return container;
  },

  flipHorizontal: function(obj) {
    if (obj) {
      obj.scale.x = -obj.scale.x;
    }
  },

  getResponsiveY: function(coefficient) {
    	var header = window.document.getElementById('header');
    var body = window.document.getElementById('body');
    return header.clientHeight + body.clientHeight/2 + coefficient/window.innerHeight;
  },

  getTooltipX: function(mouseX, ttWidth) {
    var ttX = mouseX > window.innerWidth/2? mouseX-ttWidth-60 : mouseX+60;

    if (ttX < 15) {
      return 15;
    }

    if (ttX + ttWidth > window.innerWidth) {
      return window.innerWidth - ttWidth - 15;
    }

    return ttX;
  },

  getTooltipY: function(mouseY, ttHeight) {
    var ttY = mouseY > window.innerHeight/2? mouseY-ttHeight-30 : mouseY+30;

    if (ttY < 5) {
      return 5;
    }

    if (ttY + ttHeight > window.innerHeight) {
      var result = window.innerHeight - ttHeight - 15;
      return result < 5? 5 : result;
    }

    return ttY;
  },

  getBattlePopupX: function(mouseX, ttWidth) {
    var ttX = mouseX > window.innerWidth/2? mouseX+ttWidth*0.6 : mouseX-ttWidth*0.6;

    if (ttX < ttWidth*0.6) {
      return ttWidth*0.6;
    }

    if (ttX + ttWidth > window.innerWidth) {
      return window.innerWidth - ttWidth - 15;
    }

    return ttX;
  },

  getBattlePopupY: function(mouseY, ttHeight) {
    var ttY = mouseY - ttHeight*0.6;

    if (ttY < ttHeight*0.6) {
      return ttHeight*0.6;
    }

    if (ttY + ttHeight*0.6 > window.innerHeight) {
      return window.innerHeight - ttHeight*0.6;
    }

    return ttY;
  },

  getDomAnchor: id => {
    const ele = document.getElementById(id);
    return ele?.getBoundingClientRect();
  },

  angleToVector: function(angleInRadians, magnitude) {
    var vector = {};
    vector.x = Math.sin(angleInRadians) * magnitude;
    vector.y = Math.cos(angleInRadians) * magnitude;

    return vector;
  },

  // returns angle in radians
  angleFromSpriteToSprite: function(s1, s2) {
    var dx = s2.x - s1.x;
    var dy = s2.y - s1.y;
    // return 180 - Math.atan2( dx,dy ) * 180/Math.PI; // degrees
    return Math.PI - Math.atan2(dx, dy);
  },

  distanceFromSpriteToSprite: function(s1, s2) {
    var xDist = Math.abs(s1.x - s2.x);
    var yDist = Math.abs(s1.y - s2.y);

    return Math.sqrt(xDist*xDist + yDist*yDist);
  },

  makeFaerieSpinners: (home_sprite, num=12) => {
    const _spinners = [];
    const global_pos = home_sprite.toGlobal(new PIXI.Point(0, 0));

    // n random
    for (let i = 0; i < num; ++i) {
      const s = new FaerieSprite();
      _spinners.push(s);

      s.x = global_pos.x - 5 + Math.random() * 10;
      s.y = global_pos.y - 5 + Math.random() * 10;
      s.alpha = 0.0;

      TweenMax.to(s, 0.2, {
        x: global_pos.x - home_sprite.width / 6 + Math.random() * home_sprite.width / 3,
        y: global_pos.y - home_sprite.height / 5 + Math.random() * home_sprite.height / 2,
        alpha: 0.72,
        ease: Quad.easeIn,
      }).delay(i * 0.02);

      // guarantee each spinner is born to die
      setTimeout(hideSingleSpinner, 1100, s);

      s.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
      DT_CANVAS_GLOBALS.stage.addChild(s);
    }

    return _spinners;
  },

  hideFaerieSpinners: (dead_spinners) => {
    if (Array.isArray(dead_spinners)) {
      dead_spinners.forEach(hideSingleSpinner);
    }
  },

  makePixiRenderer({ canvas_ele, canvas_id }) {
    if (!canvas_ele) {
      canvas_ele = document.getElementById(canvas_id);
    }
    const { height, width } = canvas_ele.getBoundingClientRect();

    const app = new PIXI.Application({
      antialias: true,
      backgroundAlpha: 0,
      height,
      resizeTo: canvas_ele,
      resolution: window.devicePixelRatio || 1,
      sharedLoader: true,
      sharedTicker: true,
      view: canvas_ele,
      width,
    });

    return app;
  },
};
export default CanvasTools;

const hideSingleSpinner = (s) => {
  try {
    if (s.dispose_tween) {
      s.dispose_tween.kill();
    }

    s.dispose_tween = TweenMax.to(s, 0.7, {
      alpha: 0,
      ease: Linear.easeIn,
      onComplete: (dead_s) => {
        dead_s.dispose();
        if (dead_s.parent) {
          dead_s.parent.removeChild(dead_s);
        }
      },
      onCompleteParams: [s],
    });
  } catch (err) {
    logError(err, {
      module: 'components/ui_screens/edit_hero/edit_abilities/AblityElement',
      func: 'hideSingleSpinner',
    });
  }
};
