import * as PIXI from 'pixi.js';
import InfoPanel from './InfoPanel';
import Colors from '~/constants/Colors';
import text from '~/text';
import AbilityAspectField from './AbilityAspectField';

const AbilityInfoPanel = function( ability, showCalcText=true ) {

    const levelText = (ability.handle==='melee_attack' || ability.handle==='move')? "":" - "+(ability.level>0?text('game.level.brief')+ability.level:text('ui.locked'));

    const tt = new InfoPanel(
     	text('abilities.'+ability.handle+'.name')+levelText,
    	text('abilities.'+ability.handle+'.blurb'),
    	""
    );
    
    tt.aspectFields = [];
    var _aspectFieldContainer = new PIXI.Container();

    const makeAspectField = ( labelText, valueText, calcText, color=0xffffff ) => {
		const attField = new AbilityAspectField( labelText+': ', valueText, calcText, color );
		attField.y = tt.aspectFields.length*Math.min( 26, window.innerHeight*0.051 );
		_aspectFieldContainer.addChild( attField );
		tt.aspectFields.push( attField );
	};

	const calcText = text('abilities.'+ability.handle+'.calc');

    if( ability.range ) {
        makeAspectField( text('game.range'), ability.range.min+"-"+ability.range.max, calcText.range );
    }
    
    if( ability.hpCost ) {
        makeAspectField( text('game.hp_cost'), ability.hpCost, calcText.hpCost, Colors.HP_RED );
    }
    
    if( ability.mpCost ) {
        makeAspectField( text('game.mp_cost'), ability.mpCost, calcText.mpCost, Colors.MP_PURPLE );
    }
    
    if( ability.apCost ) {
         makeAspectField( text('game.ap_cost'), ability.apCost, calcText.apCost, Colors.AP_ORANGE );
    }

    if( ability.charges ) {
        makeAspectField( text('game.charges'), ability.charges, calcText.charges );
    }
    
    if( ability.damage ) {
        makeAspectField( text('game.damage'), ability.damage, calcText.damage, Colors.HP_RED );
    }

    if( ability.healing ) {
        makeAspectField( text('game.healing'), ability.healing, calcText.healing, Colors.AP_GREEN );
    }    
    
    if( ability.potency ) {
        makeAspectField( text('game.potency'), (ability.potency%1)===0?ability.potency:ability.potency.toFixed(2), calcText.potency );
    }
    
    if( ability.duration ) {
        makeAspectField( text('game.duration'), (ability.duration/1000).toFixed(1)+'s', calcText.duration );
    }
    
    if( ability.effectRange ) {
        makeAspectField( text('game.effect_range'), ability.effectRange, calcText.effectRange );
    }

    if( ability.maBonus ) {
        makeAspectField( text('game.ma_bonus'), ability.maBonus, calcText.maBonus );
    }

    if( ability.mgBonus ) {
        makeAspectField( text('game.mg_bonus'), ability.mgBonus.toFixed(2), calcText.mgBonus );
    }

	_aspectFieldContainer.x = tt.body.x;
	_aspectFieldContainer.y = tt.body.y;
    tt.body = _aspectFieldContainer;
	tt.addChild( _aspectFieldContainer );
	
    if( !showCalcText ) {
        for( var i = 0; i < tt.aspectFields.length; ++i ) {
            tt.aspectFields[i].removeChild( tt.aspectFields[i].calcField );
            tt.aspectFields[i].y = i*Math.min( 20, window.innerHeight*0.035 );
        }
    }

    tt.title.x = Math.round( tt.padding_x + tt._width*0.29 - tt.title.width/2 );
    tt.title.y = Math.round( tt._height/2 - tt.title.height - tt.padding_y*2 );
    
    tt.description.x = tt.padding_x;
    tt.description.y = Math.round( tt.title.y + tt.title.height + tt.padding_y );
    tt.description.style.wordWrapWidth = tt._width * 0.58;
    
    if( tt.body.height > window.innerHeight*0.23 ) {
        tt.body.height = window.innerHeight*0.23;
        tt.body.scale.x = tt.body.scale.y;
    }

    if( tt.body.width > window.innerWidth*0.16 ) {
        tt.body.width = window.innerWidth*0.16;
        tt.body.scale.y = tt.body.scale.x;
    }

    tt.body.x = Math.round( tt.description.x + tt.description.style.wordWrapWidth + tt.padding_x );
    tt.body.y = Math.round( tt._height/2 - tt.body.height/2 );

    /*if( ability.level===0 && ability.handle!=='melee_attack' && ability.handle!=='move' ) {
        tt.removeChild( _aspectFieldContainer );
        tt.title.x = Math.round( tt._width/2 - tt.title.width/2 );
        tt.description.x = Math.round( tt._width/2 - tt.description.width/2 );
    }*/

	return tt;
};
export default AbilityInfoPanel;