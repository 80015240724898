import * as PIXI from 'pixi.js';
import CavernsTileSprite from './CavernsTileSprite';
import UnitPuppet from '~/components/common/unit_puppets/UnitPuppet';
import Colors from '~/constants/Colors';
import GravitySprite from '~/components/common/GravitySprite';
import ApplicationStore from '~/flux/stores/ApplicationStore';
import { ObjectPool } from '~/Tools';

let _deathParticlePool;
const onAssetsLoaded = () => {
	_deathParticlePool = new ObjectPool(
		// create func
		() => {
			const p = new GravitySprite(PIXI.utils.TextureCache['o_small.png']);
      p.width = 5 + Math.random()*7;
      p.scale.y = p.scale.x;
      p.floorY = -10 + Math.random()*50;
      p.yVelocity = -3.0 - Math.random()*2;
      p.anchor.x = p.anchor.y = 0.5;
      return p;
		},

		// reset func
		(p) => {
      p.dispose();
      gsap.killTweensOf(p);
			p.x = 0;
			p.y = 0;
      p.yVelocity = -3.0 - Math.random()*2;
      p.alpha = 1;
		},

		// starting num
		30
	);	
	ApplicationStore.removeListener( ApplicationStore.ASSETS_LOADED, onAssetsLoaded );
};
ApplicationStore.on( ApplicationStore.ASSETS_LOADED, onAssetsLoaded );

const CavernsUnitSprite = function( unit_state, options ) {	
	const _tileSprite = new CavernsTileSprite( unit_state );
	
	_tileSprite.updateUnit = function( unit_state ) {
		if( unit_state.dead || !unit_state.inPlay ) {
			return;
		}
		this.gamePiece = unit_state;
    if (unit_state.hero) {
      _tileSprite.puppet.actor = unit_state;
    }
	};

	const visuals = getVisualData( unit_state );
	_tileSprite.tileImg.text = visuals.symbol;
	_tileSprite.tileImg.style.fill = visuals.tint;

  if (unit_state.hero || (unit_state.team === 'white' && unit_state.handle === 'poppet')) {
    // make the unit_state puppet
    _tileSprite.puppet = new UnitPuppet({ 
      unit_state,
      body_scale: 0.5,
    });
    _tileSprite.puppet.y = _tileSprite.height * 0.6;
    _tileSprite.addChild(_tileSprite.puppet);
    _tileSprite.puppet.interactive = false;
    if (!options?.prevent_transition_in) {
      _tileSprite.puppet.transitionIn();
    }
  }

	// initialize the text queue timer
	_tileSprite.textQueue = [];
  const onTextQueueInterval = () => {
		if (_tileSprite.textQueue.length > 0) {
			const textEffect = _tileSprite.textQueue.shift();
			textEffect.scale.x = Math.min(2, 1 / _tileSprite.scale.x);
			textEffect.scale.y = Math.min(2, 1 / _tileSprite.scale.y);
			textEffect.y = -_tileSprite.tileImg.height * 0.23;
			textEffect.init();
			_tileSprite.addChild( textEffect );
		}
	};
	_tileSprite.textQueueTimer = setInterval(onTextQueueInterval, 300);

	const onDeathParticleComplete = ( deadParticle ) => {
    	_deathParticlePool.put( deadParticle );
    	_tileSprite?.dispose();
    };

	_tileSprite.onDeath = function() {
    gsap.killTweensOf(_tileSprite.tileImg);
		TweenMax.to(_tileSprite.tileImg, 0.35, { alpha: 0 });

    if (!unit_state.hero) {
      var numParticles = Math.floor( 6 + Math.random()*5 );
      var gSprite;
      for (let i = 0; i < numParticles; ++i) {
        gSprite = _deathParticlePool.get();
        gSprite.init();
        gSprite.rotation_tween = TweenMax.to(gSprite, 1.5 + Math.random() * 1.0, {
          x: gSprite.x - 60 + Math.random() * 120,
          rotation: Math.random() * Math.PI * 3,
          alpha: 0,
          onComplete: onDeathParticleComplete,
          onCompleteParams: [gSprite],
        });
        this.addChild( gSprite );
      }
    }
	};
	
	_tileSprite.disposeAsUnitSprite = () => {
		if (this.disposed ) {
			return;
		}

		clearInterval(_tileSprite.textQueueTimer);
		_tileSprite.textQueue = null;
    _tileSprite?.puppet?.dispose();
		this.disposed = true;
	};

	return _tileSprite;
};
export default CavernsUnitSprite;

function getVisualData( unit_state ) {
	var result = {};

	if( unit_state.hero ) {
		result.symbol = ''; // '@';
	} else {
		result.symbol = SYMBOLS_DICT[ unit_state.handle ];
	}

	result.tint = Colors[unit_state.hero_handle || unit_state.handle];

	return result;
}

const SYMBOLS_DICT = {
	dwarf: 'D',
	elf: 'e',
	faerie: 'p',

	giant_rat: 'r',
	kobold: 'k',
	skeleton: 'x',
	skeletal_archer: 'x',
	cave_lizard: 'l',
	ratling_warrior: 'r',
	slime: 'o',
	ratling_priest: 'r',
	huge_bat: 'B',
	bandit: 'h',
	goblin: 'g',
	kobold_cleric: 'k',
	goblin_elementalist: 'g',
	snake: 's'
};
