import * as PIXI from 'pixi.js';
import { Colors } from '~/constants';
import { CavernsActions } from '~/flux/actions';
import { CavernsStore } from '~/flux/stores';
import CanvasTools from '~/view/CanvasTools';
import ActionRenderer from '~/view/game-screens/battle/canvas/game_board/action_renderings/ActionRenderer';
import CavernsTileSprite from './CavernsTileSprite';
import CavernsUnitSprite from './CavernsUnitSprite';

const DOOR_SPRITE_SCALE = { x: 0.6, y: 0.6 };

const CavernsBattleView = function( battleState, options ) {
  PIXI.Container.call( this );

  if( !battleState ) {
    return;
  }

  this.can_update = true;
  this.getAllPieceSprites = () => { return _allPieceSprites; };
  this.getTileSprites = () => { return _tileSprites; };

  const _actionRenderer = this.actionRenderer = new ActionRenderer( this, true );

  let _allPieceSprites = {};
  let _doorSprites = [];
  let _portal_sprite;
  let _tileSprites = {};
  let _unitSprites = {};
  let _unitSpritesArray;
  let _wallSprites = [];

  const updateCollections = () => {
    if (!this.can_update) {
      return;
    }

    _unitSpritesArray = []

    for (const prop of Object.keys(battleState.allPieces)) {
      let piece = battleState.allPieces[prop];
      
      if (_allPieceSprites[prop]) {
        _allPieceSprites[prop].gamePiece = piece;
      } else {
        // make a new piece sprite
        if (piece.tile) {
          let ts = new CavernsTileSprite( piece );
          _allPieceSprites[prop] = ts;
          this.addChildAt(ts, 0);

          if (piece.occupied ) {
            ts.tileImg.visible = false;
          }
        } else if (piece.unit) {
          let us = new CavernsUnitSprite( piece, options );
          _allPieceSprites[prop] = us;
          this.addChild( us );
        }

        _allPieceSprites[prop]?.snapToBoardPosition( piece.x, piece.y );
      }

      if (piece.unit) {
        _unitSprites[prop] = _allPieceSprites[prop];
        _unitSpritesArray.push( _unitSprites[prop] );
        _unitSprites[prop].updateUnit(piece);
        _unitSprites[prop].tileImg.visible = true;
      } else if (piece.tile) {
        _tileSprites[prop] = _allPieceSprites[prop];
        if (!piece.occupied && !piece.loot) {
          _tileSprites[prop].tileImg.visible = true;
        }
      }
    }

    // sort unit sprites for z-depth
    _unitSpritesArray.sort((a, b) => a.gamePiece.y - b.gamePiece.y);
    for (const us of _unitSpritesArray) {
      this.addChild(us);
    }

    // tint door sprites red/green
    let door_tint = 0x00ff00; // start green (can travel)
    const all_units_array = Object.values(battleState.allUnits);
    // the player can't change rooms if there are any living mobs in the current room
    for (const { team, dead, inPlay } of all_units_array) {
      if (team === 'black' && !dead && inPlay) {
        door_tint = 0xff0000; // red, can't travel
        break;
      }
    }
    for (const ds of _doorSprites) {
      ds.highlight.tint = door_tint;
    }
  };

  const makeDoorSprites = () => {
    for (const ds of _doorSprites) {
      ds?.removeChildren();
      ds?.highlight?.destroy();
      ds?.dispose();
    }
    _doorSprites = []

    for (const door of battleState.doors) {
      const doorSprite = new CavernsTileSprite(door);
      doorSprite.tileImg.text = door.stairs
        ? door.direction === 'UP'
          ? '<'
          : '>'
        : '/';
      doorSprite.tileImg.style.fill = door.stairs ? 0xcccccc : 0xa48457;
      doorSprite.tileImg.style.fontSize = 48;
      doorSprite.tileImg.style.fontStyle = 'bold';
      doorSprite.scale = { ...DOOR_SPRITE_SCALE };
      doorSprite.snapToBoardPosition(door.x, door.y);
      this.addChild(doorSprite);
      _doorSprites.push(doorSprite);

      // red/green highlight around exits, we paint a translucent white circle that will be tinted by `updateCollections()`
      doorSprite.highlight = new PIXI.Graphics();
      doorSprite.highlight.beginFill(0xffffff, 0.15);
      doorSprite.highlight.drawCircle(0, 0, 42);
      doorSprite.highlight.endFill();
      doorSprite.addChild(doorSprite.highlight);
  
      doorSprite.interactive = doorSprite.interactiveChildren = doorSprite.buttonMode = true;
      doorSprite.tap = doorSprite.click = onDoorSpriteClick;
      doorSprite.mouseover = doorSprite.pointerover = onDoorSpriteMouseover;
      doorSprite.mouseout = doorSprite.pointerout = onDoorSpriteMouseout;
      doorSprite.hitArea = new PIXI.Circle(0, 0, 42);
    }
  }

  const makePortalSprite = () => {
    if (!battleState?.portal) {
      return;
    }

    const { portal } = battleState;
    _portal_sprite = new CavernsTileSprite(portal);
    _portal_sprite.tileImg.text = 'Ω'
    _portal_sprite.tileImg.style.fill = Colors.CAVERNS_PORTAL;
    _portal_sprite.tileImg.style.fontSize = 48;
    _portal_sprite.scale = { ...DOOR_SPRITE_SCALE };
    _portal_sprite.tileImg.anchor.x = _portal_sprite.tileImg.anchor.y = 0.5;
    _portal_sprite.snapToBoardPosition(portal.x, portal.y);
    this.addChild(_portal_sprite);

    _portal_sprite.highlight = new PIXI.Graphics();
    _portal_sprite.highlight.beginFill(Colors.dreamsmith, 0.15);
    _portal_sprite.highlight.drawCircle(0, 0, 42);
    _portal_sprite.highlight.endFill();
    _portal_sprite.addChild(_portal_sprite.highlight);

    _portal_sprite.interactive = _portal_sprite.interactiveChildren = _portal_sprite.buttonMode = true;
    _portal_sprite.tap = _portal_sprite.click = onPortalSpriteClick;
    _portal_sprite.mouseover = _portal_sprite.pointerover = onDoorSpriteMouseover;
    _portal_sprite.mouseout = _portal_sprite.pointerout = onDoorSpriteMouseout;
    _portal_sprite.hitArea = new PIXI.Circle(0, 0, 42);
  }

  const makeWallSprites = () => {
    for (const ws of _wallSprites) {
      ws.dispose();
    }
    _wallSprites = []

    for (const wall of battleState.walls) {
      const wallsSprite = new CavernsTileSprite(wall)
      wallsSprite.tileImg && wallsSprite.txtPool.put(wallsSprite.tileImg);
      wallsSprite.tileImg = wallsSprite.txtPool.get();
      wallsSprite.tileImg.text = '#';
      wallsSprite.tileImg.style.fontSize = 24;
      wallsSprite.tileImg.style.fill = 0x777777;
      wallsSprite.addChild(wallsSprite.tileImg)
      wallsSprite.interactive = wallsSprite.interactiveChildren = false;

      wallsSprite.snapToBoardPosition( wall.x, wall.y );
      this.addChild( wallsSprite );
      _wallSprites.push( wallsSprite );
    }
  };
  
  const onDoorSpriteMouseover = (event) => {
    try {
      const target = event.target
        ? event.target
        : event.currentTarget

      if (target && target.tileImg) {
        target.scale.x *= 1.1;
        target.scale.y *= 1.1;
      }
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onDoorSpriteMouseout',
        event
      })
    }
  }

  const onDoorSpriteMouseout = (event) => {
    try {
      const target = event.target
        ? event.target
        : event.currentTarget

      if (target && target.tileImg) {
        target.scale = { ...DOOR_SPRITE_SCALE };
      }
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onDoorSpriteMouseout',
        event
      })
    }
  }

  const onDoorSpriteClick = (event) => {
    CavernsActions.doorSpriteClick(event.target.gamePiece.direction)
  }

  const onPortalSpriteClick = (event) => {
    CavernsActions.setPortalLevelToCurrentDepth();
    CanvasTools.makeFaerieSpinners(_portal_sprite);
  }

  const onStatCostsExacted = (data) => {
    try {
      updateCollections()
      _actionRenderer.renderAbility(data)
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onStatCostsExacted',
        data
      })
    }
  }

  const onAbilityExecuted = (data) => {
    _actionRenderer.renderAbilityResult(data)
  };

  const onBattleEvent = ( data ) => {
    try {
      if (this.can_update) {
        battleState = CavernsStore.getAll().battleState;
      }
      if (!battleState) {
        return;
      }

      _actionRenderer.renderEvent( data.event );

      if (data.event.eventHandle === 'death') {
        if (!battleState || !battleState.allPieces) {
          return;
        }

        const victimId = data.event.victimId;
        delete battleState.allPieces[ victimId ];
        delete battleState.allUnits[ victimId ]; 
        delete _allPieceSprites[ victimId ];
        delete _unitSprites[ victimId ];
      }
        
      updateCollections();
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onBattleEvent',
        data
      })
    }
  };

  const onUnitCondition = ( condition ) => {
    try{ 
      let unit = battleState.allUnits[ condition.ownerId ];
      let unitSprite = _unitSprites[unit.uid];
      unitSprite.updateUnit( unit );

      _actionRenderer.renderCondition( condition );
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onUnitCondition',
        condition
      })
    }
  };

  const onUnitConditionExpired = ( data ) => {
    _actionRenderer.stopRenderingCondition( data );
  };

  const onUnitState = (unit) => {
    try{
      const unitSprite = _unitSprites[ unit.uid ];
      unitSprite?.updateUnit( unit );
    } catch (err) {
      logError(err, {
        module: 'CavernsBattleView',
        func: 'onUnitState',
        unit
      })
    }
  };
  
  const onTotalLootPickedUp = () => {
    if (this.can_update) {
      battleState = CavernsStore.getAll().battleState;
    }
    updateCollections();
  };

  CavernsStore.on( CavernsStore.STAT_COSTS_EXACTED, onStatCostsExacted );
  CavernsStore.on( CavernsStore.ABILITY_EXECUTED, onAbilityExecuted );
  CavernsStore.on( CavernsStore.BATTLE_EVENT, onBattleEvent );
  CavernsStore.on( CavernsStore.UNIT_CONDITION, onUnitCondition );
  CavernsStore.on( CavernsStore.UNIT_CONDITION_EXPIRED, onUnitConditionExpired );
  CavernsStore.on( CavernsStore.GOT_UNIT_STATE, onUnitState );  
  CavernsStore.on( CavernsStore.TOTAL_LOOT_PICKED_UP, onTotalLootPickedUp );
  
  this.dispose = () => {
    this.stopUpdating();

    for (const ps of Object.values(_allPieceSprites)) {
      ps.dispose();
    }
    _actionRenderer.dispose();
    _allPieceSprites = null;
    _tileSprites = null;
    _unitSprites = null;
    battleState = null;
    this.removeChildren();
    this.parent?.removeChild(this);

    for (const ds of _doorSprites) {
      ds.mouseover = ds.pointerover = ds.mouseout = ds.pointerout = null;
      ds?.removeChildren();
      ds?.highlight?.destroy();
      ds?.dispose();
    }
    _doorSprites = null;

    for (const ws of _wallSprites) {
      ws.dispose();
    }
    _wallSprites = null;

    if (_portal_sprite) {
      _portal_sprite.mouseover = _portal_sprite.pointerover = _portal_sprite.mouseout = _portal_sprite.pointerout = null;
      _portal_sprite.removeChildren();
      _portal_sprite.highlight?.destroy();
      _portal_sprite.dispose();
      _portal_sprite = null;
    }
  };

  this.stopUpdating = () => {
    this.can_update = false;
    CavernsStore.removeListener( CavernsStore.STAT_COSTS_EXACTED, onStatCostsExacted );
    CavernsStore.removeListener( CavernsStore.ABILITY_EXECUTED, onAbilityExecuted );
    CavernsStore.removeListener( CavernsStore.BATTLE_EVENT, onBattleEvent );
    CavernsStore.removeListener( CavernsStore.UNIT_CONDITION, onUnitCondition );
    CavernsStore.removeListener( CavernsStore.UNIT_CONDITION_EXPIRED, onUnitConditionExpired );
    CavernsStore.removeListener( CavernsStore.GOT_UNIT_STATE, onUnitState );
    CavernsStore.removeListener( CavernsStore.TOTAL_LOOT_PICKED_UP, onTotalLootPickedUp );
  };

  // init
  makeDoorSprites();
  makeWallSprites();
  updateCollections();
  makePortalSprite();
  this.pivot.x = (this.width / 2 - this.getChildAt(1).width/2) / this.scale.x;
  this.pivot.y = (this.height / 2 - this.getChildAt(1).width/2) / this.scale.y;
  // render any extant loot drops
  for (const tile of Object.values(battleState.allTiles)) {
    const { loot, occupied, uid: tileId } = tile;
    if (loot) {
      if (loot.gold) {
        _actionRenderer.renderEvent({
          eventHandle: 'gold_spawn',
          tileId,
        });
      }
      if (loot.pd) {
        _actionRenderer.renderEvent({
          eventHandle: 'pd_spawn',
          tileId,
        });
      }
      if (loot.equipment?.length) {
        for (const item of loot.equipment) {
          _actionRenderer.renderEvent({
            eventHandle: 'equipment_spawn',
            tileId,
            item,
          });
        }
      }

      if (occupied) {
        _tileSprites[tileId].lootImg.visible = false;
      }
    }
  }
  // make sure we render any conditions that already exist in the battleState we constructed with
  for (let unitId in battleState.allUnits) {
    for (let conditionId in battleState.allUnits[unitId].conditions) {
      _actionRenderer.renderCondition( battleState.allUnits[unitId].conditions[conditionId] );
    }
  }
};
CavernsBattleView.prototype = Object.create( PIXI.Container.prototype );
CavernsBattleView.prototype.constructor = CavernsBattleView;
export default CavernsBattleView;
