import PlayerDispatcher from '~/flux/dispatchers/PlayerDispatcher'
import PlayerStore from '~/flux/stores/PlayerStore'

const PlayerActions = {
  loginPlayer(playerId) {
    PlayerDispatcher.handlePlayerAction({
      actionType: PlayerDispatcher.LOGIN_PLAYER,
      playerId
    })
  },

  onPlayerLoggedIn(player) {
    PlayerDispatcher.handlePlayerAction({
      actionType: PlayerDispatcher.PLAYER_LOGGED_IN,
      player
    })
  },

  enterNewPlayerName(newPlayerName) {
    PlayerDispatcher.handlePlayerAction({
      actionType: PlayerDispatcher.ENTER_NEW_PLAYER_NAME,
      newPlayerName
    })
  },

  submitNewsletterSignup(email) {
    PlayerDispatcher.handlePlayerAction({
      actionType: PlayerDispatcher.SUBMIT_NEWSLETTER_SIGNUP,
      email
    })
  },

  onSocialClick(platformTag) {
    PlayerDispatcher.handlePlayerAction({
      actionType: PlayerDispatcher.SOCIAL_CLICK,
      platformTag
    })
  }
}
export default PlayerActions

PlayerStore.on(PlayerStore.PLAYER_LOGGED_IN, PlayerActions.onPlayerLoggedIn);
