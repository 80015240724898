import * as PIXI from 'pixi.js';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import NukeEffect from '../../../effects/NukeEffect';
import FlameEffect from '../../../effects/FlameEffect';
import Audio from '~/Audio';

const MolotovCocktailRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement && actorSprite.puppet.getMovements().throw.movePuppet({ transitTime });

    var _timeline = new TimelineMax();

    _timeline.call(Audio.play, ['throw_01'], transitTime * 0.4);
    _timeline.call(Audio.play,['glass_shatter_01'], transitTime * 0.4 + data.projectileTime / 1000);

    _timeline.call(
      ()=> {
        const _bottle = new PIXI.Sprite();
        _bottle.texture = PIXI.utils.TextureCache['effects/molotov_bottle.png'];
        _bottle.anchor.x = _bottle.anchor.y = 0.5;
        _bottle.x = actorSprite.x ;
        _bottle.y = actorSprite.y;
        gameBoard.addChild(_bottle);

        const rotation_tween = TweenMax.to(_bottle, data.projectileTime / 1000, {
          rotation: (45 + Math.random()* 360) * (Math.PI / 180),
          ease: Linear.easeNone,
        });
        _timeline.add(
          rotation_tween,
          _timeline._time,
        );

        _timeline.add(
          TweenMax.to(_bottle, data.projectileTime/1000, {
            x: victimSprite.x,
            y: victimSprite.y,
            onComplete: (bottle)=> {
              bottle?.parent?.removeChild(_bottle);
              makeExplosions();
            },
            onCompleteParams: [_bottle]
          }),
          _timeline._time,
        );

      },
      [],
      transitTime * 0.5
    );

    function makeExplosions() {
      var _damage = BattleCalc.getEquippedAbility(actorSprite.gamePiece, 'molotov_cocktail').damage;

      var scaleByDamage = 0.5 + _damage/350;

      var nuke = new NukeEffect(1000);
      nuke.scale.x = scaleByDamage;
      nuke.scale.y = scaleByDamage;
      nuke.x = victimSprite.x;
      nuke.y = victimSprite.y;
      gameBoard.addChild(nuke);

      var numFlames = _damage/8;
      var blastRadius = 98;
      var flame;
      var flameLifeSecs;
      for (var i = 0; i < numFlames; ++i) {
        flameLifeSecs = 1.4 + Math.random()*0.8;

        flame = new FlameEffect(flameLifeSecs*1000);
        flame.x = victimSprite.x - 5 + Math.random() * 10;
        flame.y = victimSprite.y - 5 + Math.random() * 10;
        flame.alpha = 0.7;
        gameBoard.addChild(flame);

        var xDest = victimSprite.x + Math.random()*blastRadius * Math.cos(Math.random()*361);
        var yDest = victimSprite.y + Math.random()*blastRadius * Math.sin(Math.random()*361);

        _timeline.add(TweenMax.to(flame, 0.5 + Math.random()*1.0, { x: xDest, y: yDest }), _timeline._time);
        _timeline.add(TweenMax.to(flame, flameLifeSecs, { alpha: 0, ease: Expo.easeIn }), _timeline._time);
      }

      Audio.play('burning_hands');
    }
  },
};
export default MolotovCocktailRendering;
