<template>
  <div
    id="hero_builds_root"
    class="flex pb-3"
  >
    <div
      id="hero_builds_sidebar"
      class="max-w-[42%] h-full mx-[3vh] p-[2vh] pt-[1vh] border border-zinc-500/60 flex flex-col rounded-lg"
    >
      <HeroRosterView />
    </div>
    <div
      id="selected_hero_builds_body"
      class="grow h-full pr-8 flex flex-col pointer-events-none justify-center"
    >
    <div
      id="selected_hero_builds_title"
      class="mb-6 w-full text-center"
    >
      {{ (text('heroes.yourHeroBuilds'))(state.selected_hero_handle) }}:
    </div>
    <div
      id="selected_hero_build_puppets"
      class="mt-4 flex flex-wrap justify-center gap-4"
    >
      <div
        v-for="hero_build, i of state.selected_hero_builds"
        :id="`HeroBuilds_puppet_anchor_${hero_build._id}`"
        :key="i"
        :class="{
          'w-[20vh] h-[20vh] cursor-pointer hover:outline hover:outline-yellow-200/30 rounded-full pointer-events-auto flex flex-col items-center justify-center': true,
          'bg-white/20': state.hovered_build_id === hero_build._id,
        }"
        @mouseover="() => {state.hovered_build_id = hero_build._id}"
        @mouseleave="() => {state.hovered_build_id = null}"
      >
        <DTButton
          v-if="state.hovered_build_id === hero_build._id"
          type="info"
          class="w-[90px]"
          @click="onEditBuildClick(hero_build._id)"
        >
          {{ text('ui.edit') }}
        </DTButton>
        <DTButton
          v-if="state.hovered_build_id === hero_build._id"
          type="neutral"
          class="mt-2 w-[90px]"
          @click="onRenameBuildClick(hero_build._id)"
        >
          {{ text('ui.rename') }}
        </DTButton>
        <DTButton
          v-if="state.hovered_build_id === hero_build._id && state.selected_hero_builds.length > 1"
          type="error"
          class="mt-2 w-[90px]"
          @click="onDeleteBuildClick(hero_build._id)"
        >
          {{ text('ui.delete') }}
        </DTButton>
      </div>
      <Pressable
        v-if="state.selected_hero_builds.length < Balance.MAX_HERO_BUILDS"
        class="m-[6vh]"
      >
        <div
          id="create_new_build_button"
          class="w-[8vh] h-[8vh] outline outline-yellow-200/30 rounded-lg cursor-pointer pointer-events-auto no-select bg-white/10 hover:bg-white/20 flex justify-center items-center text-4xl font-bold hover:text-yellow-300"
          @click="onCreateNewBuildClick"
        >
          +
        </div>
      </Pressable>
    </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, onMounted, nextTick, reactive } from 'vue';
import Balance from 'dt-common/constants/Balance';
import Audio from '~/Audio';
import { Screens } from '~/constants';
import { HeroBuildActions, UIActions } from '~/flux/actions';
import { GameStateStore, HeroBuildStore, UIStore } from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import { PuppetWithNameplate } from '~/view/components/common/canvas';
import { DTButton, HeroRosterView, Pressable } from '~/view/components/common/DOM';

let _puppets;

const state = reactive({
  selected_hero_handle: UIStore.getAll().focused_hero_handle || (
    Object.values(GameStateStore.getAll().gameState.hero_roster)
      .find(({ level }) => level > 0)
  ).handle,
  selected_hero_builds: [],
  hovered_build_id: null,
});

function getSelectedHeroBuilds() {
  const { hero_builds } = HeroBuildStore.getAll();
  return hero_builds[state.selected_hero_handle] || [];
}

onBeforeMount(() => {
  state.selected_hero_builds = getSelectedHeroBuilds()
});

onMounted(() => {
  makePuppets();

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);

  HeroBuildStore.on(HeroBuildStore.GOT_HERO_BUILDS, onGotHeroBuilds);
  HeroBuildStore.on(HeroBuildStore.HERO_UNLOCKED, updateSelectedHero);
  HeroBuildStore.on(HeroBuildStore.NEW_HERO_BUILD_CREATED, onNewHeroBuildCreated);
  HeroBuildStore.on(HeroBuildStore.HERO_BUILD_DELETED, onHeroBuildDeleted);
  UIStore.on(UIStore.HERO_FOCUSED, updateSelectedHero);

  Audio.setBGTrack('menu_music');
});

onBeforeUnmount(() => {
  destroyPuppets();

  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);

  HeroBuildStore.removeListener(HeroBuildStore.GOT_HERO_BUILDS, onGotHeroBuilds);
  HeroBuildStore.removeListener(HeroBuildStore.HERO_UNLOCKED, updateSelectedHero);
  HeroBuildStore.removeListener(HeroBuildStore.NEW_HERO_BUILD_CREATED, onNewHeroBuildCreated);
  HeroBuildStore.removeListener(HeroBuildStore.HERO_BUILD_DELETED, onHeroBuildDeleted);
  UIStore.removeListener(UIStore.HERO_FOCUSED, updateSelectedHero);
});

const handleResize = Tools.debounce(() => {
  makePuppets();
}, 300);

function makePuppets() {
  destroyPuppets();
  _puppets = [];

  // selected hero builds
  const { hero_builds } = HeroBuildStore.getAll();
  const { hero_roster } = GameStateStore.getAll().gameState;
  for (const hero_build of state.selected_hero_builds || []) {
    const puppet = new PuppetWithNameplate({
      roster_hero: hero_roster[state.selected_hero_handle],
      built_hero:  hero_build,
      scale_mod: 1.7,
      show_build_name: true,
      build_name_label_only: true,
    });
    const dom_anchor = document.getElementById(`HeroBuilds_puppet_anchor_${hero_build._id}`);
    const { x, y, width, height } = dom_anchor.getBoundingClientRect();
    puppet.x = x + width / 2;
    puppet.y = y + height * 0.64;
    DT_CANVAS_GLOBALS.stage.addChild(puppet);
    _puppets.push(puppet);

    puppet.transitionIn();
  }
}

function destroyPuppets() {
  for (const puppet of _puppets || []) {
    puppet.dispose();
    puppet?.parent?.removeChild(puppet);
  }
  _puppets = null;
}

function updateSelectedHero(hero_handle) {
  state.selected_hero_handle = hero_handle;
  state.selected_hero_builds = getSelectedHeroBuilds();
  nextTick(makePuppets);
}

function onEditBuildClick(build_id) {
  UIActions.focusHeroBuild({
    hero_handle: state.selected_hero_handle,
    build_id,
  });
  UIActions.uiNav({ screen_id: Screens.EDIT_HERO  });
}

function onRenameBuildClick(hero_build_id) {
  const hero_handle = state.selected_hero_handle
  UIActions.showModal({
    modal_key: 'EnterTextModal',
    modal_props: {
      modal_title: text('ui.rename_hero_modal_title')(hero_handle),
      onConfirmed: ({ input }) => {
        HeroBuildActions.renameHeroBuild(({
          hero_build_id,
          hero_handle,
          new_build_name: input
        }))
      }
    }
  });
}

function onDeleteBuildClick(build_id) {
  const hero_handle = state.selected_hero_handle;
  const selected_build = state.selected_hero_builds.find(({ _id }) => _id === build_id);
  UIActions.showModal({
    modal_key: 'ConfirmDecisionModal',
    modal_props: {
      title_text: text('heroes.delete_hero_build_title'),
      prompt_text: text('heroes.delete_hero_build_prompt')({
        hero_handle,
        build_name: selected_build.build_display_name || text('heroes.basic'),
      }),
      onConfirmed: () => {
        HeroBuildActions.deleteHeroBuild({
          hero_handle,
          build_id,
        });
      },
    },
  });
}

function onGotHeroBuilds() {
  state.selected_hero_builds = getSelectedHeroBuilds();
  nextTick(makePuppets);
}

function onHeroBuildDeleted() {
  state.selected_hero_builds = getSelectedHeroBuilds();
  nextTick(makePuppets);
}

function onCreateNewBuildClick() {
  UIActions.showModal({
    modal_key: 'EnterHeroBuildNameModal',
    modal_props: {
      onConfirmed: onHeroBuildNameConfirmed,
    },
  });
}

function onHeroBuildNameConfirmed(new_build_name) {
  HeroBuildActions.createNewHeroBuild({
    hero_handle: state.selected_hero_handle,
    new_build_name,
  });
}

function onNewHeroBuildCreated() {
  state.selected_hero_builds = getSelectedHeroBuilds();
  nextTick(makePuppets);
}
</script>
