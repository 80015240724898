import * as PIXI from 'pixi.js';
import Game from 'dt-common/constants/Game.js';

const BASE_SCALE = 76 / 180;

var PuppetPart = function(bodyScale) {
  PIXI.Sprite.call(this);

  this.world_x = 0;
  this.world_y = 0;
  this.world_z = 0;
  this.yaw = 0;
  this.pitch = 0;

  this.scale.x = 0.125 * bodyScale;
  this.scale.y = 0.125 * bodyScale;

  this.myScale = BASE_SCALE * bodyScale;

  this.translateWorldCoords = (facingDirection) => {
    if (facingDirection === Game.NORTH) {
      this.x = this.world_x * this.myScale;
      this.y = (this.world_y-(this.world_z*0.5)) * this.myScale;
    } else if (facingDirection === Game.SOUTH) {
      this.x = -this.world_x * this.myScale;
      this.y = (this.world_y+(this.world_z*0.5)) * this.myScale;
    } else if (facingDirection === Game.EAST) {
      this.x = this.world_z * 0.5 * this.myScale;
      this.y = (this.world_y+(this.world_x*0.5)) * this.myScale;
    } else if (facingDirection === Game.WEST) {
      this.x = -this.world_z * 0.5 * this.myScale;
      this.y = (this.world_y-(this.world_x*0.5)) * this.myScale;
    } else {
      throw new Error('Unhandled facingDirection in PuppetPart::translateWorldCoords()');
    }
  };

  this.translateYawAndPitch = (facingDirection) => {
    if (facingDirection === Game.NORTH) {
      this.rotation = this.yaw * (Math.PI/180);
    } else if (facingDirection === Game.SOUTH) {
      this.rotation = -this.yaw * (Math.PI/180);
    } else if (facingDirection === Game.EAST) {
      this.rotation = this.pitch * (Math.PI/180);
    } else if (facingDirection === Game.WEST) {
      this.rotation = -this.pitch * (Math.PI/180);
    } else {
      throw new Error('Unhandled facingDirection in PuppetPart::translateYawAndPitch()');
    }
  };
};
PuppetPart.prototype = Object.create(PIXI.Sprite.prototype);
PuppetPart.prototype.constructor = PuppetPart;
export default PuppetPart;
