import * as PIXI from 'pixi.js';
import getUnitState from 'dt-common/isomorphic-helpers/getUnitState';
import UnitDetailsView from '~/view/components/footer/Footer_canvas/battle_HUD/UnitDetailsView';
import CavernsStore from '~/flux/stores/CavernsStore';

const UnitDetailsPanel = function({
  hero_builds,
  loadout_squad_list,
  roster_heroes,
  puppet_scale,
  do_transition_in,
}) {
  PIXI.Container.call( this );

  const NUM_COLUMNS = loadout_squad_list.length > 8 ? 3 : loadout_squad_list.length > 4 ? 2 : 1;
  const UDV_HEIGHT = window.innerHeight * 0.15;
  let _unitDetailsViews;

  this.dispose = () => {        
    CavernsStore.removeListener( CavernsStore.BATTLE_EVENT, update );
    CavernsStore.removeListener( CavernsStore.GOT_UNIT_STATE, update );
    destroyUnitDetailsViews();
    this.removeChildren();
    this.parent?.removeChild(this);
  };

  const destroyUnitDetailsViews = () => {
    if (_unitDetailsViews) {
      for (let i = 0; i < _unitDetailsViews.length; ++i) {
        _unitDetailsViews[i].dispose();
      }
      _unitDetailsViews = null;
    }
  };

  const makeUnitDetailsViews = () => {
    destroyUnitDetailsViews();
    _unitDetailsViews = [];

    for (let i = 0; i < loadout_squad_list.length; ++i) {
      const loadout_member = loadout_squad_list[i];
      const hero_handle = loadout_member.hero_handle || loadout_member.handle;
      const unit_state = getUnitState({
        roster_hero: roster_heroes[hero_handle],
        unit_build: hero_builds[hero_handle].find(({ _id }) => _id === loadout_member.hero_build_id),
      });

      const udv = new UnitDetailsView(unit_state, false, true);
      udv.height = UDV_HEIGHT;
      udv.scale.x = udv.scale.y;
      udv.x = -udv.width * 1.7 - Math.round( (i % NUM_COLUMNS) * udv.width );
      udv.y = Math.round(i % NUM_COLUMNS * (UDV_HEIGHT / 4) + Math.floor(i / NUM_COLUMNS) * UDV_HEIGHT * 1.2);
      this.addChild(udv);
      _unitDetailsViews.push(udv);
    }

    update();
  };

  const update = (data) => {
    const { battleState } = CavernsStore.getAll();
    if (!battleState) {
      return;
    }

    if (data?.event?.eventHandle === 'death') {
      for (const view of _unitDetailsViews) {
        if (data?.event?.victimId === view.unit_state.uid) {
          view.onUnitDeath();
          return
        }
      }
    }

    for (const [uid, unit_state] of Object.entries(battleState.allUnits)) {
      if (!unit_state?.hero || unit_state?.team !== 'white') {
        continue;
      }

      for (const view of _unitDetailsViews) {
        if (view.handle === unit_state.handle || view.handle === unit_state.hero_handle) {
          view.updateUnit(unit_state);
          break;
        }
      }
    }
  };

  makeUnitDetailsViews();
  CavernsStore.on(CavernsStore.BATTLE_EVENT, update);
  CavernsStore.on(CavernsStore.GOT_UNIT_STATE, update);
};
UnitDetailsPanel.prototype = Object.create( PIXI.Container.prototype );
UnitDetailsPanel.prototype.constructor = UnitDetailsPanel;
export default UnitDetailsPanel;
