import { EventEmitter } from 'events';
import firebase from 'firebase/compat/app';
import { validate as uuidValidate } from 'uuid';
import PlayerDispatcher from '~/flux/dispatchers/PlayerDispatcher';
import TutorialDispatcher from '~/flux/dispatchers/TutorialDispatcher';
import Tools from '~/Tools';
import text from '~/text';

const { awaitSocket, registerDispatchHandlers } = Tools;

let playerId;
let _socket;
let _tutorial_battle_01_started = false;
let _num_tutorial_01_enemies_killed = 0;

// the stuff we serve:
let completed_tutorial_steps = {};
let show_equip_ability_tutorial = false;

const TutorialStore = Object.assign({}, EventEmitter.prototype, {
  GOT_TOTAL_SALE_AMOUNT: 'GOT_TOTAL_SALE_AMOUNT',
  TUTORIAL_COMPLETE: 'TUTORIAL_COMPLETE',

  getAll() {
    return {
      completed_tutorial_steps,
      show_equip_ability_tutorial: false // testing only
    };
  }
});
export default TutorialStore;

PlayerDispatcher.register(registerDispatchHandlers({
  [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn
}));
TutorialDispatcher.register(registerDispatchHandlers({
  [TutorialDispatcher.START_TUTORIAL_BATTLE_01]: startTutorialBattle01
}));

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  _socket = socket;
  if (!socket.has_TutorialStore_listeners) {
    _socket.on('heroUnlocked', onHeroUnlocked);
    _socket.on('battleEvent', onBattleEvent);
    _socket.on('battleInitialized', onBattleInitialized);
    _socket.on('tutorial_battle_01_done', onTutorialBattle01Done);
    _socket.on('ability_change', onAbilityChange);
    _socket.on('engine_recovery_failed', onEngineRecoveryFailed);

    socket.has_TutorialStore_listeners = true;
  }
}

function onPlayerLoggedIn(action) {
  try {
    const { _id, privateData, gameState: { hero_roster } } = action.player;

    playerId = _id;
    completed_tutorial_steps = privateData.completed_tutorial_steps || {};

    const heroes_array = Object.values(hero_roster);
    checkForTutorialStart(heroes_array);

    // set the flag for running the equip ability section of the tutorial
    // const { equipped_an_ability, skipped_all } = completed_tutorial_steps;
    // if (!equipped_an_ability && !skipped_all) {
    //   show_equip_ability_tutorial = !anyHeroHasAbilitiesEquipped(heroes_array);
    // }
  } catch (err) {
    logError(err, {
      module: 'TutorialStore',
      func: 'onPlayerLoggedIn',
      action
    });
  }
}

function onHeroUnlocked(data) {
  checkForTutorialStart(Object.values(data.hero_roster));
}

function checkForTutorialStart(heroes_array) {

  try {
    // check for need to kickoff tutorial battle 01
    let num_unlocked_heroes = 0;
    for (const hero of heroes_array) {
      if (hero.level > 0) {
        ++num_unlocked_heroes;
      }
    }
    if (
      num_unlocked_heroes === 1 &&
      !completed_tutorial_steps.battle_01 &&
      !completed_tutorial_steps.skipped_all &&
      uuidValidate(playerId) // legacy players (whose IDs are not uuid) should not be sent to the tutorial
    ) {
      // TweenMax.delayedCall(1.5, startTutorialBattle01)
      TutorialStore.emit(TutorialStore.TUTORIAL_COMPLETE);
    }
  } catch (err) {
    logError(err, {
      module: 'TutorialStore',
      func: 'checkForTutorialStart',
      hero_roster
    });
  }
}

function startTutorialBattle01() {
  try {
    if (!_tutorial_battle_01_started) {
      _socket.emit('startTutorialBattle01', { playerId });
      _tutorial_battle_01_started = true;
      _num_tutorial_01_enemies_killed = 0;

      const fa = firebase.analytics();
      fa.logEvent('tutorial_begin');
      fa.logEvent('onboard_step:3', {
        name: 'req_start_tutorial_battle_01'
      });
    }
  } catch (err) {
    logError(err, {
      module: 'TutorialStore',
      func: 'startTutorialBattle01'
    });
  }
}

function onBattleInitialized(data) {
  try {
    const { battle_id } = data;

    if (battle_id.startsWith('tutorial_01')) {
      firebase.analytics().logEvent('onboard_step:4', {
        name: 'tutorial_battle_01_initialized'
      });
    }
  } catch (err) {
    logError(err, {
      module: 'TutorialStore',
      func: 'onBattleInitialized'
    });
  }
}

function onBattleEvent(data) {
  try {
    const { battle_id, event: { eventHandle } } = data;

    if (battle_id.startsWith('tutorial_01') && eventHandle === 'death') {
      // TODO(@rob-wfs): bugged - got logs of enemy 7, 8
      firebase.analytics().logEvent(`onboard_step:kill_enemy_${++_num_tutorial_01_enemies_killed}`, {
        name: 'killed_tutorial_01_enemy',
        num_killed: _num_tutorial_01_enemies_killed
      });
    }
  } catch (err) {
    logError(err, {
      module: 'TutorialStore',
      func: 'onBattleEvent'
    });
  }
}

function onTutorialBattle01Done() {
  completed_tutorial_steps.battle_01 = true;
  firebase.analytics().logEvent('onboard_step:12', {
    name: 'tutorial_battle_01_done'
  });
}

function onAbilityChange() {
  if (!completed_tutorial_steps.equipped_an_ability) {
    completed_tutorial_steps.equipped_an_ability = true;
    show_equip_ability_tutorial = false;

    _socket.emit('set_completed_tutorial_step', { playerId, step_name: 'equipped_an_ability' });

    firebase.analytics().logEvent('onboard_step:15', {
      name: 'equipped_first_ability'
    });
    firebase.analytics().logEvent('tutorial_complete');
  }
}

// function anyHeroHasAbilitiesEquipped(heroes_array) {
//   for (const hero of heroes_array) {
//     for (const tree of hero.abilityTrees) {
//       for (const ability of Object.values(tree.abilities)) {
//         if (['move', 'melee_attack'].includes(ability.handle)) {
//           continue;
//         }

//         if (ability.level > 0) {
//           return true;
//         }
//       }
//     }
//   }

//   return false;
// }

function onEngineRecoveryFailed(data) {
  try {
    if (data.battle_id === `tutorial_01_${playerId}`) {
      // when tutorial battles break, we need the player to start over
      window.alert(text('tutorial.tutorial_engine_error'));
      window.location.reload();
    }
  } catch (err) {
    logError(err, {
      module: 'TutorialStore',
      func: 'onEngineRecoveryFailed',
      data
    });
  }
}
