export default {
	unlock_a_hero: "Welcome to Dungeon Team! Start by unlocking your first hero...", 
	wait_btn_tip: 	"The battle is paused.\n\n"
				  + "It's your turn, but you have no Action Points (AP).\n\n"
				  + "Click the 'Wait' button to end your turn and start recharging AP.",
	attack_tip: "Click here then click an enemy to perform a basic attack.",
	move_tip: "Click here then a click a tile to move.",
	zoom_tip: "You can zoom in on the action using the mouse wheel. Try it now.",
	edit_hero_tip: "Click your hero to add some abilities ----->",
	cycle_ability_trees_tip: "Click a panel to cycle through\nyour hero's ability trees.",
	get_ability_info: "Clicking the plus sign will level up and equip the ability.",
	final_tip: "That's it - you're ready to play! Don't be afraid to ask for help in the chat!\n"
				+ "When you're done adding abilities, click the ARENA button to start your first real battle.\n"
				+ "Good luck, and thanks for playing Dungeon Team!",
	no_abilities_equipped: "No abilities equipped!",
  tutorial_engine_error: "Something went wrong with the tutorial engine. We'll have to log you back in..."
}
