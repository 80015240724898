import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';

const APMeter = function( unit ) {
    PIXI.Container.call( this );

    const drawNub = ( x, y ) => {
        _gfx.beginFill( TINT );
        _gfx.drawCircle( x, y, 0.35 );
        _gfx.endFill();
    };

    this.update = ( newUnit ) => {
        if( newUnit ) {
            unit = newUnit;
        }

        _gfx.clear();
        _gfx.lineStyle( 3, TINT );

        drawNub( 16, 3 );

        var progress = Math.max( 0, Math.min( 1, (unit.ap - 0) / (unit.max_ap/6) ) );
        _gfx.moveTo( 16, 3 );
        _gfx.lineTo( 16 + (42-16)*progress, 3 + (3-3)*progress );

        if( progress >= 1 ) {
            drawNub( 42, 3 );
        }

        progress = Math.max( 0, Math.min( 1, (unit.ap - (1*unit.max_ap/6)) / (unit.max_ap/6) ) );
        _gfx.moveTo( 42, 3 );
        _gfx.lineTo( 42 + (54-42)*progress, 3 + (25-3)*progress );

        if( progress >= 1 ) {
            drawNub( 54.5, 25 );
        }

        progress = Math.max( 0, Math.min( 1, (unit.ap - (2*unit.max_ap/6)) / (unit.max_ap/6) ) );
        _gfx.moveTo( 55, 25 );
        _gfx.lineTo( 55 + (42-55)*progress, 25 + (49-25)*progress );

        if( progress >= 1 ) {
            drawNub( 42, 49 );
        }

        progress = Math.max( 0, Math.min( 1, (unit.ap - (3*unit.max_ap/6)) / (unit.max_ap/6) ) );
        _gfx.moveTo( 42, 49 );
        _gfx.lineTo( 42 + (16-42)*progress, 49 + (49-49)*progress );

        if( progress >= 1 ) {
            drawNub( 16, 49 );
        }

        progress = Math.max( 0, Math.min( 1, (unit.ap - (4*unit.max_ap/6)) / (unit.max_ap/6) ) );
        _gfx.moveTo( 16, 49 );
        _gfx.lineTo( 16 + (3-16)*progress, 49 + (25-49)*progress );

        if( progress >= 1 ) {
            drawNub( 3.5, 25.5 );
        }

        progress = Math.max( 0, Math.min( 1, (unit.ap - (5*unit.max_ap/6)) / (unit.max_ap/6) ) );
        _gfx.moveTo( 4, 25 );
        _gfx.lineTo( 4 + (16-4)*progress, 25 + (1-25)*progress );

        _gfx.endFill();
    };

    this.dispose = () => {
        this.removeChildren();
    };

    this.pivot.x = 29;
    this.pivot.y = 26;
    const TINT = Colors.AP_ORANGE; // unit.team==='white'? Colors.WHITE_TEAM:Colors.BLACK_TEAM;

    var _gfx = new PIXI.Graphics();
    this.addChild( _gfx );
};
APMeter.prototype = Object.create( PIXI.Container.prototype );
APMeter.prototype.constructor = APMeter;
export default APMeter;