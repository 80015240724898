import Audio from '~/Audio';

const DeathRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
		if( !victimSprite ) {
			return;
		}
    if (document.hidden) {
      // minimal death routine:
      victimSprite.tileImg.visible = false;
    } else {
      if (!isCaverns || victimSprite.gamePiece.hero) {
        victimSprite.puppet.die();
      }    
      victimSprite.onDeath();
    }
    
    Audio.play('unit_death');

		var victim = victimSprite.gamePiece;
		
		for (var prop in victim.conditions) {
			var c = victim.conditions[ prop ];				
			gameBoard.actionRenderer.stopRenderingCondition({
				conditionHandle: c.handle,
				conditionId: c.uid
			});
		}

		if (isCaverns) {
			const victim = victimSprite.gamePiece;
			const tileSprites = gameBoard.getTileSprites();
			for( var tileId in tileSprites ) {
				var tSprite = tileSprites[tileId];
				var tile = tSprite.gamePiece;
				if( tile.x===victim.x && tile.y===victim.y ) {
					if( tSprite.lootImg ) {
						tSprite.lootImg.visible = true;
					} else {
						tSprite.tileImg.visible = true;
					}
					break;
				} 
			}
		}
	}
};
export default DeathRendering;