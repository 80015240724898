<template>
  <div
    class="w-full h-full"
  >
    <div 
      id="shop_screen_root"
      class="h-full px-12 flex"
    >
      <div
        id="shop_global_inventory"
        class="w-[30%] h-full pt-[6%] flex flex-col"
      >
        <div
          id="global_inventory_title"
          class="text-yellow-300 no-select"
        >
          {{ text('ui.global_inventory') }}
        </div>
        <div
          id="global_bags_icons"
          class="mt-3 flex"
        >
          <div
            v-for="n in Balance.MAX_GLOBAL_BAG_SLOTS"
            :key="n"
            :class="{
              'w-8 h-8 mr-2 border border-zinc-500 flex justify-center items-center no-select': true,
              'border border-yellow-300': state.selected_global_bag_index === n - 1,
            }"
          >
            <div
              v-if="!!state.global_bags[n - 1]"
              :id="`global_bag_${n - 1}`"
              class="h-[90%] w-auto pointer-events-none"
            ></div>
            <div
              v-else-if="state.global_bags[n - 1] === null"
              class="w-full h-full pointer-events-auto cursor-pointer"
              @mouseup="onGlobalBagSlotMouseup({ bag_slot_index: n - 1 })"
            ></div>
            <img
              v-else
              :src="`${Config.DOM_IMG_PATH}/lock.png`"
              class="pointer-events-auto cursor-pointer"
              @click="onLockedGlobalBagSlotClick"
            >
          </div>
        </div>
        <div
          id="global_item_icons_container"
          class="mt-3 flex flex-wrap"
        >
          <div
            v-for="bag_item, i in state.selected_global_bag_items"
            :id="`selected_global_bag_item_${i}`"
            :key="i"
            :style="`width: ${GAME_ITEM_ICON_SIZE_PX}px; height: ${GAME_ITEM_ICON_SIZE_PX}px;`"
          />
        </div>
      </div>
      <SellItemsPanel
        v-if="state.item_uids_to_sell.length && state.canvas_root"
        class="bg-blue-400/20"
        v-model="state.item_uids_to_sell"
        :canvas_root="state.canvas_root"
        @item-clicked="onSellItemIconClicked"
        @cancel="state.item_uids_to_sell = []; makeGlobalInventoryIcons();"
        :lets_deal_message="state.lets_deal_message"
      />
    </div>
  </div>
</template>

<script setup>
import { nextTick, onBeforeUnmount, onMounted, reactive } from 'vue';
import Balance from 'dt-common/constants/Balance';
import Economy from 'dt-common/constants/Economy';
import Game from 'dt-common/constants/Game';
import describeVacantInventorySpace from 'dt-common/isomorphic-helpers/describeVacantInventorySpace';
import Audio from '~/Audio';
import GameItemIcon from '~/components/common/GameItemIcon';
import { Config } from '~/constants';
import { GameItemActions } from '~/flux/actions';
import { GameStateStore } from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';
import SellItemsPanel from './SellItemsPanel.vue';
import ShopScreen_canvas from '../canvas/Shop';

const {
  ItemLocations: {
    GLOBAL_BAG_SLOT,
    GLOBAL_INVENTORY,
    EQUIPMENT_FIGURE,
  },
} = Game;
const GAME_ITEM_ICON_SIZE_PX = 64;

let _currently_dragging_icon_data;
let _equipped_global_bag_icons;
let _global_game_item_icons;

const state = reactive({
  global_bags: [],
  item_uids_to_sell: [],
  selected_global_bag_index: 0,
  selected_global_bag_items: [],
  lets_deal_message: text('ui.lets_deal'),
});

onMounted(() => {
  state.global_bags = GameStateStore.getAll().gameState.inventory.bags;
  state.selected_global_bag_index = state.global_bags.findIndex(x => !!x);
  state.selected_global_bag_items = getSelectedGlobalBagItems();
  nextTick(renderCanvas);

  window.addEventListener('resize', handleResize);
  window.addEventListener('orientationchange', handleResize);
  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('mouseup', onMouseUp);
  GameStateStore.on(GameStateStore.EQUIPMENT_CHANGE, onEquipmentChange);

  Audio.setBGTrack('menu_music');
  Audio.play('register_ding');
  Audio.play('star_twinkle_01');
});

onBeforeUnmount(() => {
  destroyEquippedGlobalBagIcons();
  destroyGlobalInventoryIcons();

  state.canvas_root.destroy();
  setTimeout(state.canvas_root.dispose, 500); // don't ask

  window.removeEventListener('resize', handleResize);
  window.removeEventListener('orientationchange', handleResize);
  window.removeEventListener('mousemove', onMouseMove);
  window.removeEventListener('mouseup', onMouseUp);
  GameStateStore.removeListener(GameStateStore.EQUIPMENT_CHANGE, onEquipmentChange);
});

function destroyEquippedGlobalBagIcons() {
  for (const game_item_icon of _equipped_global_bag_icons || []) {
    game_item_icon?.dispose();
  }
  _equipped_global_bag_icons = null;
}

function destroyGlobalInventoryIcons() {
  for (const game_item_icon of _global_game_item_icons || []) {
    game_item_icon?.dispose();
  }
  _global_game_item_icons = null;
}

function getSelectedGlobalBagItems() {
  const { bags } = GameStateStore.getAll().gameState.inventory;
  return bags
    ? bags[state.selected_global_bag_index].items
    : [];
}

const handleResize = Tools.debounce(renderCanvas, 300);

function makeEquippedGlobalBagIcons() {
  destroyEquippedGlobalBagIcons();
  _equipped_global_bag_icons = [];

  for (let i = 0; i < state.global_bags.length; ++i) {
    const bag = state.global_bags[i];
    if (!bag) {
      continue;
    }

    // make game item icon & add to stage
    const { x, y, width, height } = CanvasTools.getDomAnchor(`global_bag_${i}`);
    const game_item_icon = new GameItemIcon(bag, {
      frame: false,
    });
    game_item_icon.setScale(24);
    game_item_icon.x = x + width / 2;
    game_item_icon.y = y + height / 2;
    DT_CANVAS_GLOBALS.stage.addChildAt(game_item_icon, 1); // add at 0 so as not to mask faerie spinners on equipment change

    // store icon data locally
    game_item_icon.bag_slot_index = i;
    _equipped_global_bag_icons.push(game_item_icon);

    // add interaction listeners
    game_item_icon.interactive = game_item_icon.buttonMode = true;
    game_item_icon.click = ((icon, bag_slot_index) => {
      icon.should_start_drag = false;
      state.selected_global_bag_index = bag_slot_index;
      state.selected_global_bag_items = state.global_bags[bag_slot_index].items;
      nextTick(makeGlobalInventoryIcons);
    }).bind(null, game_item_icon, i);
    game_item_icon.mousedown = (icon => icon.should_start_drag = true).bind(null, game_item_icon);
    game_item_icon.mousemove = ((game_item_icon, bag_slot_index, event) => {
      game_item_icon.should_start_drag && onItemDragStart({
        from: {
          bag_slot_index,
          location: GLOBAL_BAG_SLOT,
        },
        game_item_icon,
      }, event);
      game_item_icon.should_start_drag = false;
    }).bind(null, game_item_icon, i);
    game_item_icon.mouseup = onItemDragEnd.bind(null, {
      game_item_icon,
      to: {
        bag_slot_index: i,
        location: GLOBAL_BAG_SLOT,
      },
    });
  }
}

function makeGlobalInventoryIcons() {
  destroyGlobalInventoryIcons();
  _global_game_item_icons = [];

  for (let i = 0; i < state.selected_global_bag_items.length; ++i) {
    const bag_item = state.selected_global_bag_items[i];

    // make game item icon & add to stage
    const game_item_icon = new GameItemIcon(bag_item);
    game_item_icon.setScale(GAME_ITEM_ICON_SIZE_PX);
    const { x, y, width, height } = CanvasTools.getDomAnchor(`selected_global_bag_item_${i}`);
    game_item_icon.x = x + width / 2;
    game_item_icon.y = y - height / 2;
    state.canvas_root.addChildAt(game_item_icon, 1); // add at 1 so as not to mask faerie spinners on equipment change

    // store icon data locally
    game_item_icon.bag_item_index = i;
    _global_game_item_icons.push(game_item_icon);

    // add interaction listeners
    game_item_icon.tap = game_item_icon.click = onGlobalInventoryItemClick.bind(null, { game_item_icon });
    game_item_icon.mousedown = (icon => icon.should_start_drag = true).bind(null, game_item_icon);
    // game_item_icon.click = (icon => icon.should_start_drag = false).bind(null, game_item_icon);
    game_item_icon.mousemove = ((game_item_icon, bag_item_index, event) => {
      game_item_icon.should_start_drag && onItemDragStart({
        from: {
          bag_index: state.selected_global_bag_index,
          bag_item_index,
          location: GLOBAL_INVENTORY,
        },
        game_item_icon,
      }, event);
      game_item_icon.should_start_drag = false;
    }).bind(null, game_item_icon, i);
    game_item_icon.mouseup = onItemDragEnd.bind(null, {
      game_item_icon,
      to: {
        bag_index: state.selected_global_bag_index,
        bag_item_index: i,
        location: GLOBAL_INVENTORY,
      },
    });

    if (bag_item && state.item_uids_to_sell.includes(bag_item.uid)) {
      processIconForItemToSell(game_item_icon);
    }
  }
}

function onGlobalInventoryItemClick({ game_item_icon }) {
  game_item_icon.should_start_drag = false;
  if (state.item_uids_to_sell.length === 0) {
    // first item on the block
    state.lets_deal_message = text('ui.lets_deal')();
  }
  state.item_uids_to_sell = [...state.item_uids_to_sell, game_item_icon.getItem().uid];
  processIconForItemToSell(game_item_icon);
}

const onItemToSellIconClick = Tools.debounce(({ game_item_icon }) => {
  // remove from state.item_uids_to_sell & redraw
  const item_to_sell_index = state.item_uids_to_sell.findIndex(uid => uid === game_item_icon.getItem().uid);
  state.item_uids_to_sell.splice(item_to_sell_index, 1);
  state.item_uids_to_sell = [...state.item_uids_to_sell];

  // return inventory icon to buffered state
  game_item_icon.alpha = game_item_icon.alpha_buffer;
  game_item_icon.tap = game_item_icon.click = game_item_icon.click_handler_buffer;
}, 200);

function onEquipmentChange() {
  state.global_bags = GameStateStore.getAll().gameState.inventory.bags;
  state.selected_global_bag_items = getSelectedGlobalBagItems();
  nextTick(() => {
    makeEquippedGlobalBagIcons();
    makeGlobalInventoryIcons();
  });
}

function onGlobalBagSlotMouseup({ bag_slot_index }) {
  onItemDragEnd({
    to: {
      bag_slot_index,
      location: GLOBAL_BAG_SLOT,
    },
  });
}

function onItemDragStart(data, event) {
  const item = data.game_item_icon.getItem();
  if (!item || state.item_uids_to_sell.includes(item.uid)) {
    return;
  }
  _currently_dragging_icon_data = data;

  const { img } = data.game_item_icon;
  DT_CANVAS_GLOBALS.stage.addChild(img);
  img.x = event.data?.global?.x ?? event.x;
  img.y = event.data?.global?.y ?? event.y;
}

function onItemDragEnd({ game_item_icon: to_icon, to }) {
  if (!_currently_dragging_icon_data) {
    return;
  }

  const { from, game_item_icon: from_icon } = _currently_dragging_icon_data;
  const from_game_item = from_icon.getItem();
  const to_game_item = to_icon?.getItem();

  if (from_game_item?.type === 'miracle_dye' && !!to_game_item?.slot) {
    const from_icon_clone =  new PIXI.Sprite();
    from_icon_clone.x = from_icon.x;
    from_icon_clone.y = from_icon.y;
    from_icon_clone.width = from_icon.width;
    from_icon_clone.height = from_icon.height;

    const to_icon_clone = new PIXI.Sprite();
    to_icon_clone.x = to_icon.x;
    to_icon_clone.y = to_icon.y;
    to_icon_clone.width = to_icon.width;
    to_icon_clone.height = to_icon.height;

    UIActions.showModal({
      modal_key: 'MiracleDyeModal',
      modal_props: {
        item_to_dye: to_game_item,
        onColorSelected: ({ base_16_color }) => {
          CanvasTools.makeFaerieSpinners(from_icon_clone);
          CanvasTools.makeFaerieSpinners(to_icon_clone);
          GameItemActions.activateMiracleDye({
            dyeId: from_game_item.uid,
            itemId: to_game_item.uid,
            from,
            to,
            tint: base_16_color,
          })
          Audio.play('airy_magic_whoosh');
          Audio.play('bless');
        },
      },
    });
  } else if (from_game_item?.type === 'equipment_dye' && !!to_game_item?.slot) {
    GameItemActions.dyeItem({
      dyeId: from_game_item.uid,
      itemId: to_game_item.uid,
      from,
      to,
    });
    CanvasTools.makeFaerieSpinners(from_icon);
    CanvasTools.makeFaerieSpinners(to_icon);
  } else {
    onMoveItemConfirmed({ from, to });
  }
}


function onItemPurchaseConfirmed() {
  // render spinners over the first empty slot o
  const { is_room, bag_with_room, index_with_room } = describeVacantInventorySpace(GameStateStore.getAll().gameState.inventory);
  if (is_room && bag_with_room === state.selected_global_bag_index) {
    CanvasTools.makeFaerieSpinners(
      _global_game_item_icons[index_with_room],
      8
    );
  }
};

function onLockedGlobalBagSlotClick() {
  const unlock_cost = Economy.GLOBAL_BAG_SLOT_UNLOCK_COST[state.global_bags.length];
    UIActions.showModal({
      modal_key: 'ConfirmDecisionModal',
      modal_props: {
        title_text: text('ui.bag_slot_unlock_title'),
        prompt_text: text('ui.bag_slot_unlock_prompt')(unlock_cost),
        onConfirmed: GameItemActions.globalBagSlotUnlockConfirm,
      },
    });
}

function onMoveItemConfirmed({ from, to }) {
  const from_item_icon = from.location === GLOBAL_BAG_SLOT
    ? _equipped_global_bag_icons.find(icon => icon.bag_slot_index === from.bag_slot_index)
    : from.location === GLOBAL_INVENTORY
        ? _global_game_item_icons.find(icon => icon.bag_item_index === from.bag_item_index)
        : null;
  const to_item_icon = to.location === GLOBAL_BAG_SLOT
    ? _equipped_global_bag_icons.find(icon => icon.bag_slot_index === to.bag_slot_index) 
    : to.location === GLOBAL_INVENTORY
        ? _global_game_item_icons.find(icon => icon.bag_item_index === to.bag_item_index)
        : null;
        
  const game_item = from_item_icon.getItem();
  const action_payload = {
    from,
    game_item,
    to,
  };

  switch (to.location) {
    case GLOBAL_BAG_SLOT:
      if (game_item.type === 'bag') {
        if (from.location !== GLOBAL_BAG_SLOT || from.bag_slot_index !== to.bag_slot_index) {
          GameItemActions.equipBagToGlobalBagSlot(action_payload);
        }
      } else {
        const { is_room, index_with_room } = describeVacantInventorySpace(
          { bags: state.global_bags },
          { bag_index: to.bag_slot_index },
        );
        if (is_room) {
          action_payload.to.location = GLOBAL_INVENTORY;
          action_payload.to.bag_index = to.bag_slot_index;
          action_payload.to.bag_item_index = index_with_room;
          GameItemActions.placeItemInGlobalInventory(action_payload);
        }
      }
      break;

    case GLOBAL_INVENTORY:
      if (from.location === EQUIPMENT_FIGURE) {
        $addMessageLogMessage(
          text('ui.warnings.no_unequip_item_to_global'),
          Colors.RED,
        );
        return;
      } else {
        GameItemActions.placeItemInGlobalInventory(action_payload);
      }
      break;
  }

  // spinners
  CanvasTools.makeFaerieSpinners(from_item_icon);
  to_item_icon && CanvasTools.makeFaerieSpinners(to_item_icon);
}

function onMouseMove(event) {
  if (_currently_dragging_icon_data) {
    // track the mouse
    _currently_dragging_icon_data.game_item_icon.img.x = event.x;
    _currently_dragging_icon_data.game_item_icon.img.y = event.y;
  }
}

function onMouseUp() {
  if (!_currently_dragging_icon_data) {
    return;
  }
  // kill the dragging icon & refresh its container
  const { from, game_item_icon } = _currently_dragging_icon_data;
  DT_CANVAS_GLOBALS.stage.removeChild(game_item_icon.img);
  switch (from.location) {
    case GLOBAL_INVENTORY: makeGlobalInventoryIcons(); break;
  }
  _currently_dragging_icon_data = null;
}

function onSellItemIconClicked(item_uid) {
  onItemToSellIconClick({
    game_item_icon: _global_game_item_icons
      .filter(icon => !!icon.getItem())
      .find(icon => icon.getItem().uid === item_uid)
  });
}

function processIconForItemToSell(game_item_icon) {
  // grey out from global inventory
  game_item_icon.alpha_buffer = game_item_icon.alpha;
  game_item_icon.alpha = 0.27;
  // buffer & reverse the click handler
  game_item_icon.click_handler_buffer = game_item_icon.click;
  game_item_icon.tap = game_item_icon.click = onItemToSellIconClick.bind(null, { game_item_icon });
}

function renderCanvas() {
  state.canvas_root?.dispose();
  state.canvas_root = new ShopScreen_canvas();
  const { x, y } = CanvasTools.getDomAnchor('shop_screen_root') || { x: 0, y: 0 };
  state.canvas_root.x = x;
  state.canvas_root.y = y;
  DT_CANVAS_GLOBALS.stage.addChildAt(state.canvas_root, 0);

  makeEquippedGlobalBagIcons();
  makeGlobalInventoryIcons();

  state.canvas_root.shopInventoryShelves.event_emitter.on('ITEM_PURCHASE_CONFIRMED', onItemPurchaseConfirmed);
}
</script>
