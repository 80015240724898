import * as PIXI from 'pixi.js';
import Balance from 'dt-common/constants/Balance';
import getAvailableAttributePoints from 'dt-common/isomorphic-helpers/getAvailableAttributePoints';
import getUnitState from 'dt-common/isomorphic-helpers/getUnitState';
import Audio from '~/Audio';
import Tooltip from '~/components/tooltips/Tooltip';
import Colors from '~/constants/Colors';
import { EditHeroActions } from '~/flux/actions';
import { FluxGetters, HeroBuildStore, UIStore } from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import AttPointsPanel from './AttPointsPanel';

const EditAttributes = function() {
  PIXI.Container.call(this);

  const { built_hero, hero_handle, roster_hero } = FluxGetters.getFocusedHeroBuild();
  let _hero_state = getUnitState({
    roster_hero,
    unit_build: built_hero,
  });

  let _available_att_points = getAvailableAttributePoints({ built_hero, roster_hero });

  let _primaryAttFields = [];
  let _secondaryAttFields = [];
  let _attPointsPanel;

  this.transitionIn = (duration) => {
    TweenMax.from(this, duration, { x: window.innerWidth });
  };

  this.transitionOut = (duration, onComplete) => {
    TweenMax.to(this, duration, {
      x: -window.innerWidth,
      onComplete,
    });
  };

  // compatability with legacy where there were "InfoPanels", not Tooltips
  this.hidePopups = () => {};

  this.dispose = () => {
    HeroBuildStore.removeListener(HeroBuildStore.ATTRIBUTE_CHANGE, onAttributeChange);
    UIStore.removeListener(UIStore.GAME_SUBMODE_SELECTION, onAttributeChange);

    destroyPrimaryAttributeFields();
    destroySecondaryAttributeFields();
    destroyAttPointsPanel();

    if (_tooltip) {
      _tooltip.dispose();
      _tooltip = null;
    }

    this.destroy();
  };

  const TITLE_STYLE = {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(18),
    fontStyle: 'bold',
    fill: Colors.BRIGHT_YELLOW,
  };

  let _tooltip;

  const destroyPrimaryAttributeFields = () => {
    if (_primaryAttFields) {
      for (const attField of  _primaryAttFields) {
        attField.mouseover = attField.mouseout = null;
        attField.pointerover = attField.pointerout = null;

        if (attField.upgradeButton) {
          attField.upgradeButton.tap = attField.upgradeButton.click = null;
          attField.upgradeButton.mouseover = attField.upgradeButton.pointerover = null;
          attField.upgradeButton.mouseout = attField.upgradeButton.pointerout = null;
        }

        this.removeChild(attField);
      }
      _primaryAttFields = null;
    }
  };

  const destroySecondaryAttributeFields = () => {
    if (_secondaryAttFields) {
      for (const attField of _secondaryAttFields) {
        attField.mouseover = attField.mouseout = null;
        attField.pointerover = attField.pointerout = null;
        this.removeChild(attField);
      }
      _secondaryAttFields = null;
    }
  };

  const destroyAttPointsPanel = () => {
    if (_attPointsPanel) {
      _attPointsPanel.dispose();
      this.removeChild(_attPointsPanel);
      _attPointsPanel = null;
    }
  };

  // make column titles
  const MIDDLE_GAP = DT_CANVAS_GLOBALS.spacing * 3.5;
  // primary attributes
  var _primaryTitle = new PIXI.Text(text('ui.primary_attributes'), TITLE_STYLE);
  _primaryTitle.x = Math.round(window.innerWidth / 2  - _primaryTitle.width - MIDDLE_GAP);
  _primaryTitle.y = Math.round(window.innerHeight * 0.132);
  this.addChild(_primaryTitle);
  // secondary attributes
  var _secondaryTitle = new PIXI.Text(text('ui.secondary_attributes'), TITLE_STYLE);
  _secondaryTitle.x = Math.round(window.innerWidth / 2 + MIDDLE_GAP);
  _secondaryTitle.y = Math.round(_primaryTitle.y);
  this.addChild(_secondaryTitle);

  const ATT_LABEL_STYLE = {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(14),
    fill: 0xffffff,
  };
  const ATT_VALUE_STYLE = Object.assign({}, ATT_LABEL_STYLE, {
    fill: Colors[hero_handle],
    fontStyle: 'bold',
    dropShadow: true,
    dropShadowColor: 0x777777,
    dropShadowAngle: Math.PI / 3,
    dropShadowDistance: 1,
  });
  const ATT_FIELD_PADDING = DT_CANVAS_GLOBALS.spacing * 0.75;

  const makePrimaryAttributeField = (attTag) => {
    try {
      var attField = new PIXI.Container();
      var label = new PIXI.Text(text('game.'+attTag)+':', ATT_LABEL_STYLE);
      label.attTag = attTag;
      label.x = Math.round(ATT_FIELD_PADDING);
      label.y = Math.round(ATT_FIELD_PADDING);
      attField.addChild(label);
      attField.label = label;

      attField.x = Math.round(_primaryTitle.x + _primaryTitle.width / 2 - label.width - DT_CANVAS_GLOBALS.spacing);
      const field_above = _primaryAttFields.length === 0
        ? _primaryTitle
        : _primaryAttFields[_primaryAttFields.length - 1];
      attField.y = Math.round(field_above.y + field_above.height + (attTag === 'learning' ? DT_CANVAS_GLOBALS.spacing : 3));

      const atts = _hero_state.attributes;
      const attValue = atts.base[attTag] + atts.fromEquipment[attTag] + atts.fromUpgrades[attTag];

      const vf = attField.valueField = new PIXI.Text(attValue, ATT_VALUE_STYLE);
      vf.x = Math.round(label.x + label.width + DT_CANVAS_GLOBALS.spacing / 2);
      vf.y = Math.round(label.y + label.height/2 - vf.height / 2);
      attField.addChild(vf);

      // upgrade button
      const ub = new PIXI.Sprite();
      ub.texture = PIXI.utils.TextureCache['plus.png'];
      ub.anchor.x = ub.anchor.y = 0.5;
      ub.x = label.x + label.width + DT_CANVAS_GLOBALS.spacing * 4 + ub.width / 2;
      ub.y = label.y + label.height / 2;
      attField.addChild(ub);
      ub.attTag = attTag;
      attField.upgradeButton = ub;

      const upgradeCost = Balance.getAttUpgradeCost(atts.base[attTag] + atts.fromUpgrades[attTag]);
      if (_available_att_points >= upgradeCost) {
        // add upgrade button interactions
        ub.interactive = ub.buttonMode = true;

        // click - make spinners & send the upgrade request
        ub.tap = ub.click = (event) => {
          CanvasTools.makeFaerieSpinners(attField.upgradeButton, 4);
          CanvasTools.makeFaerieSpinners(attField.valueField, 4);

          const { current_game_mode, current_game_submode } = UIStore.getAll();

          EditHeroActions.upgradeAttribute({
            attribute_handle: event.target.attTag,
            hero_build_id: built_hero._id,
            hero_handle,
          });
        };

        // mouseover - tint selected hero's color
        ub.mouseover = ub.pointerover = () => {
          ub.tint = Colors.BRIGHT_YELLOW;
        };
        // mouseout - tint back to white
        ub.mouseout = ub.pointerout = () => {
          ub.tint = 0xffffff;

          ub.mouseup();
        };
        // mousedown - scale down
        ub.mousedown = ub.touchstart = () => {
          ub.scale_buffer = { x: ub.scale.x, y: ub.scale.y };
          ub.scale = { x: ub.scale.x * 0.8, y: ub.scale.y * 0.8 };
        };
        // mouseup - scale up
        ub.mouseup = ub.touchend = () => {
          if (ub.scale_buffer) {
            ub.scale = ub.scale_buffer;
          }
        };
      } else {
        ub.tint = 0x171717;
      }

      // add invisible backdrop to catch mouse activity anywhere on the line
      attField._width = attField.width + ATT_FIELD_PADDING * 2;
      attField._height = attField.height + ATT_FIELD_PADDING * 2;
      CanvasTools.addBackFill(attField, 0x000000, 0.01);

      // add mouseover tooltip
      attField.interactive = true;
      attField.mouseover = attField.pointerover = onAttFieldMouseover.bind(null, attField, attTag, 'primary');
      attField.mouseout = attField.pointerout = onAttFieldMouseout.bind(null, attField);

      this.addChild(attField);
      _primaryAttFields.push(attField);
    } catch (err) {
      logError(err, {
        module: 'components/ui_screens/edit_hero/edit_attributes/EditAttributes',
        func: 'makePrimaryAttributeField',
      });
    }
  };

  const onAttFieldMouseover = (attField, attTag, type) => {
    if (_tooltip) {
      _tooltip.dispose();
    }

    if (type === 'primary') {
      const { base, fromUpgrades, fromEquipment } = _hero_state.attributes;
      const cost = Balance.getAttUpgradeCost(base[attTag] + fromUpgrades[attTag]);

      _tooltip = new Tooltip(
        'primary_attribute',
        {
          handle: attTag,
          base: base[attTag],
          fromEquipment: fromEquipment[attTag],
          fromUpgrades: fromUpgrades[attTag],
          cost,
          has_upgrade_cost: _available_att_points >= cost,
        },
        attField,
      );
    } else if (type === 'secondary') {
      _tooltip = new Tooltip(
        'secondary_attribute',
        {
          handle: attTag,
          value: _hero_state.attributes[attTag],
        },
        attField,
      );
    }

    if (!attField.color_buffer) {
      attField.color_buffer = attField.label.style.fill;
    }
    attField.label.style.fill = Colors.BRIGHT_YELLOW;
  };

  const onAttFieldMouseout = (attField) => {
    if (_tooltip) {
      _tooltip.dispose();
      _tooltip = null;
    }

    if (attField.color_buffer) {
      attField.label.style.fill = attField.color_buffer;
    }
  };

  const makePrimaryAttributeFields = () => {
    destroyPrimaryAttributeFields();

    _primaryAttFields = [];
    makePrimaryAttributeField('learning');
    makePrimaryAttributeField('perception');
    makePrimaryAttributeField('mana');
    makePrimaryAttributeField('toughness');
    makePrimaryAttributeField('strength');
    makePrimaryAttributeField('willpower');
    makePrimaryAttributeField('dexterity');
  };

  const makeSecondaryAttributeField = (attTag) => {
    try {
      var attField = new PIXI.Container();
      var label = new PIXI.Text(text('game.'+attTag)+':', ATT_LABEL_STYLE);
      label.attTag = attTag;
      label.x = Math.round(ATT_FIELD_PADDING);
      label.y = Math.round(ATT_FIELD_PADDING);
      attField.addChild(label);
      attField.label = label;

      attField.x = Math.round(_secondaryTitle.x + _secondaryTitle.width / 2 - attField.width + DT_CANVAS_GLOBALS.spacing * 1.5);

      const field_above = _secondaryAttFields.length === 0
        ? _secondaryTitle
        : _secondaryAttFields[_secondaryAttFields.length - 1];
      attField.y = Math.round(field_above.y + field_above.height + (attTag === 'meleeDamage' ? DT_CANVAS_GLOBALS.spacing : 3));

      var valueText = _hero_state.attributes[attTag];
      switch (attTag) {
        case 'meleeDamage':
        case 'armorClass':
          valueText = valueText.toFixed(0);
          break;

        default:
          valueText = valueText.toFixed(2);
          break;
      }
      if (['dodgeChance', 'physicalResil', 'mentalResil'].includes(attTag)) {
        valueText += '%';
      }

      var valueField = new PIXI.Text(valueText, ATT_VALUE_STYLE);
      valueField.x = Math.round(label.x + label.width + DT_CANVAS_GLOBALS.spacing);
      valueField.y = Math.round(label.y + label.height / 2 - valueField.height / 2);
      attField.addChild(valueField);

      // add invisible backdrop to catch mouse activity anywhere on the line
      attField._width = attField.width + ATT_FIELD_PADDING * 2;
      attField._height = attField.height + ATT_FIELD_PADDING * 2;
      CanvasTools.addBackFill(attField, 0x000000, 0.01);

      // add mouseover tooltip
      attField.interactive = true;
      attField.mouseover = attField.pointerover = onAttFieldMouseover.bind(null, attField, attTag, 'secondary');
      attField.mouseout = attField.pointerout = onAttFieldMouseout.bind(null, attField);

      this.addChild(attField);
      _secondaryAttFields.push(attField);
    } catch (err) {
      logError(err, {
        module: 'components/ui_screens/edit_hero/edit_attributes/EditAttributes',
        func: 'makeSecondaryAttributeField',
        attTag,
      });
    }
  };

  const makeSecondaryAttributeFields = () => {
    destroySecondaryAttributeFields();

    _secondaryAttFields = [];
    makeSecondaryAttributeField('meleeDamage');
    makeSecondaryAttributeField('armorClass');
    makeSecondaryAttributeField('dodgeChance');
    makeSecondaryAttributeField('physicalResil');
    makeSecondaryAttributeField('mentalResil');
    makeSecondaryAttributeField('regeneration');
    makeSecondaryAttributeField('speed');
    makeSecondaryAttributeField('magicalGravity');
  };

  const makeAttPointsPanel = () => {
    destroyAttPointsPanel();

    _attPointsPanel = new AttPointsPanel();
    _attPointsPanel.x = Math.round(_primaryTitle.x - _attPointsPanel.width - MIDDLE_GAP);
    const mid_att_field = _primaryAttFields[3];
    _attPointsPanel.y = Math.round(mid_att_field.y + mid_att_field.height / 2);
    this.addChild(_attPointsPanel);
  };

  // initialization
  makePrimaryAttributeFields();
  makeSecondaryAttributeFields();
  makeAttPointsPanel();

  const onAttributeChange = () => {
    try {
      const heroBuffer = JSON.parse(JSON.stringify(_hero_state));
      const { built_hero, roster_hero } = FluxGetters.getFocusedHeroBuild();
      _available_att_points = getAvailableAttributePoints({ built_hero, roster_hero });
      _hero_state = getUnitState({
        roster_hero,
        unit_build: built_hero,
      });

      // check for attribute level-ups & play a sound
      for (let prop in _hero_state.attributes.fromUpgrades) {
        if (_hero_state.attributes.fromUpgrades[prop] > heroBuffer.attributes.fromUpgrades[prop]) {
          Audio.play('star_twinkle');

          if (_tooltip) {
            _tooltip.dispose();
            _tooltip = null;
          }
          break;
        }
      }

      // check for reset && play a sound
      var allLevelZero = true;
      for (let prop in _hero_state.attributes.fromUpgrades) {
        if (_hero_state.attributes.fromUpgrades[prop] > 0) {
          allLevelZero = false;
          break;
        }
      }
      if (allLevelZero) {
        Audio.play('electric_smash');
        if (_tooltip) {
          _tooltip.dispose();
          _tooltip = null;
        }
      }

      makePrimaryAttributeFields();
      makeSecondaryAttributeFields();
      makeAttPointsPanel();
    } catch (err) {
      logError(err, {
        module: 'components/ui_screens/edit_hero/edit_attributes',
        func: 'onAttributeChange',
      });
    }
  };
  HeroBuildStore.on(HeroBuildStore.ATTRIBUTE_CHANGE, onAttributeChange);
  UIStore.on(UIStore.GAME_SUBMODE_SELECTION, onAttributeChange);
};
EditAttributes.prototype = Object.create(PIXI.Container.prototype);
EditAttributes.prototype.constructor = EditAttributes;
export default EditAttributes;
