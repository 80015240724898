import * as PIXI from 'pixi.js';
import AltarStore from '~/flux/stores/AltarStore';
import LightFromAboveEffect from '~/view/game-screens/battle/canvas/game_board/effects/LightFromAboveEffect';

const AltarImg = function(alignment) {
  PIXI.Container.call(this);

  this.dispose = () => {		
    AltarStore.removeListener(AltarStore.GOT_ALTAR_STATE, refresh);

    if(_holyLightTween) {
      _holyLightTween.kill();
    }

    TweenMax.killTweensOf(makeSparkle);

    this.removeChildren();
  };

  const ALTAR_WIDTH = this.ALTAR_WIDTH = window.innerWidth * 0.75;

  var _leftPillar = new PIXI.Sprite();
  _leftPillar.texture = PIXI.utils.TextureCache['altar/chaos_portrait.png'];
  _leftPillar.x = ALTAR_WIDTH*0.005;
  this.addChild(_leftPillar);

  var _rightPillar = new PIXI.Sprite();
  _rightPillar.texture = PIXI.utils.TextureCache['altar/order_portrait.png'];
  _rightPillar.x = ALTAR_WIDTH*0.995 - _rightPillar.width;
  this.addChild(_rightPillar);

  var _centerPillar = new PIXI.Sprite();
  _centerPillar.texture = PIXI.utils.TextureCache['altar/balance_portrait.png'];
  _centerPillar.x = ALTAR_WIDTH/2 - _centerPillar.width/2;
  this.addChild(_centerPillar);

  const makeAltarMeter = () => {
    var board = new PIXI.Container();
    while (board.width < ALTAR_WIDTH*0.18) {
      var tile = new PIXI.Sprite();
      tile.texture = PIXI.utils.TextureCache['altar/altar_meter.png'];
      tile.x = board.width;
      board.addChild(tile);
    }

    return board;
  };

  var _leftBoard = makeAltarMeter();
  _leftBoard.x = ALTAR_WIDTH*0.31 - _leftBoard.width/2;
  _leftBoard.y = this.height/2 - _leftBoard.height/2;
  this.addChild(_leftBoard);

  var _rightBoard = makeAltarMeter();
  _rightBoard.x = ALTAR_WIDTH*0.69 - _rightBoard.width/2;
  _rightBoard.y = this.height/2 - _rightBoard.height/2;
  this.addChild(_rightBoard);

  var _candle = new PIXI.Sprite();
  _candle.texture = PIXI.utils.TextureCache['effects/light_from_above_18.png'];
  _candle.anchor.x = 0.5;
  _candle.anchor.y = 0.9;
  _candle.height = this.height * 3;
  // _candle.scale.x = _candle.scale.y;
  _candle.x = ALTAR_WIDTH*0.085 + ALTAR_WIDTH*0.83*alignment;
  _candle.y = this.height/2 - 1;
  this.addChild(_candle);

  var _holyLightTween;
  const fadeHolyLight = () => {
    if(_holyLightTween) {
      _holyLightTween.kill();
    }

    var destAlpha = _candle.alpha===1? 0.4+Math.random()*0.4 : 1;
    _holyLightTween = TweenMax.to(_candle, 0.5+Math.random()*2, {alpha:destAlpha, onComplete:fadeHolyLight});
  };
  fadeHolyLight();

  const makeSparkle = () => {
    var sparkle = new PIXI.Graphics();
    sparkle.beginFill(0xefe57a, 0.5+Math.random()*0.5);
    sparkle.drawRect(0, 0, 1, 1);
    sparkle.endFill();
    sparkle.x = _candle.x -_candle.width*0.3 + Math.random()*_candle.width*0.6;
    sparkle.y = _candle.y -_candle.height*0.5;
    var duration = 9+Math.random()*4;
    TweenMax.to(sparkle, duration, {
      y: _candle.y,
      ease: Linear.easeNone,
      onComplete: (deadSparkle)=>{
        if(deadSparkle.parent) {
          deadSparkle.parent.removeChild(deadSparkle);
        }
      },
      onCompleteParams: [sparkle]
    });
    TweenMax.to(sparkle, duration, {alpha:0,ease:Quad.easeIn});
    this.addChild(sparkle);

    TweenMax.delayedCall(0.05+Math.random()*0.2, makeSparkle);
  };
  makeSparkle();

  const MARKER_SIZE = 32;
  var mask;

  var _chaosAffinityMarker;
  const caf = _chaosAffinityMarker = new PIXI.Container();
  caf.empty = new PIXI.Graphics();
  caf.empty.beginFill(0x440000);
  caf.empty.drawRect(0, 0, MARKER_SIZE, -MARKER_SIZE);
  caf.empty.endFill();
  caf.addChild(caf.empty);
  caf.full = new PIXI.Graphics();
  caf.full.beginFill(0xff0000);
  caf.full.drawRect(0, 0, MARKER_SIZE, -MARKER_SIZE);
  caf.full.endFill();
  caf.full.scale.y = AltarStore.getAll().chaosAffinity;
  caf.addChild(caf.full);
  caf.x = _leftPillar.x + _leftPillar.width / 2 - MARKER_SIZE / 2;
  caf.y = _leftPillar.y + _leftPillar.height / 2 + MARKER_SIZE / 2;
  mask = new PIXI.Graphics();
  mask.beginFill(0x000000, 0.01);
  mask.drawCircle(MARKER_SIZE/2, -MARKER_SIZE/2, MARKER_SIZE/2);
  mask.endFill();
  caf.addChild(mask);
  caf.mask = mask;
  this.addChildAt(caf, 0);

  var _balanceAffinityMarker;
  const baf = _balanceAffinityMarker = new PIXI.Container();
  baf.empty = new PIXI.Graphics();
  baf.empty.beginFill(0x004400);
  baf.empty.drawRect(0, 0, MARKER_SIZE,-MARKER_SIZE);
  baf.empty.endFill();
  baf.addChild(baf.empty);
  baf.full = new PIXI.Graphics();
  baf.full.beginFill(0x00ff00);
  baf.full.drawRect(0, 0, MARKER_SIZE, -MARKER_SIZE);
  baf.full.endFill();
  baf.full.scale.y = AltarStore.getAll().balanceAffinity;
  baf.addChild(baf.full);
  baf.x = _centerPillar.x + _centerPillar.width/2 - MARKER_SIZE/2;
  baf.y = _centerPillar.y + _centerPillar.height/2 + MARKER_SIZE/2 - 2;
  mask = new PIXI.Graphics();
  mask.beginFill(0x000000, 0.01);
  mask.drawCircle(MARKER_SIZE/2, -MARKER_SIZE/2, MARKER_SIZE/2);
  mask.endFill();
  baf.addChild(mask);
  baf.mask = mask;
  this.addChildAt(baf, 0);

  var _orderAffinityMarker;
  const oaf = _orderAffinityMarker = new PIXI.Container();
  oaf.empty = new PIXI.Graphics();
  oaf.empty.beginFill(0x000044);
  oaf.empty.drawRect(0, 0, MARKER_SIZE, -MARKER_SIZE);
  oaf.empty.endFill();
  oaf.addChild(oaf.empty);
  oaf.full = new PIXI.Graphics();
  oaf.full.beginFill(0x0000ff);
  oaf.full.drawRect(0, 0, MARKER_SIZE, -MARKER_SIZE);
  oaf.full.endFill();
  oaf.full.scale.y = AltarStore.getAll().orderAffinity;
  oaf.addChild(oaf.full);
  oaf.x = _rightPillar.x + _rightPillar.width/2 - MARKER_SIZE/2;
  oaf.y = _rightPillar.y + _rightPillar.height/2 + MARKER_SIZE/2;
  mask = new PIXI.Graphics();
  mask.beginFill(0x000000, 0.01);
  mask.drawCircle(MARKER_SIZE/2, -MARKER_SIZE/2, MARKER_SIZE/2);
  mask.endFill();
  oaf.addChild(mask);
  oaf.mask = mask;
  this.addChildAt(oaf, 0);

  var _lastLevel = AltarStore.getAll().altarLevel;

  const refresh = () => {
    const altarState = AltarStore.getAll();

    TweenMax.to(_candle, 3.0+Math.random()*1, {x:ALTAR_WIDTH*0.085 + ALTAR_WIDTH*0.83*altarState.alignment, ease:Quad.easeInOut});
    TweenMax.to(_chaosAffinityMarker.full.scale, 2.5+Math.random()*1.5, {y:altarState.chaosAffinity});
    TweenMax.to(_balanceAffinityMarker.full.scale, 2.5+Math.random()*1.5, {y:altarState.balanceAffinity});		
    TweenMax.to(_orderAffinityMarker.full.scale, 2.5+Math.random()*1.5, {y:altarState.orderAffinity});

    if(altarState.altarLevel > _lastLevel) {
      var chaosLight = new LightFromAboveEffect(1000);
		    chaosLight.width = _leftPillar.width*2;
		    chaosLight.scale.y = chaosLight.scale.x;
		    chaosLight.alpha = 0.6;
		    chaosLight.tint = 0xff0000;
		    chaosLight.x = _leftPillar.x + _leftPillar.width/2;
		    chaosLight.y = -_leftPillar.height/2;
		    chaosLight.scale.y *= -1;
		    this.addChild(chaosLight);

		    var balanceLight = new LightFromAboveEffect(1000);
		    balanceLight.width = _centerPillar.width*2;
		    balanceLight.scale.y = balanceLight.scale.x;
		    balanceLight.alpha = 0.6;
		    balanceLight.tint = 0x00ff00;
		    balanceLight.x = _centerPillar.x + _centerPillar.width/2;
		    balanceLight.y = -_centerPillar.height/2;
		    balanceLight.scale.y *= -1;
		    this.addChild(balanceLight);

		    var orderLight = new LightFromAboveEffect(1000);
		    orderLight.width = _rightPillar.width*2;
		    orderLight.scale.y = orderLight.scale.x;
		    orderLight.alpha = 0.6;
		    orderLight.tint = 0x0000ff;
		    orderLight.x = _rightPillar.x + _rightPillar.width/2;
		    orderLight.y = -_rightPillar.height/2;
		    orderLight.scale.y *= -1;
		    this.addChild(orderLight);

      _lastLevel = altarState.altarLevel;
    }
  };	
  AltarStore.on(AltarStore.GOT_ALTAR_STATE, refresh);
};
AltarImg.prototype = Object.create(PIXI.Container.prototype);
AltarImg.prototype.constructor = AltarImg;
export default AltarImg;