import Colors from '~/constants/Colors';
import onLootImgMouseover  from "./onLootImgMouseover";

const GoldSpawnRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const tileSprite = gameBoard.getAllPieceSprites()[data.tileId];
		if( !tileSprite ) {
			return;
		}
		
		if (tileSprite.lootImg && tileSprite.lootImg.text !== '$') {
			tileSprite.lootImg.text = '&';
			tileSprite.lootImg.style.fill = 0xffffff;
		} else if (!tileSprite.lootImg ) { 
			tileSprite.lootImg = tileSprite.txtPool.get();
			tileSprite.lootImg.text = '$';
      tileSprite.lootImg.style.fontSize = 24;
			tileSprite.lootImg.style.fill = Colors.GOLD;

			tileSprite.addChild( tileSprite.lootImg );

			tileSprite.lootImg.interactive = true;
			tileSprite.lootImg.mouseover = tileSprite.lootImg.touch = onLootImgMouseover;

			tileSprite.tileImg.visible = false;
		}
	}
};
export default GoldSpawnRendering;