import * as PIXI from 'pixi.js';
import { Colors, Screens } from '~/constants';
import { UIActions } from '~/flux/actions';

const SquadPuppetMouseoverPanel = function({
  onHeroBuildSelected,
  out_build,
}) {
  PIXI.Container.call(this);

  // swap engagement button
  const swap_btn = new PIXI.Sprite();
  swap_btn.texture = PIXI.utils.TextureCache['arena_lobby/change_team_icon.png'];
  swap_btn.width = DT_CANVAS_GLOBALS.spacing * 5;
  swap_btn.scale.y = swap_btn.scale.x;
  swap_btn.anchor = { x: 0.5, y: 0.5 };
  swap_btn.x = Math.round(-swap_btn.width / 2 - DT_CANVAS_GLOBALS.spacing * 1.25);
  swap_btn.y = Math.round(-swap_btn.height);
  this.swap_btn = swap_btn;
  this.addChild(swap_btn);
  swap_btn.interactive = swap_btn.buttonMode = true;
  swap_btn.mouseover = () => {
    swap_btn.tint = Colors.GREEN;
  }
  swap_btn.mouseout = () => {
    swap_btn.tint = 0xffffff;
  }
  swap_btn.tap = swap_btn.click = (event) => {
    event.stopPropagation();
    UIActions.showModal({
      modal_key: 'SwapEngagementModal',
      modal_props: { onHeroBuildSelected },
    });
  };
  TweenMax.from(
    swap_btn.scale,
    0.6,
    { x: 0, y: 0, ease: Elastic.easeOut },
  );

  // edit hero button
  const edit_btn = new PIXI.Sprite();
  edit_btn.texture = PIXI.utils.TextureCache['armory/class_customization_btn.png'];
  edit_btn.width = DT_CANVAS_GLOBALS.spacing * 5;
  edit_btn.scale.y = edit_btn.scale.x;
  edit_btn.anchor = { x: 0.5, y: 0.5 };
  edit_btn.x = Math.round(edit_btn.width / 2 + DT_CANVAS_GLOBALS.spacing * 1.25);
  edit_btn.y = Math.round(-edit_btn.height);
  this.edit_btn = edit_btn;
  this.addChild(edit_btn);
  edit_btn.interactive = edit_btn.buttonMode = true;
  edit_btn.mouseover = () => {
    edit_btn.tint = Colors.GREEN;
  }
  edit_btn.mouseout = () => {
    edit_btn.tint = 0xffffff;
  }
  edit_btn.tap = edit_btn.click = (event) => {
    event.stopPropagation();
    UIActions.focusHeroBuild({
      hero_handle: out_build.hero_handle,
      build_id: out_build._id,
    });
    UIActions.uiNav({ screen_id: Screens.EDIT_HERO });
  };
  TweenMax.from(
    edit_btn.scale,
    0.6,
    { x: 0, y: 0, ease: Elastic.easeOut },
  );
}
SquadPuppetMouseoverPanel.prototype = Object.create(PIXI.Container.prototype);
SquadPuppetMouseoverPanel.prototype.constructor = SquadPuppetMouseoverPanel;
export default SquadPuppetMouseoverPanel;
