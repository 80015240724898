<template>
  <div
    id="inn_opponents_view"
    class="relative w-full pl-12 flex flex-col items-center"
  >
    <div class="absolute -bottom-[145px] right-[25%] flex items-center">
      <DTButton
        type="info"
        class="max-h-10 min-w-12"
        @click="refreshOpponents"
      >
        <img
          :src="`${Config.DOM_IMG_PATH}/refresh.png`"
          :class="{
            'my-1 mx-4 h-6 w-auto': true,
            spin: state.pending_opponent_data,
          }"
        >
      </DTButton>
      <div
        id="opponents_title"
        class="ml-6 text-xl font-bold underline no-select"
      >
        {{ text('ui.opponents') }}
      </div>
    </div>
    <div
      id="opponents_list"
      class="w-full h-full mt-4 flex items-center justify-center"
    >
      <div
        v-if="state.pending_opponent_data"
        class="w-full h-full flex justify-center items-center"
      >
        <DTSpinner />
      </div>
      <InnOpponentListItem
        v-else-if="state.current_inn_submode_state?.current_rank > 1"
        v-for="opponent, i of state.opponents"
        :key="i"
        :opponent_index="i"
        :opponent="opponent"
      />
      <div v-if="!state.pending_opponent_data && state.opponents.length === 0">
        {{ text('ui.everyone_embattled') }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, reactive } from 'vue';
import Config from '~/constants/Config';
import { InnActions } from '~/flux/actions';
import { InnStore, UIStore } from '~/flux/stores';
import text from '~/text';
import { DTButton, DTSpinner } from '~/view/components/common/DOM';
import InnOpponentListItem from './InnOpponentListItem.vue';

const NUM_OPPONENTS_PER_REFRESH = 3;

const state = reactive({
  opponents: [],
  pending_opponent_data: false,
  current_inn_submode_state: InnStore.getAll().current_submode_state,
});

onMounted(() => {
  InnStore.on(InnStore.GOT_INN_OPPONENTS, onGotOpponents);
  InnStore.on(InnStore.GOT_PLAYER_INN_SUBMODE_STATE, refreshOpponents);
  UIStore.on(UIStore.GAME_SUBMODE_SELECTION, onGameSubmodeSelection);

  state.current_inn_submode_state = InnStore.getAll().current_submode_state;
  state.opponents = InnStore.getAll().current_submode_state?.current_rank === 1
    ? []
    : InnStore.getAll().opponents || [];
});

onBeforeUnmount(() => {
  InnStore.removeListener(InnStore.GOT_INN_OPPONENTS, onGotOpponents);
  InnStore.removeListener(InnStore.GOT_PLAYER_INN_SUBMODE_STATE, refreshOpponents);
  UIStore.removeListener(UIStore.GAME_SUBMODE_SELECTION, onGameSubmodeSelection);
});

function refreshOpponents() {
  state.pending_opponent_data = true;
  InnActions.fetchRandomInnOpponents({
    game_submode: UIStore.getAll().current_game_submode,
    num_opponents: NUM_OPPONENTS_PER_REFRESH,
  });
}

function onGameSubmodeSelection() {
  state.current_inn_submode_state = InnStore.getAll().current_submode_state;
  state.opponents = InnStore.getAll().current_submode_state?.current_rank === 1
    ? []
    : InnStore.getAll().opponents || [];
}

function onGotOpponents({ inn_opponents }) {
  state.current_inn_submode_state = InnStore.getAll().current_submode_state;
  state.opponents = inn_opponents;
  state.pending_opponent_data = false;
}
</script>

<style>
@-moz-keyframes spin {
  to { -moz-transform: rotate(-360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(-360deg); }
}
@keyframes spin {
  to {transform:rotate(-360deg);}
}

.spin {
  animation: spin 800ms linear infinite;
}
</style>