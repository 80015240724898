import * as PIXI from 'pixi.js';
import TileSprite from './piece_sprites/TileSprite';
import UnitSprite from './piece_sprites/UnitSprite';
import Game from 'dt-common/constants/Game';
import BattleStore from '~/flux/stores/BattleStore';
import BattleActions from '~/flux/actions/BattleActions';
import ActionRenderer from './action_renderings/ActionRenderer';
import Colors from '~/constants/Colors';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import UnitDetailsView from '~/view/components/footer/Footer_canvas/battle_HUD/UnitDetailsView';
import CanvasTools from '~/view/CanvasTools';
import AStarSearch from 'dt-common/battle_engine/ai/AStarSearch/AStarSearch';

const GameBoard = function(battleState) {
  PIXI.Container.call(this);

  this.getAllPieceSprites = () => { return _allPieceSprites; };
  this.getTileSprites = () => { return _tileSprites; };
  var _allPieceSprites = {};
  var _tileSprites = {};
  var _unitSprites = {};
  var _unitSpritesArray;
  var _unitDetailsPopup;

  var _aStarSearch = new AStarSearch();

  const onPieceSpriteClick = (event) => {
    try {
      var gp = event.target.gamePiece;

      if (gp.unit && gp.dead) {
        BattleActions.selectGamePiece(battleState.getTileAtCoords(gp.x,gp.y));
      } else {
        BattleActions.selectGamePiece(event.target.gamePiece);
      }
    } catch (err) {
      logError(err, {
        module: 'GameBoard',
        func: 'onPieceSpriteClick',
        event,
      });
    }
  };

  const makeUnitDetailsPopup = (mouse, unit) => {
    try {
  		_unitDetailsPopup = new UnitDetailsView(unit, true);
  		_unitDetailsPopup.x = Math.round(CanvasTools.getBattlePopupX(mouse.x,_unitDetailsPopup.width));
  		_unitDetailsPopup.y = Math.round(CanvasTools.getBattlePopupY(mouse.y,_unitDetailsPopup.height));
  		_unitDetailsPopup.pivot.x = Math.round(_unitDetailsPopup.width/2);
  		_unitDetailsPopup.pivot.y = Math.round(_unitDetailsPopup.height/2);
  		TweenMax.from(_unitDetailsPopup, 0.15, { alpha: 0,ease: Quad.easeIn });
  		TweenMax.from(_unitDetailsPopup.scale, 0.15, { x: 0,y: 0 });
  		DT_CANVAS_GLOBALS.stage.addChild(_unitDetailsPopup);
    } catch (err) {
      logError(err, {
        module: 'GameBoard',
        func: 'makeUnitDetailsPopup',
        mouse,
        unit,
      });
    }
  };

  const onUnitSpriteMouseover = (event) => {
    try {
      hideUnitDetailsPopup();
      TweenMax.delayedCall(1.0, makeUnitDetailsPopup, [event.data.global,event.target.gamePiece]);
    } catch (err) {
      logError(err, {
        module: 'GameBoard',
        func: 'onUnitSpriteMouseover',
        event,
      });
    }
  };

  const hideUnitDetailsPopup = () => {
    try {
  		gsap.killTweensOf(makeUnitDetailsPopup);

  		if (_unitDetailsPopup) {
  			TweenMax.to(_unitDetailsPopup, 0.2, { alpha: 0, ease: Quad.easeOut });
  			TweenMax.to(_unitDetailsPopup.scale, 0.2, {
  				x: 0,
  				y: 0,
  				onComplete: (udp) => {
  					DT_CANVAS_GLOBALS.stage.removeChild(udp);
  				},
  				onCompleteParams: [_unitDetailsPopup],
  			});
  		}
    } catch (err) {
      logError(err, {
        module: 'GameBoard',
        func: 'hideUnitDetailsPopup',
      });
    }
  };

  const updateCollections = () => {
    try {
  		// _allPieceSprites is NOT cleared here
  		_unitSpritesArray = [];
  		for (const prop of Object.keys(battleState.allPieces)) {
  			const piece_state = battleState.allPieces[prop];

  			if (_allPieceSprites[prop]) {
  				_allPieceSprites[prop].gamePiece = piece_state;
  			} else {
  				// make a new piece sprite
  				if (piece_state.tile) {
  					var ts = new TileSprite(piece_state);
  					_allPieceSprites[prop] = ts;
  					this.addChild(ts);

  					// testing - display coords
  					// var coords = new PIXI.Text("("+tile.x+","+tile.y+")", { font:"14px Arial", fill:0xffffff} );
  					// coords.anchor.x = coords.anchor.y = 0.5;
  					// ts.addChild( coords );
  				} else if (piece_state.unit) {
  					var us = new UnitSprite(piece_state);
  					_allPieceSprites[prop] = us;
  					if (piece_state.facingDirection) {
  						us.puppet.face(piece_state.facingDirection);
  					} else if (piece_state.team === 'white') {
  						us.puppet.face(Game.NORTH);
  					}
  					this.addChild(us);
  				}

  				_allPieceSprites[prop].snapToBoardPosition(piece_state.x, piece_state.y);

  				var ps = _allPieceSprites[prop];
  				ps.click = ps.tap = onPieceSpriteClick;
  			}

  			if (piece_state.unit) {
  				_unitSprites[prop] = _allPieceSprites[prop];
  				_unitSpritesArray.push(_unitSprites[prop]);

  				_unitSprites[prop].updateUnit(piece_state);
  				_unitSprites[prop].statBar.update(piece_state);

  				_unitSprites[prop].mouseover = onUnitSpriteMouseover;
  				_unitSprites[prop].mouseout = hideUnitDetailsPopup;
  			} else if (piece_state.tile) {
  				_tileSprites[prop] = _allPieceSprites[prop];
  			}
  		}

  		// sort unit sprites for z-depth
  		_unitSpritesArray.sort((a,b) => {
  			return a.gamePiece.y - b.gamePiece.y;
  		});
  		for (var i = 0; i < _unitSpritesArray.length; ++i) {
  			this.addChild(_unitSpritesArray[i]);
  		}
    } catch (err) {
      logError(err, {
        module: 'GameBoard',
        func: 'updateCollections',
      });
    }
  };
  updateCollections();

  // sort tile sprites for z-depth
  var tileSpritesArray = [];
  for (var prop in _tileSprites) {
    tileSpritesArray.push(_tileSprites[prop]);
  }
  tileSpritesArray.sort((a,b) => {
    return a.gamePiece.y - b.gamePiece.y;
  });
  for (var i = 0; i < tileSpritesArray.length; ++i) {
    this.addChild(tileSpritesArray[i]);
  }

  // draw vertical lines down from tiles
  for (var prop in _tileSprites) {
    var tileImg = _tileSprites[ prop ].tileImg;
    var halfWidth = tileImg.width/2;
    var halfHeight = tileImg.height/2;

    var lines = [];
    for (var i = 0; i < 4; ++i) {
      var gfx = new PIXI.Graphics();
      gfx.lineStyle(1, 0x73654d);
      gfx.moveTo(0, 0);
      gfx.lineTo(0, 2000);
      lines.push(gfx);
      tileImg.addChild(gfx);
    }
    const SCALE = tileImg.scale.x;

    lines[0].position = { x: (0*SCALE-halfWidth)/SCALE, y: (20*SCALE-halfHeight)/SCALE };
    lines[1].position = { x: (10*SCALE-halfWidth)/SCALE, y: (36*SCALE-halfHeight)/SCALE };
    lines[2].position = { x: (31*SCALE-halfWidth)/SCALE, y: (36*SCALE-halfHeight)/SCALE };
    lines[3].position = { x: (41*SCALE-halfWidth)/SCALE, y: (20*SCALE-halfHeight)/SCALE };
  }

  // put unit sprites back ontop of tiles
  for (var prop in _unitSprites) {
    this.addChild(_unitSprites[prop]);
  }

  const gameBoardUpdate = () => {
    if (battleState && !BattleStore.getAll().paused) {
      for (const [uid, unit_state] of Object.entries(battleState.allUnits)) {
        const sprite = _unitSprites[uid];
        sprite.statBar?.update(unit_state);
        sprite.apMeter?.update(unit_state);
      }
    }
    _updateInterval = requestAnimationFrame(gameBoardUpdate);
  };
  var _updateInterval = requestAnimationFrame(gameBoardUpdate);

  var _medallion;
  var _medallionTween;
  const showMedallion = (unitId) => {
    const us = _unitSprites[unitId];

    if (us) {
      for (var prop in _unitSprites) {
  			_unitSprites[prop].tileImg.alpha = 0.01;
  		}
  		us.tileImg.alpha = 0.25;
    }
  };

  const onOrdersRequested = () => {
    showMedallion(BattleStore.getAll().unitToOrder);
  };

  const onResumingTime = () => {
    destroyMedallion();
    updateCollections();
  };

  var _actionRenderer = this.actionRenderer = new ActionRenderer(this);

  const doNormalTileTint = (sprite) => {
    if (sprite.gamePiece.team==='white') {
      sprite.tileImg.tint = 0x00ff00;
    } else if (sprite.gamePiece.team==='black') {
      sprite.tileImg.tint = 0xff0000;
    } else {
      sprite.tileImg.tint = Colors.TILE_TINT;
    }
  };

  const doAbilityRangeHighlight = (unitToOrder, ability, sprite) => {
    if (!ability || ability.passive) {
      return;
    }

    var piece = sprite.gamePiece;
    var rangeMax = ability.range.max;

    if (ability && BattleCalc.boardDistance(unitToOrder,piece)>=ability.range.min && BattleCalc.boardDistance(unitToOrder,piece)<=rangeMax) {
      // sprite.tileImg.tint = 0xaaaaaa;
      sprite.rangeHighlight.visible = true;
    }
  };

  const onAbilitySelected = () => {
    try {
      var battleState = BattleStore.getAll().battleState;
      if (!battleState) {
        return;
      }

      var unitToOrder = battleState.allPieces[ BattleStore.getAll().unitToOrder ];

      var ability = BattleStore.getAll().selectedAbility;

      // highlight tiles in the ability's range
      for (var prop in _allPieceSprites) {
        _allPieceSprites[prop].rangeHighlight.visible = false;
      }

      if (ability) {
        if (ability.handle==='move') {

          var rangeMax = Math.floor(unitToOrder.ap/ability.apCost);
          var tilesWithinRange = [];

          for (var prop in battleState.allTiles) {
            var tile = battleState.allTiles[prop];

            tile.isTraversable = function() {
              return !this.occupied;
            };

            var dist = BattleCalc.boardDistance(unitToOrder, tile);
            if (dist <= rangeMax) {
              tilesWithinRange.push(tile);
            }
          }

          var paths = [];

          for (var i = 0; i < tilesWithinRange.length; ++i) {
            var tile = tilesWithinRange[i];
            if (BattleCalc.boardDistance(unitToOrder,tile) <= rangeMax) {
              paths.push(_aStarSearch.getPath(battleState.getTileAtCoords(unitToOrder.x,unitToOrder.y), tile, battleState));
            }
          }

          var uidsToHighlight = [];
          for (var k = 0; k < paths.length; ++k) {
            var path = paths[k];
            if (path.length && path.length <= rangeMax+1) {
              uidsToHighlight.push(path[path.length-1].uid);
            }
          }

          for (var prop in _tileSprites) {
            if (uidsToHighlight.indexOf(_tileSprites[prop].gamePiece.uid) > -1) {
              _tileSprites[prop].rangeHighlight.visible = true;
            }
          }

        } else {
          for (var prop in _allPieceSprites) {
            doAbilityRangeHighlight(unitToOrder, ability, _allPieceSprites[prop]);
          }
        }
      }
    } catch (err) {
      logError(err, {
        module: 'GameBoard',
        func: 'onAbilitySelected',
        event,
      });
    }
  };

  const onStatCostsExacted = (data) => {
    try {
      const actor = battleState.allPieces[data.actorId];
      const victim = battleState.allPieces[data.victimId];
      if (actor.uid !== victim.uid) {
        _allPieceSprites[data.actorId]?.puppet?.facePiece(victim);
      }

      _actionRenderer.renderAbility(data);
      updateCollections();
      _unitSprites[data.actorId].updateUnit(actor);

      for (const piece_sprite of Object.values(_allPieceSprites)) {
        piece_sprite.rangeHighlight.visible = false;
      }
    }  catch (err) {
      logError(err, {
        module: 'GameBoard',
        func: 'onStatCostsExacted',
        data,
      });
    }
  };

  const onAbilityExecuted = (data) => {
    _actionRenderer.renderAbilityResult(data);
    // updateCollections();
  };

  const onBattleEvent = (data) => {
    try {
      battleState = BattleStore.getAll().battleState;
      if (!battleState) {
        return;
      }

      updateCollections();

      _actionRenderer.renderEvent(data.event);

      if (data.event.eventHandle === 'death') {
        TweenMax.delayedCall(0.1, ()=> {
          const victimId = data.event.victimId;
          if (!battleState || !battleState.allPieces) {
            return;
          }

          delete battleState.allPieces[ victimId ];
          delete battleState.allUnits[ victimId ];
          delete _allPieceSprites[ victimId ];
          delete _unitSprites[ victimId ];
          updateCollections();
        });
      }
    } catch (err) {
      logError(err, {
        module: 'GameBoard',
        func: 'onBattleEvent',
        data,
      });
    }
  };

  const onAITurn = () => {
    showMedallion(BattleStore.getAll().actingAIUnit);
  };

  const onTurnTimerInit = (data) => {
    showMedallion(data.unitToOrderId);
  };

  const onUnitCondition = (condition) => {
    try {
      const unit_state = battleState.allUnits[ condition.ownerId ];
      const unitSprite = _unitSprites[unit_state.uid];
      unitSprite.updateUnit(unit_state);
      unitSprite.statBar.update(unit_state);

      _actionRenderer.renderCondition(condition);
    } catch (err) {
      logError(err, {
        module: 'GameBoard',
        func: 'onUnitCondition',
        condition,
      });
    }
  };

  // make sure we render any conditions that already exist in the battleState we constructed with
  for (var unitId in battleState.allUnits) {
    for (var conditionId in battleState.allUnits[unitId].conditions) {
      _actionRenderer.renderCondition(battleState.allUnits[unitId].conditions[conditionId]);
    }
  }

  const onUnitConditionExpired = (data) => {
    _actionRenderer.stopRenderingCondition(data);
  };

  const onUnitState = (unit_state) => {
    try {
      const unitSprite = _unitSprites[unit_state.uid];
      unitSprite?.updateUnit(unit_state);
      unitSprite?.statBar?.update(unit_state);
    } catch (err) {
      logError(err, {
        module: 'GameBoard',
        func: 'onUnitState',
        unit_state,
      });
    }
  };

  BattleStore.on(BattleStore.ORDERS_REQUESTED, onOrdersRequested);
  BattleStore.on(BattleStore.RESUMING_TIME, onResumingTime);
  BattleStore.on(BattleStore.ABILITY_SELECTED, onAbilitySelected);
  BattleStore.on(BattleStore.STAT_COSTS_EXACTED, onStatCostsExacted);
  BattleStore.on(BattleStore.ABILITY_EXECUTED, onAbilityExecuted);
  BattleStore.on(BattleStore.BATTLE_EVENT, onBattleEvent);
  BattleStore.on(BattleStore.AI_TURN, onAITurn);
  BattleStore.on(BattleStore.UNIT_CONDITION, onUnitCondition);
  BattleStore.on(BattleStore.UNIT_CONDITION_EXPIRED, onUnitConditionExpired);
  BattleStore.on(BattleStore.GOT_UNIT_STATE, onUnitState);
  BattleStore.on(BattleStore.TURN_TIMER_INIT, onTurnTimerInit);

  this.dispose = function() {
    // remove click/tap handler to every game piece
    for (var prop in _allPieceSprites) {
      var ps = _allPieceSprites[prop];
      ps.click = ps.tap = null;
    }

    cancelAnimationFrame(_updateInterval);

    BattleStore.removeListener(BattleStore.ORDERS_REQUESTED, onOrdersRequested);
    BattleStore.removeListener(BattleStore.RESUMING_TIME, onResumingTime);
    BattleStore.removeListener(BattleStore.ABILITY_SELECTED, onAbilitySelected);
    BattleStore.removeListener(BattleStore.STAT_COSTS_EXACTED, onStatCostsExacted);
    BattleStore.removeListener(BattleStore.ABILITY_EXECUTED, onAbilityExecuted);
    BattleStore.removeListener(BattleStore.BATTLE_EVENT, onBattleEvent);
    BattleStore.removeListener(BattleStore.AI_TURN, onAITurn);
    BattleStore.removeListener(BattleStore.UNIT_CONDITION, onUnitCondition);
    BattleStore.removeListener(BattleStore.UNIT_CONDITION_EXPIRED, onUnitConditionExpired);
    BattleStore.removeListener(BattleStore.GOT_UNIT_STATE, onUnitState);
    BattleStore.removeListener(BattleStore.TURN_TIMER_INIT, onTurnTimerInit);

    for (var uid in _allPieceSprites) {
      _allPieceSprites[uid].dispose();
    }
    _allPieceSprites = null;
    _tileSprites = null;
    _unitSprites = null;

    destroyMedallion();
    hideUnitDetailsPopup();

    _actionRenderer.dispose();
    _actionRenderer = null;

    battleState = null;

    this.removeChildren();
  };

  function destroyMedallion() {
    for (var prop in _unitSprites) {
      _unitSprites[prop].tileImg.alpha = 0.01;
    }
  }
};
GameBoard.prototype = Object.create(PIXI.Container.prototype);
GameBoard.prototype.constructor = GameBoard;
export default GameBoard;
