import * as PIXI from 'pixi.js';
import PortalEffect from './PortalEffect';

export default {
  makePortalEffect: (victimSprite) => {
    var portal = new PortalEffect(2200);
    portal.scale.y = portal.scale.x = 0.7; // 3.5;
    victimSprite.tileImg.addChild(portal);

    var tween = TweenMax.from(portal.scale, 3.5, { x: 0, y: 0, ease: Elastic.easeOut });

    TweenMax.delayedCall(1.85, (portalImg)=>{
      tween = TweenMax.to(portalImg.scale, 0.25, { x: 0, y: 0, ease: Expo.easeIn, onComplete: ()=>{
        if (portalImg.parent) portalImg.parent.removeChild(portalImg);
      } });
    }, [portal]);
  },

  makeCloudPuff: (homeSprite, colorTag=0xffffff) => {
    const duration = 1000 + Math.random() * 1201;
    const image = new PIXI.Sprite();
    image.texture = PIXI.utils.TextureCache['effects/cloud.png'];
    image.anchor.x = image.anchor.y = 0.5;
    image.tint = colorTag;

    image.x = homeSprite.x;
    image.y = homeSprite.y;
    image.alpha = 0;
    homeSprite.parent.addChild(image);

    const _timeline = new TimelineMax({
      onComplete: i => i.parent?.removeChild(i),
      onCompleteParams: [image],
    });

    const fadeInTime = 0.05 + Math.random() * 0.15;
    _timeline.add(TweenMax.to(image, fadeInTime, {
      alpha: 0.3,
      onComplete: (image, remainingDuration) => {
        _timeline.add(
          TweenMax.to(image, remainingDuration, { alpha: 0, ease: Expo.easeIn }),
          _timeline._time,
        );
      },
      onCompleteParams: [image, (duration / 1000 - fadeInTime)],
    }), _timeline._time);

    const xDest = homeSprite.x - 14 + Math.random() * 28;
    const yDest = homeSprite.y - 10 -  Math.random() * 49;


    const rotation_tween = TweenMax.to(image, duration / 1000, {
      x: xDest,
      y: yDest,
      rotation: (-50 + Math.random() * 100) * (Math.PI / 180), ease: Linear.easeNone,
      onUpdate: img => { !img?.parent && rotation_tween.kill() },
      onUpdateParams: [image],
    });
    _timeline.add(
      rotation_tween,
      _timeline._time,
    );

    _timeline.add(TweenMax.from(image.scale, 0.5, { x: 0, y: 0 }), _timeline._time);
  },
};
