import { Dispatcher } from 'flux';

const PaymentsDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'PAYMENTS_DISPATCHER',

  CREATE_PAYMENT_INTENT: 'CREATE_PAYMENT_INTENT',

  handlePaymentsAction(action) {
    this.dispatch({
      source: PaymentsDispatcher.NAME,
      action
    });
  }
});
export default PaymentsDispatcher;
