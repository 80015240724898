<template>
  <Dialog
    :open="true"
    @close="props.onClose(); props.afterClosed();"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90">
      <DialogPanel class="relative border border-white w-[700px] max-h-[90vh] flex flex-col bg-black">
        <canvas
          id="modal_canvas"
          ref="modal_canvas"
          class="absolute z-0 w-[700px] h-full"
        />

        <DialogTitle
          :class="{
            'border-b border-white p-3 font-bold': true,
            'text-yellow-300': state.debrief?.caverns_data,
            'text-green-500': state.is_pvp_victory,
            'text-red-500': !state.is_pvp_victory,
          }"
        >
          {{
            state.debrief?.caverns_data
              ? text('ui.returned_to_surface')
              : state.is_pvp_victory
                ? text('ui.victory')
                : text('ui.defeat')
          }}
        </DialogTitle>

        <div class="w-full h-48" /> <!-- space for squad view -->


        <!-- Inn player state updates -->
        <div 
          v-if="state.debrief?.game_mode === GAME_MODE_pvpAuto"
          class="w-full mt-6 px-6 py-16 text-center text-sm"
        >
          <div
            v-if="state.is_pvp_victory"
          >
            <!-- peanuts stolen -->
            <div class="text-yellow-700">
              {{ text('ui.peanuts_burgled') }}: {{ Tools.formatGameCurrency(state.debrief?.peanuts_stolen) }}
            </div>
            <!-- new tourney rank -->
            <div class="font-bold text-yellow-300">
              {{ text('ui.new_rank') }}: #{{ getNewRankText() }}
            </div>
          </div>
        </div>
         <!-- Arena competition profile updates -->
        <div
          v-if="state.debrief?.game_mode === GAME_MODE_pvpLive"
          class="w-full mt-6 py-16 text-center text-xs"
        >
          <div>
            <span class="font-bold">
              {{ text('ui.rating_change') }}:
            </span>
            <span
              :class="{
                'text-red-500': state.rating_change < 0,
                'text-green-500': state.rating_change > 0,
                'text-white': state.rating_change === 0,
              }">
              {{ `${state.rating_change >= 0 ? '+' : ''}${state.rating_change}` }}
            </span>
          </div>
          <div>
            <span class="font-bold">
              {{ text('ui.new_rating') }}:
            </span>
            <span>{{ state.new_rating }}</span>
          </div>
        </div>

        <!-- space for battle rewards -->
        <div
          v-if="state.debrief?.is_pvp_victory || state.debrief?.caverns_data"
          class="w-full h-24"
        />

        <div class="w-full h-24 px-6 flex justify-end items-center z-10 pointer-events-none">
          <DTButton
            type="success"
            class="py-1 px-5"
            @click="props.onClose(); props.afterClosed();"
          >
            {{ text('ui.OK') }}
          </DTButton>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { nextTick, onBeforeUnmount, reactive, ref, watch } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';
import Game from 'dt-common/constants/Game';
import getNumHeroesInSubmode from 'dt-common/isomorphic-helpers/getNumHeroesInSubmode';
import {
  BattleStore,
  GameStateStore,
  HeroBuildStore,
  InnStore,
  PlayerStore,
  UIStore
} from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';
import { EngagedSquadView } from '~/view/components/common/canvas';
import { DTButton } from '~/view/components/common/DOM';
import BattleRewards from '~/view/game-screens/battle-debrief/canvas/BattleRewards';

const { GAME_MODES: { GAME_MODE_pvpAuto, GAME_MODE_pvpLive } } = Game;

let _battle_rewards_view;
let _engaged_squad_view;
let _modal_pixi_app;

const props = defineProps({
  afterClosed: {
    type: Function,
    default: () => {},
  },
  attack: {
    type: Boolean,
    default: false,
  },
  completed_caverns_run_data: {
    type: Object,
    required: false,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

const state = reactive({ debrief: null });

function getNewRankText() {
  const player_inn_state = state.debrief?.playerStateUpdates[PlayerStore.getAll().loggedInPlayerId]?.player_inn_state;
  return player_inn_state
    ? player_inn_state[state.debrief?.game_submode]?.current_rank
    : '--';
}

const modal_canvas = ref(null);

watch(modal_canvas, val => {
  if (val && !_modal_pixi_app) {
    const { loggedInPlayerId } = PlayerStore.getAll();

    state.debrief = props.completed_caverns_run_data
      ? { caverns_data: props.completed_caverns_run_data }
      : BattleStore.getAll().debrief;
    state.is_pvp_victory = (
      state.debrief.game_mode === GAME_MODE_pvpAuto
      && state.debrief?.white_is_winner
    )
    || (
      state.debrief.game_mode === GAME_MODE_pvpLive
      && state.debrief?.winnerId === loggedInPlayerId
    );

    if (state.debrief.game_mode === GAME_MODE_pvpLive) {

      state.new_rating = state.debrief.playerStateUpdates[
        loggedInPlayerId
      ].competition_profile[
        state.debrief.game_submode
      ].elo_rating;

      state.rating_change = state.new_rating - state.debrief.beforeBattle[loggedInPlayerId].elo;
    }
    
    nextTick(() => {
      _modal_pixi_app = CanvasTools.makePixiRenderer({
        canvas_id: 'modal_canvas',
        canvas_ele: val,
      });
      makeBattleRewardsView();
      makeEngagedSquadView();
    });
  }
});

onBeforeUnmount(() => {
  destroyCanvas();
});

function makeEngagedSquadView({ do_transition_in = true } = {}) {
  destroyEngagedSquadView();

  const { loadouts } = GameStateStore.getAll().gameState;
  const { attack_loadout } = InnStore.getAll();
  const { current_game_mode, current_game_submode } = UIStore.getAll();

  const num_heroes = getNumHeroesInSubmode(current_game_submode);
  const puppet_scale = num_heroes < 5
    ? 1.75
    : num_heroes > 5
      ? 1.00
      : 1.35;

  _engaged_squad_view = new EngagedSquadView({
    hero_builds: HeroBuildStore.getAll().hero_builds,
    loadout_squad_list: current_game_mode === Game.GAME_MODES.GAME_MODE_pvpAuto && attack_loadout?.length
      ? attack_loadout.filter(hero => !!hero.engagement)
      : loadouts[current_game_mode][current_game_submode].filter(hero => !!hero.engagement),
    roster_heroes: GameStateStore.getAll().gameState.hero_roster,
    puppet_scale,
    do_transition_in,
    before_battle: state.debrief?.before_battle || state.debrief.caverns_data?.before_battle,
    getHorizontalPuppetSpacing: ({ puppet_index }) => {
      const width_coefficient = window.innerWidth * 0.087;
      switch (num_heroes) {
        case 5: return width_coefficient * -0.32 + puppet_index * puppet_scale * 100 + 52;
        case 7: return width_coefficient * -0.25 + puppet_index *  puppet_scale * 100 + 42;
        default:  {
          const PUPPET_HORIZ_SPACING = Math.max(
            172,
            width_coefficient / (puppet_scale * 2)
          );
          return  Math.round(puppet_index * PUPPET_HORIZ_SPACING);
        }
      }
    },
    perma_vamp: state.is_pvp_victory || state.debrief.caverns_data
      ? null
      : 'stunned',
  });
  const { width } = modal_canvas.value.getBoundingClientRect();
  _engaged_squad_view.x = Math.round(width / 2 - _engaged_squad_view.width /2) + puppet_scale * 37;
  _engaged_squad_view.y = 142;
  _modal_pixi_app.stage.addChild(_engaged_squad_view);

  _engaged_squad_view.interactive = _engaged_squad_view.interactiveChildren = false;
}

function makeBattleRewardsView() {
  destroyBattleRewardsView();

  _battle_rewards_view = new BattleRewards(state.debrief);
  const { height, width } = modal_canvas.value.getBoundingClientRect();
  _battle_rewards_view.width = Math.min(_battle_rewards_view.width, width * 0.7);
  _battle_rewards_view.scale.y = _battle_rewards_view.scale.x;
  _battle_rewards_view.x = Math.round(width / 2 - _battle_rewards_view.width / 2);
  _battle_rewards_view.y = Math.round(height - 60 - _battle_rewards_view.height / 2);
  _modal_pixi_app.stage.addChild(_battle_rewards_view);
}

function destroyCanvas() {
  destroyEngagedSquadView();
  destroyBattleRewardsView();
  _modal_pixi_app?.destroy();
}

function destroyEngagedSquadView() {
  _engaged_squad_view?.dispose();
  _engaged_squad_view = null;
}

function destroyBattleRewardsView() {
  _battle_rewards_view?.dispose();
  _battle_rewards_view = null;
}
</script>
