export default {
	base: "Base",
	from_equipment: "From Equipment",
  from_upgrades: "From Upgrades",
  next_upgrade_cost: 'Next Upgrade Cost',

	armorClass: {
		name: "Armor Class",
		short: "AC",
		blurb: "The bulk of this unit's armor. This value will be subtracted from external damage sources."
	},

	dexterity: {
		name: "Dexterity",
		short: "De",
		blurb: "Dexterity measures the grace and precision of the unit's movement. It strongly affects the effectiveness of missile attacks, melee attacks, Speed, and Chance-to-Dodge."
	},

	dodgeChance: {
		name: "Dodge Chance",
		short: "DC",
		blurb: "The chance that this unit will dodge incoming physical attacks.",
		calc: "Pe/4 + De/7 + Ma/16 + Illusion:Blur"
	},

	physicalResil: {
		name: "Physical Resilience",
		short: "PR",
		blurb: "The chance that this unit will resist effects like Slow and Stun.",
		calc: "St/5 + De/5 + Movement:Freedom*3 + Illusion:Blur"
	},

	mentalResil: {
		name: "Mental Resilience",
		short: "MR",
		blurb: "The chance that this unit will resist effects like Fear and Rage.",
		calc: "Le/5 + Wi/5 + Spirit:Iron Will*3 + Illusion:Blur"
	},

	learning: {
		name: "Learning",
		short: "Le",
		blurb: "Learning measures a unit's ability to mentally grow from experience. It is associated with skill in many magical abilities, but a physical fighter will also benefit from knowing where to aim his blows!"
	},

	magicalGravity: {
		name: "Magical Gravity",
		short: "MG",
		blurb: "The rate, per second, at which this unit recharges Magic Power.",
		calc: "Ma/5 + Wi/14 + Pe/23"
	},

	mana: {
		name: "Mana",
		short: "Ma",
		blurb: "Mana indicates an individual's attunement to unseen physical and non-physical energies at work in the universe (not necessarily a consciousness thereof).  It affects the potency of many spells, and is the primary factor in Maximum Magic Power as well as Magical Gravity. Indeed, it affects a great many calculations in small ways, and is often mistaken for Luck."
	},

	meleeDamage: {
		name: "Melee Damage",
		short: "MD",
		blurb: "The amount of damage dealt with regular melee attacks (all heroes can perform melee attacks regardless of their ability and AI settings).",
		calc: "St*3 + De*2 + Le/2 + Wi/3"
	},

	perception: {
		name: "Perception",
		short: "Pe",
		blurb: "A unit's awareness of its physical surroundings. High Perception is needed to achieve great skill in archery, and it is the primary factor in Chance-to-Dodge."
	},

	regeneration: {
		name: "Regeneration",
		short: "Re",
		blurb: "The rate, per second, at which this unit recharges Hit Points.",
		calc: "St/8 + Wi/10 + To/15 + Ma/20"
	},

	speed: {
		name: "Speed",
		short: "Sp",
		blurb: "The rate, per second, at which this unit recharges Action Points.",
		calc: "10 + De/8 + St/12 + Wi/20 + Ma/50 + Movement:Quickness/3"
	},

	strength: {
		name: "Strength",
		short: "St",
		blurb: "Strength measures the mass of the unit's body, and the force with which can move.  It is especially useful in melee abilities, and strongly influences both Regeneration and Speed."
	},

	toughness: {
		name: "Toughness",
		short: "To",
		blurb: "Toughness measures one's resistance to abuse.  Most importantly, raising this attribute will grant the unit a lot of Hit Points."
	},

	willpower: {
		name: "Willpower",
		short: "Wi",
		blurb: "A strong-willed individual has personal drive and will be very good at influencing others. This attribute is checked in some magical skills - especially healing - and grants a lot of Hit Points. Finally, it factors heavily into the regeneration of all three stats: Hit Points, Action Points, and Magic Power."
	},

	max_hp: {
		name: "Max Hit Points",
		calc: "To*25 + St*15 + Wi*10 + Ma*5"
	},

	max_ap: {
		name: "Max Action Points",
		calc: "100 + Movement:Preparation*2"
	},

	max_mp: {
		name: "Max Magic Power",
		calc: "Ma*10 + Le*7 + Wi*4 + To*2"
	}
};
