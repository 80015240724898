import * as PIXI from 'pixi.js';
import Matter from 'matter-js'
import Balance from 'dt-common/constants/Balance';
import { Colors } from '~/constants';
import { ApplicationStore, InnStore } from '~/flux/stores';

const Body = Matter.Body;
const Engine = Matter.Engine;
const Runner = Matter.Runner;
const Composite = Matter.Composite;
const Bodies = Matter.Bodies;

const MAX_NUM_PEANUTS = 300;
const RADIANS_TO_DEGREES = (180 / Math.PI);

const engine = Engine.create();
const world = engine.world;
const runner = Runner.create();

const PeanutsView = function({ width, height, peanuts_canvas_ele }) {
  PIXI.Container.call(this);

  let _peanut_bodies = []
  let _peanut_sprites = {};
  let _sprites_frame_id;
  
  this.dispose = () => {
    cancelAnimationFrame(_sprites_frame_id);
    clearInterval(_drops_interval);

    Runner.stop(runner);
    Engine.clear(engine);
    Composite.clear(world, false, true);
    Matter.World.clear(world);

    this.removeChildren();
    _peanut_sprites = null;

    _peanut_bodies = [];
  };

  // create engine & runner
  Runner.run(runner, engine);

  // floor & walls
  const plank_length = width * 0.45;
  const PLANK_THICKNESS = 17;
  const planks = [
    Bodies.rectangle(width / 2, height, width, PLANK_THICKNESS, { isStatic: true, angle: 0 }), // floor
    Bodies.rectangle(PLANK_THICKNESS / 4, height / 2 , height, PLANK_THICKNESS, { isStatic: true, angle: Math.PI * 0.5 }), // left wall
    Bodies.rectangle(width - PLANK_THICKNESS / 4, height / 2, height, PLANK_THICKNESS, { isStatic: true, angle: Math.PI * 0.5 }), // right wall
  ];
  Composite.add(world, planks);

  // inclined planes
  const inclines = [
    Bodies.rectangle(plank_length * 0.60, height * 0.23, plank_length * 1.2, PLANK_THICKNESS, { isStatic: true, angle: Math.PI * 0.06 }),
    Bodies.rectangle(width - plank_length * 0.56, height * 0.44, plank_length * 1.2, PLANK_THICKNESS, { isStatic: true, angle: -Math.PI * 0.08 }),
    Bodies.rectangle(plank_length * 0.51, height * 0.59, plank_length, PLANK_THICKNESS, { isStatic: true, angle: Math.PI * 0.09 })
  ];
  Composite.add(world, inclines);
  for (const incline of inclines) {
    // render the incline (just as a white bar?)
    const plank_gfx = new PIXI.Graphics();
    plank_gfx.beginFill(0xffffff, 0.4);
    plank_gfx.drawPolygon(incline.vertices);
    plank_gfx.endFill();
    this.addChild(plank_gfx);
  }

  // physics body factory
  const makePeanutBody = ({ top_only } = {}) => {
    const size = 15 + Math.random() * 4;
    const body = Bodies.rectangle(
      size + Math.random() * (width - size * 2),
      top_only
        ? DT_CANVAS_GLOBALS.spacing * -10 // drop new peanuts from above viewport
        : height - size - Math.random() * height, // initial random placement
      size / 2,
      size,
      {
        chamfer: { radius: size / 5.5 },
        density: 0.5,
        friction: 0.005,
        frictionStatic: 1,
        restitution: 0.35,
        sleepThreshold: 0.01,
        slop: 0.001,
      }
    );
    body.dt_size = size;
    Composite.add(world, body);
    _peanut_bodies.push(body);

    Body.applyForce(body, body.position, { x: -0.25 + Math.random() * 0.5, y: 0.5 + Math.random() * 0.5 });
    Body.setAngularVelocity(body, -0.5 + Math.random() * 1);
  
    return body;
  };
  
  let uid = 0;
  const makePeanutSprite = (peanut_body) => {
    const peanut_sprite = new PIXI.Sprite();
    peanut_sprite.texture = PIXI.utils.TextureCache['peanut_001.png'];
    peanut_sprite.tint = Colors.peanut;
    peanut_sprite.width = peanut_body.dt_size * 0.6;
    peanut_sprite.scale.y = peanut_sprite.scale.x;
    peanut_sprite.anchor = { x: 0.5, y: 0.5 };
    peanut_sprite.x = peanut_body.position.x;
    peanut_sprite.y = peanut_body.position.y;
    peanut_body.dt_uid = ++uid;
    _peanut_sprites[uid] = peanut_sprite;
    this.addChild(peanut_sprite);
  }

  // initialize bodies & sprites for current peanuts count
  const { current_submode_state } =  InnStore.getAll();
  const { current_rank, last_num_peanuts, last_rank_change_at, tourney_entered_at } = current_submode_state;
  const now = Date.now();
  const ms_elapsed_since_last_rank_change = now - last_rank_change_at;
  const num_peanuts = Math.floor(
    last_num_peanuts + ms_elapsed_since_last_rank_change * Balance.getInnPeanutsPerMSByRank(current_rank),
  );
  const num_bodies = Math.min(MAX_NUM_PEANUTS -100, Math.max(10, num_peanuts / 5000)); // Math.min(500, num_peanuts);
  for (let i = 0; i < num_bodies; ++i) {
    const body = makePeanutBody();
    makePeanutSprite(body);
  }

  const updateSprites = () => {
    for (const body of _peanut_bodies) {
      const peanut_sprite = _peanut_sprites[body.dt_uid];
      peanut_sprite.x = body.position.x;
      peanut_sprite.y = body.position.y;
      peanut_sprite.angle = body.angle * RADIANS_TO_DEGREES;

      const { x, y } = Body.getVelocity(body);
    }
    _sprites_frame_id = requestAnimationFrame(updateSprites);
  }
  updateSprites();

  // handle new peanut drops over time
  let _delta;
  let _last_new_peanut_drop_at = Date.now();
  const PPMS = Balance.getInnPeanutsPerMSByRank(current_submode_state.current_rank);
  let _num_new_peanuts;
  const _drops_interval = setInterval(() => {
    if (document.hidden) {
      return;
    }

    const now = Date.now();
    _delta = now - _last_new_peanut_drop_at;
    _num_new_peanuts = _delta * PPMS;

    if ((_num_new_peanuts > 100 || _delta >= 3000) && _peanut_bodies.length < MAX_NUM_PEANUTS) {
      const body = makePeanutBody({ top_only: true });
      makePeanutSprite(body);
      _last_new_peanut_drop_at = now;
    }

  }, 400);
};
PeanutsView.prototype = Object.create(PIXI.Container.prototype);
PeanutsView.prototype.constructor = PeanutsView;
export default PeanutsView;
