<!-- eslint-disable-line vue/multi-word-component-names -->
<template>
  <div
    id="caverns_battle_log"
    ref="caverns_battle_log"
    class="w-full max-h-[100%] pt-6 pl-3 overflow-y-auto pointer-events-auto text-xs text-slate-400"
    @scroll="onScroll"

  >
    <BattleLogEntry
      v-for="entry, i in state.log_entries"
      :entry_data="entry"
      :key="i"
      class="w-full text-center"
    />
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, onUpdated, reactive, ref } from 'vue';
import { CavernsStore } from '~/flux/stores';
import {
  getBattleEventLogEntry,
  getBattleEventLogEntryForAbility,
  getBattleEventLogEntryForCondition,
  getCavernsNewTurnMessage,
 } from '~/text';
import BattleLogEntry from './BattleLogEntry.vue';

let _is_scrolling = false;
let _last_ai_unit_id = null;

const state = reactive({
  active_caverns_run: false,
  log_entries: [],
});

const caverns_battle_log = ref(null);

onMounted(() => {
  CavernsStore.on(CavernsStore.BATTLE_INITIALIZED, initialCinchScroll);
  CavernsStore.on(CavernsStore.AI_TURN, onAITurn);
  CavernsStore.on( CavernsStore.BATTLE_EVENT, onBattleEvent );
  CavernsStore.on( CavernsStore.UNIT_CONDITION, onUnitCondition );
  CavernsStore.on( CavernsStore.ABILITY_EXECUTED, onAbilityExecuted );
});

onBeforeUnmount(() => {
  CavernsStore.removeListener(CavernsStore.BATTLE_INITIALIZED, initialCinchScroll);
  CavernsStore.removeListener(CavernsStore.AI_TURN, onAITurn);
  CavernsStore.removeListener( CavernsStore.BATTLE_EVENT, onBattleEvent );
  CavernsStore.removeListener( CavernsStore.UNIT_CONDITION, onUnitCondition );
  CavernsStore.removeListener( CavernsStore.ABILITY_EXECUTED, onAbilityExecuted );
});

onUpdated(cinchScroll);

function addEntry(newEntry) {
  state.log_entries = [...state.log_entries, newEntry];
  if (state.log_entries.length > 1000) {
    state.log_entries = state.log_entries.slice(1);
  }
};

function cinchScroll() {
  if (!_is_scrolling) {
    caverns_battle_log.value.scrollTop = caverns_battle_log.value.scrollHeight;
  }
}

function initialCinchScroll() {
  setTimeout(cinchScroll, 500);
}

function onAbilityExecuted(data) {
  addEntry({
    text: getBattleEventLogEntryForAbility(data, CavernsStore.getAll().battleState.allPieces)
  });
};

function onAITurn(data) {
  if (data.aiUnitId !== _last_ai_unit_id) {
    const ai_unit = data.allUnits[data.aiUnitId]
      addEntry({
        ...getCavernsNewTurnMessage({ ai_unit }),
        new_turn: true,
      });
    }
    _last_ai_unit_id = data.aiUnitId;
  }

function onBattleEvent(data) {
  data.allPieces = CavernsStore.getAll().battleState.allPieces;
  const entry = getBattleEventLogEntry(data);
  if (entry) {
    TweenMax.delayedCall(
      0.16,
      addEntry,
      [entry]
    );
  }
};

function onScroll(event) {
  _is_scrolling = true;
  setTimeout(() => {
    if (!caverns_battle_log.value) {
      return;
    }
    const { clientHeight, scrollHeight, scrollTop } = caverns_battle_log.value;
    if (Math.abs(scrollTop - scrollHeight) < clientHeight + 300) {
      _is_scrolling = false;
    }
  }, 100);
}

function onUnitCondition(data) {
  TweenMax.delayedCall(
    0.16,
    addEntry,
    [{
      text: getBattleEventLogEntryForCondition(data, CavernsStore.getAll().battleState.allPieces)
    }]
  );
};
</script>
