<template>
  <component
    :is="modals[state.current_modal] || 'div'"
    id="generic_modal_component"
    :on-close="onModalClosed"
    v-bind="state.modal_props"
  />
</template>

<script setup>
import { reactive } from 'vue';
import UIDispatcher from '~/flux/dispatchers/UIDispatcher';
import Tools from '~/Tools';
import AuthModal from './AuthModal.vue';
import BattleDebriefModal from './BattleDebriefModal.vue';
import BattleLogModal from './BattleLogModal.vue';
import ChatTextColorPicker from './ChatTextColorPicker.vue';
import ConfirmDecisionModal from './ConfirmDecisionModal.vue';
import ContextualInfoModal from './ContextualInfoModal/index.vue';
import EnterHeroBuildNameModal from './EnterHeroBuildNameModal.vue';
import EnterTextModal from './EnterTextModal.vue';
import FauxModalWithCanvas from './FauxModalWithCanvas.vue';
import HeroInventoryRemovalWarningModal from './HeroInventoryRemovalWarningModal.vue';
import HeroUnlockModal from './HeroUnlockModal.vue';
import InnLogsModal from './InnLogsModal.vue';
import LeaderboardsModal from './LeaderboardsModal/index.vue';
import MiracleDyeModal from './MiracleDyeModal.vue';
import NameChangeModal from './NameChangeModal.vue';
import PixieDustModal from './PixieDustModal.vue';
import ResetAbilitiesModal from './ResetAbilitiesModal.vue';
import ResetAttributesModal from './ResetAttributesModal.vue';
import SettingsModal from './SettingsModal.vue';
import SocialModal from './SocialModal.vue';
import SwapEngagementModal from './SwapEngagementModal.vue';
import WelcomeModal from './WelcomeModal.vue';

const modals = {
  'AuthModal': AuthModal,
  'BattleDebriefModal': BattleDebriefModal,
  'BattleLogModal': BattleLogModal,
  'ChatTextColorPicker': ChatTextColorPicker,
  'ConfirmDecisionModal': ConfirmDecisionModal,
  'ContextualInfoModal': ContextualInfoModal,
  'EnterHeroBuildNameModal': EnterHeroBuildNameModal,
  'EnterTextModal': EnterTextModal,
  'FauxModalWithCanvas': FauxModalWithCanvas,
  'HeroInventoryRemovalWarningModal': HeroInventoryRemovalWarningModal,
  'HeroUnlockModal': HeroUnlockModal,
  'InnLogsModal': InnLogsModal,
  'LeaderboardsModal': LeaderboardsModal,
  'MiracleDyeModal': MiracleDyeModal,
  'NameChangeModal': NameChangeModal,
  'PixieDustModal': PixieDustModal,
  'ResetAbilitiesModal': ResetAbilitiesModal,
  'ResetAttributesModal': ResetAttributesModal,
  'SettingsModal': SettingsModal,
  'SocialModal': SocialModal,
  'SwapEngagementModal': SwapEngagementModal,
  'WelcomeModal': WelcomeModal,
};

const state = reactive({
  current_modal: 'WelcomeModal',
  allow_modal_close: false,
});

UIDispatcher.register(Tools.registerDispatchHandlers({
  [UIDispatcher.SHOW_MODAL]: onShowModal,
}));

function onShowModal(action) {
  state.current_modal = action.modal_key;
  state.modal_props = action.modal_props;

  // hack. headlessui <Dialog> (https://headlessui.com/vue/dialog) for some ungodly reason fires @close on mounted
  state.allow_modal_close = false;
  setTimeout(() => {
    state.allow_modal_close = true;
  }, 500);
}

function onModalClosed() {
  if (state.allow_modal_close) {
    state.current_modal = null;
  }
}
</script>
