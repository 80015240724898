import { EventEmitter } from 'events';
import Colors from '~/constants/Colors';
import {
  BattleDebriefDispatcher,
  InnDispatcher,
  PlayerDispatcher,
} from '~/flux/dispatchers';
import text from '~/text';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';

let playerId;
let _socket;

// the stuff we serve:
let attack_loadout = null;
let current_submode_state = null;
let current_tourney_instance = null;
let inn_logs = {};
let opponent_to_attack = null;
let opponents = [];
let player_inn_state = null;

const InnStore = Object.assign({}, EventEmitter.prototype, {
  ATTACK_PREP_STARTED: 'ATTACK_PREP_STARTED',
  ATTACK_SQUAD_UPDATED: 'ATTACK_SQUAD_UPDATED',
  GOT_INN_OPPONENTS: 'GOT_INN_OPPONENTS',
  GOT_INN_LOGS: 'GOT_INN_LOGS',
  GOT_PLAYER_INN_SUBMODE_STATE: 'GOT_PLAYER_INN_SUBMODE_STATE',
  PASSIVE_TOURNEY_RESOLVED: 'PASSIVE_TOURNEY_RESOLVED',

  getAll() {
    return {
      attack_loadout,
      current_submode_state,
      current_tourney_instance,
      inn_logs,
      opponent_to_attack,
      opponents,
    };
  },
});
export default InnStore;

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  _socket = socket;
  if (!_socket.has_InnStore_listeners) {
    _socket.on('inn_logs', onInnLogs);
    _socket.on('inn_tourney_instance', onPlayerInnSubmodeState);
    _socket.on('inn_tourney_passive_rank_up', onPassiveRankUp);
    _socket.on('inn_passive_tourney_resolved', onPassiveTourneyResolved);
    _socket.on('random_inn_opponents', onRandomInnOpponents);

    _socket.has_InnStore_listeners = true;
  }
}

BattleDebriefDispatcher.register(registerDispatchHandlers({
  [BattleDebriefDispatcher.CLAIM_ALL_REWARDS_INN]: claimAllRewards_inn,
  [BattleDebriefDispatcher.CLAIM_GOLD_REWARD_INN]: claimGoldReward_inn,
  [BattleDebriefDispatcher.CLAIM_ITEM_REWARD_INN]: claimItemReward_inn,
  [BattleDebriefDispatcher.CLAIM_PD_REWARD_INN]: claimPixieDustReward_inn,
  [BattleDebriefDispatcher.DELETE_INN_LOG_ENTRY]: deleteInnLogEntry,
}));
PlayerDispatcher.register(registerDispatchHandlers({
  [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
}));
InnDispatcher.register(registerDispatchHandlers({
  [InnDispatcher.FETCH_RANDOM_INN_OPPONENTS]: fetchRandomInnOpponents,
  [InnDispatcher.FETCH_PLAYER_INN_SUBMODE_STATE]: fetchPlayerInnSubmodeState,
  [InnDispatcher.START_ATTACK_BATTLE]: startAttackBattle,
  [InnDispatcher.START_ATTACK_PREP]: startAttackPrep,
  [InnDispatcher.SWAP_ATTACK_HERO]: swapAttackHero,
}));

function claimAllRewards_inn(action) {
  _socket.emit('claim_all_rewards_inn', {
    playerId,
    inn_log_id: action.inn_log_id,
  });
}

function claimItemReward_inn(action) {
  _socket.emit('claim_item_rewards_inn', {
    playerId,
    inn_log_id: action.inn_log_id,
    itemIds: [action.itemId],
  });
}

function claimGoldReward_inn(action) {
  _socket.emit('claim_gold_reward_inn', {
    playerId,
    inn_log_id: action.inn_log_id,
  });
}

function claimPixieDustReward_inn(action) {
  _socket.emit('claim_pixie_dust_reward_inn', {
    playerId,
    inn_log_id: action.inn_log_id,
  });
}

function deleteInnLogEntry(action) {
  _socket.emit('delete_inn_log_entry', {
    playerId,
    log_id: action.log_id,
  });
}

function onPlayerLoggedIn({ player }) {
  playerId = player._id;
  inn_logs = player.gameState.inn_logs;
}

function fetchRandomInnOpponents({ game_submode, num_opponents }) {
  _socket.emit('get_random_inn_opponents', {
    playerId,
    game_submode,
    num_opponents,
  });
}

function fetchPlayerInnSubmodeState({ game_submode }) {
  _socket.emit('get_player_inn_submode_state', {
    playerId,
    game_submode,
  });
}

function onInnLogs(data) {
  inn_logs = data.inn_logs;
  InnStore.emit(InnStore.GOT_INN_LOGS, inn_logs);
}

function onPassiveRankUp(data) {
  const submode_string = text(`ui.game_submodes.${data.game_submode}`);
  $addMessageLogMessage(`Inn tournament rank up! New ${submode_string} rank: ${data.new_rank}`, Colors.BRIGHT_YELLOW);
}

function onPassiveTourneyResolved(data) {
  const { game_submode, new_tourney_change_event_log, this_submode_state, new_tourney_instance } = data;
  inn_logs = data.inn_logs;
  InnStore.emit(InnStore.GOT_INN_LOGS, inn_logs);

  const { new_rank, new_tier } = new_tourney_change_event_log
  if (current_submode_state === new_tourney_instance.submode_state) {
    current_tourney_instance = new_tourney_instance;
  }
  $addMessageLogMessage(`${text(`ui.game_submodes.${game_submode}`)} Inn tournament completed!`, Colors.GREEN);
  const { current_tier } = this_submode_state;
  if (new_tier >= current_tier) {
    $addMessageLogMessage('Check for rewards!\n\n', Colors.BRIGHT_YELLOW);
    if (new_tier > current_tier) {
      $addMessageLogMessage(`You've achived Inn ${text(`ui.inn_tier_titles.${new_tier}`)}!`, Colors.BRIGHT_YELLOW);
    }
  } else {
    $addMessageLogMessage(`You've been demoted to Inn ${text(`ui.inn_tier_titles.${new_tier}`)}.`, Colors.RED);
  }
  InnStore.emit(InnStore.PASSIVE_TOURNEY_RESOLVED, data);
}

function onPlayerInnSubmodeState({ player_inn_submode_state, tourney_instance }) {
  current_submode_state = player_inn_submode_state;
  current_tourney_instance = tourney_instance;
  InnStore.emit(InnStore.GOT_PLAYER_INN_SUBMODE_STATE, { player_inn_submode_state, tourney_instance });
}

function onRandomInnOpponents({ game_submode, inn_opponents }) {
  opponents = inn_opponents;
  InnStore.emit(InnStore.GOT_INN_OPPONENTS, { game_submode, inn_opponents });
}

function startAttackBattle({ game_submode }) {
  _socket.emit('start_inn_attack_battle', {
    attack_loadout,
    game_submode,
    opponentId: opponent_to_attack._id,
    playerId,
  });
}

function startAttackPrep(action) {
  attack_loadout = JSON.parse(JSON.stringify(action.attack_loadout));
  opponent_to_attack = action.opponent;
  InnStore.emit(InnStore.ATTACK_PREP_STARTED, { attack_loadout, opponent_to_attack });
}

function swapAttackHero({
  hero_handle_in,
  hero_build_id_to_swap_in,
  hero_build_id_to_swap_out,
}) {
  const in_louadout_member_obj = attack_loadout.find(({ handle }) => handle === hero_handle_in);
  const out_loadout_member_obj = attack_loadout.find(({ hero_build_id }) => hero_build_id === hero_build_id_to_swap_out);
  const target_slot_index = out_loadout_member_obj.engagement.slot;

  // aassign the hero build ID
  in_louadout_member_obj.hero_build_id = hero_build_id_to_swap_in;
  // update engagement flags & save updated loadout
  const in_loadout_member_slot_buf = in_louadout_member_obj.engagement?.slot;
  in_louadout_member_obj.engagement = { slot: target_slot_index };
  if (out_loadout_member_obj.handle !== in_louadout_member_obj.handle) {
    const must_swap = (
      typeof in_loadout_member_slot_buf === 'number'
      && typeof out_loadout_member_obj.engagement?.slot === 'number'
    );
    out_loadout_member_obj.engagement = must_swap
      ? { slot: in_loadout_member_slot_buf }
      : null;
  }

  InnStore.emit(InnStore.ATTACK_SQUAD_UPDATED);
}

