import * as PIXI from 'pixi.js';
import Game from 'dt-common/constants/Game';
import Colors from '~/constants/Colors';
import CanvasTools from '~/view/CanvasTools';
import Screens from '~/constants/Screens';
import UIActions from '~/flux/actions/UIActions';
import CurrencyStore from '~/flux/stores/CurrencyStore';
import GameStateStore from '~/flux/stores/GameStateStore';
import UIStore from '~/flux/stores/UIStore';
import text from '~/text';
import MessageLog from '~/view/components/MessageLog_canvas';

const { GAME_MODES } = Game;

const NavMenu = function(myWidth) {
  PIXI.Container.call(this);

  this.width = myWidth;
  var myHeight = this.height = window.innerHeight * 0.26;
  const BTN_WIDTH = myWidth / 9;

  CanvasTools.addBackFill(this, Colors.ALMOST_BLACK);

  CanvasTools.addBorder(
    this,
    'menu_top_border.png',
    'window_border_vert.png',
    null,
    'window_border_vert.png',
    0,
  );

  const onNavBtnSelected = (event) => {
    const { screen_id } = event.target;

    if (screen_id === Screens.ARENA_LOBBY && GameStateStore.getAll().num_unlocked_heroes === 0) {
      MessageLog.addMessage(text('ui.unlock_a_hero_first'), Colors.RED);
      return;
    }

    UIActions.uiNav({ screen_id });
  };

  const makeBtn = (texPath, xPosIndex, { screen_id }, labelString, highlightColor) => {
    const btn = new PIXI.Sprite();
    btn.texture = PIXI.utils.TextureCache[texPath];
    btn.highlightColor = highlightColor;

    var label = btn.label = new PIXI.Sprite();
    label.texture = PIXI.utils.TextureCache['footer/nav_menu/menu_banner.png'];
    var labelText = btn.label.labelText = new PIXI.Text(labelString, {
      fontFamily: 'Courier New',
      fontStyle: 'bold',
      fontSize: '52px',
      fill: 0x000000,
      dropShadow: true,
      dropShadoowColor: 0x454545,
      dropShadowDistance: 4
    });

    labelText.x = Math.round(label.width / 2 - labelText.width / 2);
    labelText.y = Math.round(label.height * 0.61 - labelText.height / 2);
    label.addChild(labelText);

    label.width = btn.width;
    label.scale.y = label.scale.x;
    label.x = Math.round(btn.width/2 - label.width/2);
    label.y = Math.round(btn.height * (screen_id === Screens.SHOP ? 1.24 : 1.07));
    btn.addChild(label);

    btn.interactive = btn.buttonMode = true;
    btn.tap = btn.click = onNavBtnSelected;
    btn.width = BTN_WIDTH;
    btn.height = Math.min(myHeight * 0.60, btn.height * btn.scale.x);
    btn.scale.x = btn.scale.y;
    btn.x = Math.round(myWidth * (xPosIndex/6) - btn.width/2);
    btn.y = (xPosIndex - 0.5) % 2 === 1
      ? Math.round(myHeight * 0.4 - btn.height / 2)
      : Math.round(myHeight * 0.55 - btn.height / 2);
    this.addChild(btn);

    btn.mouseover = () => {
      btn.tint_buf = btn.tint;
      btn.label.tint_buf = btn.label.tint;
      btn.tint = btn.label.tint = Colors.RED;
    }
    btn.mouseout = () => {
      btn.tint = btn.tint_buf;
      btn.label.tint = btn.label.tint_buf;
    }

    btn.screen_id = screen_id;
    return btn;
  };

  let _heroBuildsBtn;
  let _pvpAutoBtn;
  let _pvpLiveBtn;
  let _cavernsBtn;
  let _shopBtn;
  let _altarBtn;

  const destroyButtons = () => {
    if (_heroBuildsBtn) {
      this.removeChild(_heroBuildsBtn);
      _heroBuildsBtn.tap = _heroBuildsBtn.click = null;
    }
    if (_pvpAutoBtn) {
      this.removeChild(_pvpAutoBtn);
      _pvpAutoBtn.tap = _pvpAutoBtn.click = null;
    }
    if (_pvpLiveBtn) {
      this.removeChild(_pvpLiveBtn);
      _pvpLiveBtn.tap = _pvpLiveBtn.click = null;
    }
    if (_cavernsBtn) {
      this.removeChild(_cavernsBtn);
      _cavernsBtn.tap = _cavernsBtn.click = null;
    }
    if (_altarBtn) {
      this.removeChild(_altarBtn);
      _altarBtn.tap = _altarBtn.click = null;
    }
    _heroBuildsBtn = null;
    _pvpAutoBtn = null;
    _pvpLiveBtn = null;
    _cavernsBtn = null;
    _shopBtn = null;
    _altarBtn = null;
  };

  const makeButtons = () => {
    destroyButtons();
    
    _heroBuildsBtn = makeBtn('footer/nav_menu/caverns_icon.png', 0.5, { screen_id: Screens.HERO_BUILDS }, text('ui.HERO_BUILDS'), 0x6b5516);
    _pvpAutoBtn = makeBtn('footer/nav_menu/armory_icon.png', 1.5, { screen_id: Screens.INN }, text('ui.INN'), 0x0b9c1c);
    _pvpLiveBtn = makeBtn('footer/nav_menu/arena_icon.png', 2.5, { screen_id: Screens.ARENA_LOBBY }, text('ui.ARENA'), 0xff4a4a);
    _cavernsBtn = makeBtn('footer/nav_menu/shop_icon.png', 3.5,  { screen_id: Screens.CAVERNS }, text('ui.CAVERNS'), 0xff0000);
    _altarBtn = makeBtn('footer/nav_menu/altar_icon.png', 4.5, { screen_id: Screens.TEMPLE }, text('ui.TEMPLE'), 0xB23AFF);
    _shopBtn = makeBtn('shop/shopkeeper_portrait.png', 5.5, { screen_id: Screens.SHOP }, text('ui.SHOP'), 0x3FCD00);

    const lockedTextStyle = {
      fontFamily: 'Courier New',
      fontSize: 52,
      fontStyle: 'bold',
      fill: 0xffffff,
      dropShadow: true,
      dropShadowColor: 0x000000,
      dropShadowDistance: 4
    };
    const lockedTint = 0x444444;

    // check Arena access
    // _pvpLiveBtn.buttonMode = _pvpLiveBtn.interactive = false;
    // _pvpLiveBtn.tint = _pvpLiveBtn.label.tint = lockedTint;
    // let coming_soon_btn = new PIXI.Text('Coming Soon', lockedTextStyle);
    // coming_soon_btn.anchor.x = coming_soon_btn.anchor.y = 0.5;
    // coming_soon_btn.rotation = -Math.PI / 5;
    // coming_soon_btn.x = (_pvpLiveBtn.width / 2) / _pvpLiveBtn.scale.x;
    // coming_soon_btn.y = (_pvpLiveBtn.height / 2) / _pvpLiveBtn.scale.y;
    // _pvpLiveBtn.addChild(coming_soon_btn);

    // check caverns access
    // coming_soon_btn = new PIXI.Text('Coming Soon', lockedTextStyle);
    // coming_soon_btn.anchor.x = coming_soon_btn.anchor.y = 0.5;
    // coming_soon_btn.rotation = -Math.PI / 5;
    // coming_soon_btn.x = (_pvpLiveBtn.width / 2) / _pvpLiveBtn.scale.x;
    // coming_soon_btn.y = (_pvpLiveBtn.height / 2) / _pvpLiveBtn.scale.y;
    // _cavernsBtn.buttonMode = _cavernsBtn.interactive = false;
    // _cavernsBtn.tint = _cavernsBtn.label.tint = lockedTint;
    // _cavernsBtn.addChild(coming_soon_btn);
    // check caverns access
    if (false) { // if (GameStateStore.getAll().num_unlocked_heroes < 3) {
      _cavernsBtn.buttonMode = _cavernsBtn.interactive = false;
      _cavernsBtn.tint = _cavernsBtn.label.tint = lockedTint;

      let need3heroes = new PIXI.Text('Coming Soon', lockedTextStyle); // text('ui.need_3_heroes'), lockedTextStyle);
      need3heroes.anchor.x = need3heroes.anchor.y = 0.5;
      need3heroes.rotation = -Math.PI/5;
      need3heroes.x = (_cavernsBtn.width/2) / _cavernsBtn.scale.x;
      need3heroes.y = (_cavernsBtn.height/2) / _cavernsBtn.scale.y;
      _cavernsBtn.addChild(need3heroes);

      GameStateStore.removeListener(GameStateStore.HERO_UNLOCKED, makeButtons);
      GameStateStore.on(GameStateStore.HERO_UNLOCKED, makeButtons);
    }

    // check shop access
    const force_shop_access = true // TODO(@robertlombardo): rm testing stub
    let highestHeroLevel = 0;
    const hero_roster = GameStateStore.getAll().gameState?.hero_roster ?? {};
    for (const hero of Object.values(hero_roster)) {
      if (hero.level > highestHeroLevel) {
        highestHeroLevel = hero.level;
      }
    }
    if (highestHeroLevel < 5 && !force_shop_access) {
      _shopBtn.buttonMode = _shopBtn.interactive = false;
      _shopBtn.tint = _shopBtn.label.tint = lockedTint;

      let needLvl5 = new PIXI.Text('Coming Soon', lockedTextStyle); // text('ui.need_level_5'), lockedTextStyle);
      needLvl5.anchor.x = needLvl5.anchor.y = 0.5;
      needLvl5.rotation = -Math.PI/5;
      needLvl5.x = (_shopBtn.width/2) / _shopBtn.scale.x;
      needLvl5.y = (_shopBtn.height/2) / _shopBtn.scale.y;
      _shopBtn.addChild(needLvl5);

      GameStateStore.removeListener(GameStateStore.HERO_LEVEL_UP, makeButtons);
      GameStateStore.on(GameStateStore.HERO_LEVEL_UP, makeButtons);
    }

    // check altar access
    if (false) {
      _altarBtn.buttonMode = _altarBtn.interactive = false;
      _altarBtn.tint = _altarBtn.label.tint = lockedTint;

      let need10kgold = new PIXI.Text('Coming Soon', lockedTextStyle); // text('ui.need_5k_gold'), lockedTextStyle);
      need10kgold.anchor.x = need10kgold.anchor.y = 0.5;
      need10kgold.rotation = -Math.PI/5;
      need10kgold.x = (_altarBtn.width/2) / _altarBtn.scale.x;
      need10kgold.y = (_altarBtn.height/2) / _altarBtn.scale.y;
      _altarBtn.addChild(need10kgold);

      CurrencyStore.removeListener(CurrencyStore.CURRENCY_CHANGE, makeButtons);
      CurrencyStore.on(CurrencyStore.CURRENCY_CHANGE, makeButtons);
    }
  };
  makeButtons();

  let _highlightedBtn = null;
  const highlightBtn = (btn) => {
    if (btn) {
      btn.label.tint = btn.highlightColor;
      btn.label.labelText.style.fill = 0xffffff;
      btn.label.labelText.style.dropShadoowColor = 0x000000;
      _highlightedBtn = btn;
    }
  };

  const removeButtonHighlight = () => {
    if (_highlightedBtn) {
      _highlightedBtn.label.tint = 0xffffff;
      _highlightedBtn.label.labelText.style.fill = 0x000000;
      _highlightedBtn.label.labelText.style.dropShadoowColor = 0x454545;
    }
  };

  const onUINav = () => {
    removeButtonHighlight();

    const { current_ui_screen, current_game_mode } = UIStore.getAll();
    switch (current_ui_screen) {
      case Screens.TEMPLE: highlightBtn(_altarBtn); break;
      case Screens.ARENA_LOBBY: highlightBtn(_pvpLiveBtn); break;
      case Screens.CAVERNS: highlightBtn(_cavernsBtn); break;
      case Screens.INN: highlightBtn(_pvpAutoBtn); break;
      case Screens.HERO_BUILDS:
      case Screens.EDIT_HERO:
        highlightBtn(_heroBuildsBtn);
        break;
      case Screens.SHOP: highlightBtn(_shopBtn); break;
    }
  };
  removeListeners();
  UIStore.on(UIStore.UI_NAV, onUINav);
  onUINav();

  this.dispose = () => {
    this.destroy();
    destroyButtons();
    removeListeners();
  };
  function removeListeners() {
    CurrencyStore.removeListener(CurrencyStore.CURRENCY_CHANGE, makeButtons);
    GameStateStore.removeListener(GameStateStore.HERO_UNLOCKED, makeButtons);
    GameStateStore.removeListener(GameStateStore.HERO_LEVEL_UP, makeButtons);
    UIStore.removeListener(UIStore.UI_NAV, onUINav);
  }
};
NavMenu.prototype = Object.create(PIXI.Container.prototype);
NavMenu.prototype.constructor = NavMenu;
export default NavMenu;