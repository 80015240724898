import * as PIXI from 'pixi.js';
import en_index from '../public/assets/text/en';
import Config from '~/constants/Config';
import Tools from '~/Tools';

const GLOBAL_TEXT_DICT = {
  en: en_index,
};

const makeTextObj = (locale) => GLOBAL_TEXT_DICT[locale];

// const pl = makeTextObj( 'pl' );
// const pt = makeTextObj( 'pt' );
// const es = makeTextObj( 'es' );
const en = makeTextObj('en');

let browserLanguage;
export const getBrowserLanguage = () => browserLanguage;

switch (Config.LOCALE) {
// case 'pl': browserLanguage = pl; break;
// case 'pt': browserLanguage = pt; break;
// case 'es': browserLanguage = es; break;

  case 'en':
  default:   browserLanguage = en; break;
}

const resolveStringPath = function(path, obj) {
  return path.split('.').reduce(function(prev, curr) {
    return prev ? prev[curr] : undefined;
  }, obj || self);
};

export default (path) => {
  try {
    var result = resolveStringPath(path, browserLanguage);
    if (result) {
      return result;
    } else {
      return resolveStringPath(path, en);
    }
  } catch (error) {
    try {
      return resolveStringPath(path, en);
    } catch (err) {
      logError(err, {
        module: 'text',
        func: 'default',
        path,
      });
      return '?????';
    }
  }
};

export const getChatCommandEmote = (command, actor, target_displayName) => {
  try {
    return browserLanguage.chat.emotes[command.split('/')[1]](actor, target_displayName);
  } catch (err) {
    logError(err, {
      module: 'text',
      func: 'getChatCommandEmote',
      // userName,
      command,
      // target
    });
  }
};

export const getGameNotificationText = (userName, type, data) => {
  try {
    return browserLanguage.chat.notifications[type](userName, data);
  } catch (err) {
    logError(err, {
      module: 'text',
      func: 'getGameNotificationText',
      userName,
      type,
      data,
    });
  }
};

export const getShopkeeperMessage = () => {
  try {
    return browserLanguage.shop.randomShopkeeperMessage();
  } catch (err) {
    logError(err, {
      module: 'text',
      func: 'getShopkeeperMessage',
    });
  }
};

const BATTLE_LOG_STYLE = {
  fontFamily: 'Courier New',
  fontSize: 12,
  wordWrap: true,
};

let _txtPool = new Tools.ObjectPool(

  // create func
  () => {
    var txt = new PIXI.Text('', BATTLE_LOG_STYLE);
    txt.potato = true;
    return txt;
  },

  // reset func
  (txt) => {
    txt.style.fontStyle = 'normal';
  },

  // starting num
  150,
);

export const returnBattleLogEntryTextToPool = (txt) => {
  _txtPool.put(txt);
};

export const getBattleEventLogEntry = (data) => {
  try {
    var actor = data.allPieces[ data.event.actorId ] || data.event.unit;
    var actorHandle = actor
      ? actor.hero_handle || actor.handle
      : data.event.actorHandle || null;
    var actorName = actorHandle
      ? browserLanguage.unit_names[actorHandle]
      : null;

    var victim = data.allPieces[data.event.victimId];
    var victimHandle = victim
      ? victim.hero_handle || victim.handle
      : data.event.victimHandle || null;
    var victimName = victimHandle
      ? browserLanguage.unit_names[victimHandle]
      : null;

    const textFunc = browserLanguage.battle_event_log[data.event.eventHandle];
    if (textFunc) {
      return textFunc(actorName, victimName, data);
    } else {
      return null;
    }

  } catch (err) {
    logError(err, {
      module: 'text',
      func: 'getBattleEventLogEntry',
      data,
    });
  }
};

export const getBattleEventLogEntryForAbility = (data, allPieces) => {
  try {
    var actor = allPieces[ data.actorId ];
    var actorHandle = actor ? actor.hero_handle || actor.handle : null;
    var actorName = actorHandle? browserLanguage.unit_names[actorHandle] : null;

    var victim = allPieces[ data.victimId ];
    var victimHandle = victim ? victim.hero_handle || victim.handle : null;
    var victimName = victimHandle? browserLanguage.unit_names[victimHandle] : null;

    const textFunc = browserLanguage.battle_event_log[data.abilityHandle];
    if (textFunc) {
      return textFunc(actorName, victimName, data);
    } else {
      return null;
    }
  } catch (err) {
    logError(err, {
      module: 'text',
      func: 'getBattleEventLogEntryForAbility',
      data,
      allPieces,
    });
  }
};

export const getBattleEventLogEntryForCondition = (data, allPieces) => {
  try {
    var instigator = allPieces[ data.instigatorId ];
    var instigatorHandle = instigator ? instigator.hero_handle || instigator.handle : null;
    var instigatorName = instigatorHandle? browserLanguage.unit_names[instigatorHandle] : null;

    var owner = allPieces[ data.ownerId ];
    var ownerHandle = owner ? owner.hero_handle || owner.handle : null;
    var ownerName = ownerHandle? browserLanguage.unit_names[ownerHandle] : null;

    const textFunc = browserLanguage.battle_event_log[data.handle]
    if (textFunc) {
      return textFunc(instigatorName, ownerName, data);
    } else {
      return null;
    }
  } catch (err) {
    logError(err, {
      module: 'text',
      func: 'getBattleEventLogEntryForCondition',
      data,
      allPieces,
    });
  }
};

export const getCavernsNewTurnMessage = ({ ai_unit }) => {
  return browserLanguage.battle_event_log.new_unit_turn({ ai_unit });
}
  