<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90">
      <DialogPanel class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black">
        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.tabula_rasa') }}
        </DialogTitle>

        <div class="py-6 pl-6 pr-2 flex flex-col">
          <p>
            {{ text('ui.attributes_reset_question.p1') }}{{ text(`heroes.${props.hero_handle}.name`) }}{{ text('ui.attributes_reset_question.p2') }}{{ Economy.ATT_RESET_COST }}{{ text('ui.attributes_reset_question.p3') }}
          </p>
        </div>

        <div class="w-full h-24 px-6 flex justify-center items-center z-10">
          <DTButton
            type="success"
            class="py-1 px-5"
            @click="onYesClick"
          >
            {{ text('ui.yes') }}
          </DTButton>
          <DTButton
            type="error"
            class="ml-3 py-1 px-5"
            @click="props.onClose"
          >
            {{ text('ui.no') }}
          </DTButton>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';
import Economy from 'dt-common/constants/Economy';
import text from '~/text';
import { DTButton } from '~/view/components/common/DOM';


const props = defineProps({
  onConfirmed: {
    type: Function,
    required: true,
  },
  hero_handle: {
    type: String,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

function onYesClick() {
  props.onConfirmed();
  props.onClose();
}
</script>
