import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools';
import ApplicationStore from '~/flux/stores/ApplicationStore';

var _qMark;
var _qMarkPool = new ObjectPool(

	// create func
	() => {
		_qMark = new PIXI.Text( '?', {
			fontFamily: 'Courier New',
			fontWeight: 'bold',
			fill: 0xff0000
		});
		_qMark.anchor.x = _qMark.anchor.y = 0.5;
		return _qMark;
	},

	// reset func
	( qMark ) => {
    gsap.killTweensOf(qMark);
		qMark.scale = {x:1,y:1};
		qMark.rotation = 0;
		qMark.alpha = 1;
	},

	// starting num
	50
);

const instances = {};
	
const ScaredRendering = {
	render: function( condition, gameBoard, isCaverns ) {
		if( instances[condition.uid] ) {
			return;
		}

		instances[ condition.uid ] = {};
		instances[ condition.uid ].ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];
				
		const killQMark = ( qm ) => { 
			if( qm.parent ) {
				qm.parent.removeChild( qm );
			}
			_qMarkPool.put( qm );
		};

		const makeEffect = ( conditionId ) => {
			var qm = _qMarkPool.get();
			qm.style.fontSize = isCaverns? 12:90;			
			qm.y = isCaverns? -6-Math.random()*3 : -60-Math.random()*30;
			var xFactor = isCaverns? -2+Math.random()*4 : -20+Math.random()*40;
			qm.x = xFactor * 5;

			const ownerSprite = instances[conditionId].ownerSprite;
			
			var partToAddEffectTo = isCaverns? ownerSprite.tileImg : ownerSprite.puppet.getParts().head;
			partToAddEffectTo.addChild( qm );

			TweenMax.to( qm.scale, 0.8, {x:isCaverns?2:8,y:isCaverns?2:8} );
			qm.rotation_tween = TweenMax.to(qm, 1.5, {
				y: isCaverns ? -20 + Math.random() * 10 : -250 + Math.random() * 150,
				rotation: xFactor * (1 + Math.random() * 3) * (Math.PI / 180),
				alpha: 0,
				ease: Expo.easeOut,
				onComplete: killQMark,
				onCompleteParams: [qm],
			});

			instances[ conditionId ].interval = TweenMax.delayedCall( 0.5+Math.random()*0.5, makeEffect, [conditionId] );
		};

		instances[ condition.uid ].interval = TweenMax.delayedCall( Math.random()*0.1, makeEffect, [condition.uid] );
	},

	stopRendering: function( conditionId ) {
		if( instances[conditionId] ) {
			instances[conditionId].interval.kill();
			delete instances[ conditionId ];
		}
	},

	dispose: function() {
		for( var prop in instances ) {
			ScaredRendering.stopRendering( prop );
		}
	}
};
export default ScaredRendering;