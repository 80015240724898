<template>
  <div class="flex flex-wrap justify-end items-center">
    <div
      v-if="state.rewards.gold"
      class="relative"
    >
      <img
        :src="`${DOM_IMG_PATH}/gold.png`"
        class="mr-3 w-4 h-4 cursor-pointer pointer-events-auto"
        @click="onGoldIconClick"
      />
      <div class="absolute right-2 -bottom-2 text-white " style="font-size: 10px;">
        {{ Tools.formatGameCurrency(state.rewards.gold) }}
      </div>
    </div>
    <div
      v-if="state.rewards.pixieDust"
      class="relative"
    >
      <img
        :src="`${DOM_IMG_PATH}/pixie_dust.png`"
        class="mr-3 w-4 h-4 cursor-pointer pointer-events-auto"
        @click="onPixieDustIconClick"
      />
      <div class="absolute right-2 -bottom-2 text-white" style="font-size: 10px;">
        {{ Tools.formatGameCurrency(state.rewards.pixieDust) }}
      </div>
    </div>
    <div
      v-for="item, i of state.rewards.items"
    >
      <div
        v-if="!!state.rewards.items[i]"
        :id="`battle_rewards_icons_item_anchor_${item.uid}`"
        :key="item.uid"
        :style="`width: ${GAME_ITEM_ICON_SIZE_PX}px; height: ${GAME_ITEM_ICON_SIZE_PX}px;`"
        class="ml-1"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onBeforeUnmount, onMounted, reactive } from 'vue';
import describeVacantInventorySpace from 'dt-common/isomorphic-helpers/describeVacantInventorySpace';
import GameItemIcon from '~/components/common/GameItemIcon';
import { BattleDebriefActions } from '~/flux/actions';
import { GameStateStore, PlayerStore } from '~/flux/stores';
import Tools from '~/Tools';
import CanvasTools from '~/view/CanvasTools';

const { ASSETS_PATH='assets' } = process.env;
const DOM_IMG_PATH = `${ASSETS_PATH}/img/DOM`;
const GAME_ITEM_ICON_SIZE_PX = 32;

let _reward_item_icons;

const props = defineProps({
  battle_id: {
    type: String,
    required: false,
  },
  inn_log_id: {
    type: String,
    required: false,
  },
  modal_pixi_stage: {
    type: Object,
    required: true,
  },
  rewards: {
    type: Object,
    required: true,
  }
});

const state = reactive({
  rewards: {},
});

onBeforeUnmount(() => {
  destroyRewardItemIcons();
});

onMounted(() => {
  state.rewards = props.rewards[PlayerStore.getAll().loggedInPlayerId] || props.rewards;
  nextTick(makeRewardItemIcons);
});

function destroyRewardItemIcons() {
  for (const icon of _reward_item_icons || []) {
    icon.dispose();
  }
  _reward_item_icons = null;

}

function makeRewardItemIcons() {
  destroyRewardItemIcons();
  _reward_item_icons = [];

  const items = Object.values(
    state.rewards?.items ?? {}
  )
  for (const item of items) {
    // make game item icon & add to stage
    const rect = CanvasTools.getDomAnchor(`battle_rewards_icons_item_anchor_${item?.uid}`);
    if (!item || !rect) {
      continue;
    }
    const item_icon = new GameItemIcon(item);
    item_icon.setScale(GAME_ITEM_ICON_SIZE_PX);
    item_icon.x = rect.x + rect.width / 2;
    item_icon.y = rect.y + rect.height / 2;
    props.modal_pixi_stage.addChild(item_icon);
    _reward_item_icons.push(item_icon);

    item_icon.stage_rect = CanvasTools.getDomAnchor('modal_canvas');

    // // add interaction listener
    item_icon.interactive = item_icon.button_mode = true;
    item_icon.tap = item_icon.click = (item_uid => {
      onItemIconClick(item_uid);
    }).bind(null, item.uid);
  }
}

function onGoldIconClick() {
  if (props.battle_id) {
    BattleDebriefActions.claimGoldReward(props.battle_id);
  } else if (props.inn_log_id) {
    BattleDebriefActions.claimGoldReward_Inn(props.inn_log_id);
  }
}

function onPixieDustIconClick() {
  if (props.battle_id) {
    BattleDebriefActions.claimPixieDustReward(props.battle_id);
  } else if (props.inn_log_id) {
    BattleDebriefActions.claimPixieDustReward_Inn(props.inn_log_id);
  }
}

function onItemIconClick(itemId) {
  const { inventory } = GameStateStore.getAll().gameState; 
  const { is_room } = describeVacantInventorySpace(inventory);

  if (is_room) {
    if (props.battle_id) {
      BattleDebriefActions.claimItemReward(props.battle_id, itemId);
    } else if (props.inn_log_id) {
      BattleDebriefActions.claimItemReward_Inn(props.inn_log_id, itemId);
    }
  } else {
    GameStateStore.emit(GameStateStore.NO_INVENTORY_SPACE);
  }
};
</script>
