import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools';

var _effect;
var _effectPool = new ObjectPool(

	// create func
	() => {
		_effect = new PIXI.Graphics();
		_effect.beginFill( 0x00ffea );
		_effect.drawRect( -1, -2, 2, 4 );
		_effect.endFill();
	    return _effect;
	},

	// reset func
	( effect ) => {
    gsap.killTweensOf(effect);
		effect.alpha = 1;
	},

	// starting num
	30
);

const instances = {};
	
const InspiredRendering = {
	render: function( condition, gameBoard ) {
		if( instances[condition.uid] ) {
			return;
		}
		
		instances[ condition.uid ] = {};
		instances[ condition.uid ].ownerSprite = gameBoard.getAllPieceSprites()[condition.ownerId];
		instances[ condition.uid ].interval = TweenMax.delayedCall( Math.random()*0.1, InspiredRendering.makeEffect, [condition.uid] );
	},

	makeEffect: ( conditionId ) => {
		if( !instances[conditionId] ) {
			return;
		}

		const ownerSprite = instances[conditionId].ownerSprite;
		if( !ownerSprite ) {
			return;
		}

		var effect = _effectPool.get();
		effect.x = -ownerSprite.tileImg.width/2 + Math.random()*ownerSprite.tileImg.width;
		effect.y = -Math.random()*ownerSprite.tileImg.height/2;
		ownerSprite.addChildAt( effect, Math.floor(Math.random()*ownerSprite.children.length) );

		TweenMax.to( effect, 0.876, {
			y: effect.y - 25 + Math.random()*10,
			alpha: 0,
			ease: Linear.easeNone,
			onComplete: InspiredRendering.disposeEffect,
			onCompleteParams: [ effect ]
		});

		instances[ conditionId ].interval = TweenMax.delayedCall( 0.3+Math.random()*1.0, InspiredRendering.makeEffect, [conditionId] );
	},		

	disposeEffect: (effect) => {
		effect.parent?.removeChild(effect);
		_effectPool.put( effect ); 
	},

	stopRendering: function( conditionId ) {
		if( instances[conditionId] ) {
			if( instances[conditionId].interval ) {
				instances[conditionId].interval.kill();
			}
			delete instances[ conditionId ];
		}
	},

	dispose: function() {
		for( var prop in instances ) {
			InspiredRendering.stopRendering( prop );
		}
	}
};
export default InspiredRendering;