import FlyingArrow from '../../FlyingArrow';
import BattleConfig from 'dt-common/constants/BattleConfig';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import Audio from '~/Audio';

const MultiShotRendering = {
	render: function( data, gameBoard, isCaverns ) {
		
	},

	renderResult: function( data, gameBoard, isCaverns ) {
		if( data.phase==='fire_arrows' ) {
			const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
      const render_movement = !isCaverns || actorSprite.gamePiece.hero;
			const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

			render_movement && actorSprite.puppet.getMovements().fireBow.movePuppet({ transitTime });

			setTimeout( ()=> {
				for( var i = 0; i < data.victimIds.length; ++i ) {
					var victimSprite = gameBoard.getAllPieceSprites()[data.victimIds[i]];
					FlyingArrow( actorSprite, victimSprite, BattleCalc.projectileTime(BattleConfig.ARROW_SPEED,actorSprite.gamePiece,victimSprite.gamePiece) );
				}
			}, transitTime*1000*(5/8) );

		} else if( data.phase==='strikes' && data.strikes ) {
			for( var i = 0; i < data.strikes.length; ++i ) {
				if( data.strikes[i].success ){
					switch( Math.floor(Math.random()*3) ) {
						case 0: Audio.play( 'arrow_hit_01' ); break;
						case 1: Audio.play( 'arrow_hit_02' ); break;
						case 2: Audio.play( 'arrow_hit_03' ); break;
					}
				}
			}
		}
	}
};
export default MultiShotRendering;