import { EventEmitter } from 'events';

import PlayerDispatcher from '~/flux/dispatchers/PlayerDispatcher';
import ArenaLobbyDispatcher from '~/flux/dispatchers/ArenaLobbyDispatcher';
import ChatDispatcher from '~/flux/dispatchers/ChatDispatcher';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';
import text from '~/text';
import Colors from '~/constants/Colors';

let playerId;
let _socket;
const MATCH_TYPE_LOCATION = 'dt_arena_matchType';

// the stuff we serve:
let competition_profiles;
// let opponent = null;
let lastFightLiveRequest = null;

const ArenaLobbyStore = Object.assign({}, EventEmitter.prototype, {
  GOT_OPPONENT: 'GOT_OPPONENT',
  LAST_MATCH_TYPE_CHANGED: 'LAST_MATCH_TYPE_CHANGED',
  LEADERBOARD_RANK: 'LEADERBOARD_RANK',
  SHOW_CHALLENGE_BY_NAME_DIALOG: 'SHOW_CHALLENGE_BY_NAME_DIALOG',
  FIGHT_LIVE_REQUEST: 'FIGHT_LIVE_REQUEST',
  RECOVERED_OPPONENT_NAME: 'RECOVERED_OPPONENT_NAME',

  getAll() {
    return {
      competition_profiles,
      // opponent,
      lastFightLiveRequest,
    };
  },
});
export default ArenaLobbyStore;

PlayerDispatcher.register(registerDispatchHandlers({
  [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
}));
ArenaLobbyDispatcher.register(registerDispatchHandlers({
  [ArenaLobbyDispatcher.ENTER_STANDARD_MATCHMAKING_QUEUE]: enterStandardMatchmakingQueue,
  [ArenaLobbyDispatcher.FETCH_LEADERBOARD_RANK]: fetchLeaderboardRank,
  // [ArenaLobbyDispatcher.CHALLENGE_PLAYER_BY_NAME]: challengePlayerByName,
  // [ArenaLobbyDispatcher.ACCEPT_LIVE_CHALLENGE]: acceptLiveChallenge,
}));
// ChatDispatcher.register(registerDispatchHandlers({
  // [ChatDispatcher.ENTER_MESSAGE]: onChatMessageEntered,
// }));

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket;

    if (!_socket.has_ArenaLobbyStore_listeners) {
      // _socket.on('named_opponent_not_found', onNamedOpponentNotFound);
      // _socket.on('opponent', onOpponent);
      // _socket.on('live_arena_challenge', onFightLiveRequest);
      _socket.on('challengeResolved', onChallengeResolved);
      _socket.on('arena_leaderboard_rank', onLeaderboardRank);

      _socket.has_ArenaLobbyStore_listeners = true;
    }
  } catch (err) {
    logError(err, {
      module: 'ArenaLobbyStore',
      func: 'onSocketConnected',
    });
  }
}

function onPlayerLoggedIn(action) {
  try {
    const { player } = action;
    playerId = player._id;
    competition_profiles = player.gameState.competition_profiles;
  } catch (err) {
    logError(err, {
      module: 'ArenaLobbyStore',
      func: 'onPlayerLoggedIn',
      action,
    });
  }
}

// function challengePlayerByName(action) {
//   _named_opponent = action.playerName;

//   _socket.emit('get_opponent_by_name', {
//     playerId,
//     matchType,
//     opponent_name: _named_opponent,
//   });
// }

// function onNamedOpponentNotFound(data) {
//   $addMessageLogMessage(text('ui.no_player_by_that_name'), Colors.RED);
// }

// function onChatMessageEntered(action) {
//   try {
//     if (action.text[0] === '/') {
//       const command = action.text.split(' ')[0];
//       if (command === '/challenge') {
//         const target = action.text.slice(command.length + 1);
//         challengePlayerByName({ playerName: target });
//       }
//     }
//   } catch (err) {
//     logError(err, {
//       module: 'ArenaLobbyStore',
//       func: 'onChatMessageEntered',
//       action,
//     });
//   }
// }

// function onFightLiveRequest(data) {
//   const { battle_id, challengerName, matchType } = data;

//   lastFightLiveRequest = { battle_id, challengerName, matchType };
//   ArenaLobbyStore.emit(ArenaLobbyStore.FIGHT_LIVE_REQUEST, lastFightLiveRequest);
// }

// function acceptLiveChallenge(action) {
//   const { battle_id, challengerName } = lastFightLiveRequest;

//   opponent = { displayName: challengerName };

//   _socket.emit('accept_live_challenge', {
//     playerId,
//     battle_id,
//   });
// }

function onChallengeResolved(data) {
  Object.assign(competition_profiles, data.playerStateUpdates[playerId].competition_profile)
}

function enterStandardMatchmakingQueue(action) {
  _socket.emit('enter_standard_matchmaking_queue', {
    playerId,
    ...action
  });
}

function fetchLeaderboardRank(action) {
  _socket.emit('get_arena_leaderboard_rank', {
    playerId,
    ...action,
  });
}

function onLeaderboardRank(data) {
  ArenaLobbyStore.emit(ArenaLobbyStore.LEADERBOARD_RANK, data)
}