import * as PIXI from 'pixi.js';
import getUnitState from 'dt-common/isomorphic-helpers/getUnitState';
import { FluxGetters } from '~/flux/stores';
import EditHeroStats from '~/view/game-screens/edit-hero/canvas/EditHeroStats';
import DTCarousel from './DTCarousel';
import PuppetWithNameplate from './PuppetWithNameplate';

const HeroPicker = ({
  built_heroes,
  roster_heroes,
  puppet_scale_mod,
  show_level_views = false,
  initial_selected_build_id,
  do_puppet_transition_in,
  skip_initial_update,
}) => {
  let _edit_hero_stats;

  const _carousel = new DTCarousel({
    selection_data: built_heroes.map(built_hero => {
      const puppet = new PuppetWithNameplate({
        built_hero,
        roster_hero: roster_heroes[built_hero.hero_handle],
        scale_mod: puppet_scale_mod || 0.5,
        show_build_name: true,
        build_name_label_only: true,
        build_name_scale_mod: 2,
      });
      puppet.interactive = puppet.buttonMode = true;
      if (do_puppet_transition_in) {
        puppet.transitionIn();
      }
      
      const result = {
        // name: built_hero.build_display_name || text('heroes.basic'),
        icon: puppet,
        icon_only: true,
      }
      return result;
    }),
    initial_selected_element_index: built_heroes.findIndex(({ _id }) => _id === initial_selected_build_id) || 0,
    add_name_labels: false,
    parallax_effect_mod: 7,
    getElementHitArea: () => {
      return new PIXI.Rectangle(-110, -300, 220, 400);
    },
    skip_initial_update,
  });

  function destroyEditHeroStats() {
    _edit_hero_stats?.dispose();
  }

  function makeEditHeroStats() {
    destroyEditHeroStats();

    const { built_hero, roster_hero } = FluxGetters.getFocusedHeroBuild();
    const _edit_hero_stats = new EditHeroStats(
      getUnitState({
        roster_hero,
        unit_build: built_hero,
      }),
    );
    _carousel.addChild(_edit_hero_stats);
  };
  makeEditHeroStats();

  return _carousel;
};
export default HeroPicker;
