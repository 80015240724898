import { EventEmitter } from 'events';
import firebase from 'firebase/compat/app';
import Economy from 'dt-common/constants/Economy';
import { BattleDebriefDispatcher, PlayerDispatcher } from '~/flux/dispatchers';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';
import text from '~/text';
import Colors from '~/constants/Colors';

const { ALTAR_ACCESS_REQ } = Economy;

let _socket;
let playerId;

// the stuff we serve
let pixieDust = 0;
let gold = 0;
let altarAccess = false;

const CurrencyStore = Object.assign({}, EventEmitter.prototype, {
  CURRENCY_CHANGE: 'CURRENCY_CHANGE',
  GOLD_REWARD_CLAIMED: 'GOLD_REWARD_CLAIMED',
  INSUFFICIENT_PIXIE_DUST: 'INSUFFICIENT_PIXIE_DUST',
  PD_CREDIT: 'PD_CREDIT',
  PD_REWARD_CLAIMED: 'PD_REWARD_CLAIMED',

  getAll() {
    return {
      pixieDust,
      gold,
      altarAccess,
    };
  },
});
export default CurrencyStore;

PlayerDispatcher.register(registerDispatchHandlers({
  [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
}));
BattleDebriefDispatcher.register(registerDispatchHandlers({
  [BattleDebriefDispatcher.CLAIM_GOLD_REWARD]: claimGoldReward,
  [BattleDebriefDispatcher.CLAIM_PD_REWARD]: claimPixieDustReward,
}));

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  _socket = socket
  try {
    if (!_socket.has_CurrencyStore_listeners) {
      _socket.on('currencies', onCurrencies);
      _socket.on('gold_reward_claimed', onGoldRewardClaimed);
      _socket.on('pixie_dust_reward_claimed', onPixieDustRewardClaimed);
      _socket.on('insufficientPixieDust', onInsufficientPixieDust);
      _socket.on('insufficient_gold', onInsufficientGold);
      _socket.has_CurrencyStore_listeners = true;
    }
  } catch (err) {
    logError(err, {
      module: 'CurrencyStore',
      func: 'onSocketConnected',
    });
  }
}

function onPlayerLoggedIn(action) {
  try {
    const { player } = action;
    playerId = player._id;

    onCurrencies(player, false);
  } catch (err) {
    logError(err, {
      module: 'CurrencyStore',
      func: 'onPlayerLoggedIn',
      action,
    });
  }
}

function onCurrencies(data, log_analytics=true) {
  try {
    const { currency1, currency2 } = data;

    if (!isNaN(currency1)) {
      if (log_analytics) {
        const fa = firebase.analytics();

        if (currency1 > pixieDust) {
          fa.logEvent('earn_virtual_currency', {
            virtual_currency_name: 'pixie_dust',
            value: currency1 - pixieDust,
          });
        } else if (currency1 < pixieDust) {
          fa.logEvent('spend_virtual_currency', {
            virtual_currency_name: 'pixie_dust',
            value: pixieDust - currency1,
          });
        }
      }

      pixieDust = currency1;
    }

    if (!isNaN(currency2)) {
      if (log_analytics) {
        const fa = firebase.analytics();

        if (currency2 > gold) {
          fa.logEvent('earn_virtual_currency', {
            virtual_currency_name: 'gold',
            value: currency2 - gold,
          });
        } else if (currency2 < gold) {
          fa.logEvent('spend_virtual_currency', {
            virtual_currency_name: 'gold',
            value: gold - currency2,
          });
        }
      }

      gold = currency2;
    }

    if (
      gold >= ALTAR_ACCESS_REQ ||
      data.altarAccess === true ||
      (data.gameState && data.gameState.altarState && data.gameState.altarState.altarAccess === true)
    ) {
      altarAccess = true;
    }

    CurrencyStore.emit(CurrencyStore.CURRENCY_CHANGE);
  } catch (err) {
    logError(err, {
      module: 'CurrencyStore',
      func: 'onCurrencies',
      data,
    });
  }
}

function onInsufficientPixieDust() {
  try {
    CurrencyStore.emit(CurrencyStore.INSUFFICIENT_PIXIE_DUST);
  } catch (err) {
    logError(err, {
      module: 'CurrencyStore',
      func: 'onInsufficientPixieDust',
    });
  }
}

function onInsufficientGold() {
  $addMessageLogMessage(text('ui.not_enough_gold'), Colors.RED);
}

function claimGoldReward(action) {
  _socket.emit('claim_gold_reward', {
    playerId,
    battle_id: action.battle_id,
  });
}
function claimPixieDustReward(action) {
  _socket.emit('claim_pixie_dust_reward', {
    playerId,
    battle_id: action.battle_id,
  });
}

function onGoldRewardClaimed(data) {
  CurrencyStore.emit(CurrencyStore.GOLD_REWARD_CLAIMED, data.battle_id);
}

function onPixieDustRewardClaimed(data) {
  CurrencyStore.emit(CurrencyStore.PD_REWARD_CLAIMED, data.battle_id);
}
