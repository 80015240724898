import PaymentsDispatcher from '~/flux/dispatchers/PaymentsDispatcher'

const PaymentsActions = {      
  createPaymentIntent({ purchase_selection, payment_method_id}) {
    PaymentsDispatcher.handlePaymentsAction({
      actionType: PaymentsDispatcher.CREATE_PAYMENT_INTENT,
      purchase_selection,
      payment_method_id
    })
  }
}
export default PaymentsActions
