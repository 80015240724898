import Howler from 'howler';
import SettingsStore from '~/flux/stores/SettingsStore';

Howler.autoSuspend = false;
const { Howl } = Howler;

const { ASSETS_PATH = 'assets' } = process.env;
const AUDIO_PATH = `${ASSETS_PATH}/audio`;

let sounds = {
  // background music & ambience
  menu_music: new Howl({ src: [`${AUDIO_PATH}/bg/menu_music.mp3`], loop: true }),
  reverse_tibetan_chant: new Howl({ src: [`${AUDIO_PATH}/bg/reverse_tibetan_chant_soft.mp3`], loop: true }),
  reverse_tibetan_chant_02: new Howl({ src: [`${AUDIO_PATH}/bg/reverse_tibetan_chant_soft.mp3`] }),
  reverse_tibetan_chant_03: new Howl({ src: [`${AUDIO_PATH}/bg/reverse_tibetan_chant_soft.mp3`] }),
  battle_music: new Howl({ src: [`${AUDIO_PATH}/bg/battle_music.mp3`], loop: true }),
  caverns_theme: new Howl({ src: [`${AUDIO_PATH}/bg/caverns_theme_96kbps.mp3`], loop: true }),

  // ui effects
  click: new Howl({ src: [`${AUDIO_PATH}/ui/click.mp3`] }),
  star_twinkle: new Howl({ src: [`${AUDIO_PATH}/ui/star_twinkle_01.mp3`] }),
  electric_smash: new Howl({ src: [`${AUDIO_PATH}/ui/electric_smash_96kbps.mp3`] }),
  airy_magic_whoosh: new Howl({ src: [`${AUDIO_PATH}/ui/airy_magic_whoosh.mp3`] }),
  equipment_storage: new Howl({ src: [`${AUDIO_PATH}/ui/equipment_storage.mp3`] }),
  coin_rattle: new Howl({ src: [`${AUDIO_PATH}/ui/coin_rattle.mp3`] }),
  register_ding: new Howl({ src: [`${AUDIO_PATH}/ui/register_ding.mp3`] }),
  level_up: new Howl({ src: [`${AUDIO_PATH}/ui/level_up_02.mp3`] }),
  crystal_twinkle: new Howl({ src: [`${AUDIO_PATH}/ui/crystal_twinkle_small.mp3`] }),

  // battle effects
  sandstone_step_6: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/sandstone_step_6.mp3`] }),
  sandstone_step_46: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/sandstone_step_46.mp3`] }),
  melee_attack_04: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/melee_attack_04.mp3`] }),
  melee_attack_12: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/melee_attack_12.mp3`] }),
  melee_attack_14: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/melee_attack_14.mp3`] }),
  melee_attack_19: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/melee_attack_19.mp3`] }),
  dagger_clang: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/dagger_clang.mp3`] }),
  sword_clang: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/sword_clang.mp3`] }),
  dodge: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/dodge_2.mp3`] }),
  throw_01: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/throw_01.mp3`] }),
  glass_shatter_01: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/glass_shatter_01_96kbps.mp3`] }),
  thump_02: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/thump_02_96kbps.mp3`] }),
  body_fall_01: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/body_fall_dirt_01_96kbps.mp3`] }),
  block: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/block_03_96kbps.mp3`] }),
  unit_death: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/unit_death.mp3`] }),
  option_attack: new Howl({ src: [`${AUDIO_PATH}/unit_actions/general/option_attack_04_96kbps.mp3`] }),

  // alchemy
  burning_hands: new Howl({ src: [`${AUDIO_PATH}/unit_actions/alchemy/burning_hands_01.mp3`] }),
  fumigate: new Howl({ src: [`${AUDIO_PATH}/unit_actions/alchemy/fumigate_final_96kbps.mp3`] }),
  ice_nine: new Howl({ src: [`${AUDIO_PATH}/unit_actions/alchemy/ice_nine_01_96kbps.mp3`] }),
  poison_weapon: new Howl({ src: [`${AUDIO_PATH}/unit_actions/alchemy/poison_weapon_05_96kbps.mp3`] }),
  lightning: new Howl({ src: [`${AUDIO_PATH}/unit_actions/alchemy/lightning_01_96kbps.mp3`] }),

  // armor use
  suture: new Howl({ src: [`${AUDIO_PATH}/unit_actions/armor_use/suture_01_96kbps.mp3`] }),

  // bow use
  fire_bow: new Howl({ src: [`${AUDIO_PATH}/unit_actions/bow_use/fire_bow.mp3`] }),
  arrow_hit_01: new Howl({ src: [`${AUDIO_PATH}/unit_actions/bow_use/arrow_hit_01.mp3`] }),
  arrow_hit_02: new Howl({ src: [`${AUDIO_PATH}/unit_actions/bow_use/arrow_hit_02.mp3`] }),
  arrow_hit_03: new Howl({ src: [`${AUDIO_PATH}/unit_actions/bow_use/arrow_hit_03.mp3`] }),
  chain_pull: new Howl({ src: [`${AUDIO_PATH}/unit_actions/bow_use/chain_pull_01_96kbps.mp3`] }),
  chain_pull_02: new Howl({ src: [`${AUDIO_PATH}/unit_actions/bow_use/chain_pull_02_96kbps.mp3`] }),

  // conjuration
  summon_04: new Howl({ src: [`${AUDIO_PATH}/unit_actions/conjuration/summon_04_96kbps.mp3`] }),
  void_spike_03: new Howl({ src: [`${AUDIO_PATH}/unit_actions/conjuration/void_spike_03.mp3`] }),

  // illusion
  devil_mask_01: new Howl({ src: [`${AUDIO_PATH}/unit_actions/illusion/devil_mask_01.mp3`] }),
  evil_laugh_01: new Howl({ src: [`${AUDIO_PATH}/unit_actions/illusion/evil_laugh_01.mp3`] }),
  antidote_02: new Howl({ src: [`${AUDIO_PATH}/unit_actions/illusion/antidote_02_96kbps.mp3`] }),
  magic_wave: new Howl({ src: [`${AUDIO_PATH}/unit_actions/illusion/magic_wave.mp3`] }),

  // melee_combat
  roundhouse: new Howl({ src: [`${AUDIO_PATH}/unit_actions/melee_combat/roundhouse.mp3`] }),
  slash: new Howl({ src: [`${AUDIO_PATH}/unit_actions/melee_combat/slash_96kbps.mp3`] }),
  armor_break: new Howl({ src: [`${AUDIO_PATH}/unit_actions/melee_combat/armor_break_03_96kbps.mp3`] }),

  // movement
  leap: new Howl({ src: [`${AUDIO_PATH}/unit_actions/movement/leap_01_96kbps.mp3`] }),

  // spirit
  send_mana: new Howl({ src: [`${AUDIO_PATH}/unit_actions/spirit/send_mana_01_96kbps.mp3`] }),
  bless: new Howl({ src: [`${AUDIO_PATH}/unit_actions/spirit/bless_02_96kbps.mp3`] }),
  positive_magic_01: new Howl({ src: [`${AUDIO_PATH}/unit_actions/spirit/positive_magic_01_96kbps.mp3`] }),
  mystic_focus: new Howl({ src: [`${AUDIO_PATH}/unit_actions/spirit/mystic_focus_03_96kbps.mp3`] }),
};

let currentBGTrack = null;
let musicVolume = SettingsStore.getAll().musicVolume;
let sfxVolume = SettingsStore.getAll().sfxVolume;

const HALF_STEP = 1 / 12;

const Audio = {
  play: (sound, isMusic = false) => {
    try {
      var audio = sounds[sound];
      if (!audio) {
        return;
      }

      audio.seek(0);
      audio.volume(isMusic ? musicVolume : sfxVolume);
      
      let sprite
      sprite = audio.play();
      
      // fun
      switch (sound) {
        case 'bless':
        case 'positive_magic_01': {
          // D major
          const rate_val = (() => {
            const rand = Math.floor(Math.random() * 7)
            switch (rand) {
              case 0: return 0.5;
              case 1: return 0.5 + HALF_STEP * 2;
              case 2: return 0.5 + HALF_STEP * 4;
              case 3: return 0.5 + HALF_STEP * 5;
              case 4: return 0.5 + HALF_STEP * 7;
              case 5: return 0.5 + HALF_STEP * 9;
              case 6: return 0.5 + HALF_STEP * 11;
            }
          })();
          audio.rate(rate_val, sprite);
          break;
        }

        case 'mystic_focus':
        case 'magic_wave':
        case 'melee_attack_04':
        case 'melee_attack_12':
        case 'melee_attack_14':
        case 'melee_attack_19':
        case 'dagger_clang':
        case 'sword_clang':
        case 'dodge':
        case 'throw_01':
        case 'thump_02':
        case 'body_fall_01':
        case 'block':
        case 'unit_death':
        case 'option_attack':
        case 'burning_hands':
        case 'poison_weapon':
        case 'lightning':
        case 'arrow_hit_01':
        case 'arrow_hit_02':
        case 'arrow_hit_03':
        case 'void_spike_03': 
        case 'antidote_02':
        case 'roundhouse': {
          // random rate mod
          audio.rate(0.75 + Math.random() * 0.5, sprite);
          break;
        }
      }
    } catch (err) {
      logError(err, {
        module: 'Audio',
        func: 'play',
        sound,
        isMusic,
      });
    }
  },

  stop: (sound) => {
    if (sounds[sound]) {
      sounds[sound].pause();
    }
  },

  setBGTrack: (sound) => {
    if (currentBGTrack === sound) {
      return;
    }

    Audio.stop(currentBGTrack);

    if (sound) {
      Audio.play(sound, true);
      sounds[sound].loop = true;
    }

    currentBGTrack = sound;
  },

  setMusicVolume: (value) => {
    musicVolume = value;

    if (sounds[currentBGTrack]) {
      sounds[currentBGTrack].volume(musicVolume);
    }
  },

  setSFXVolume: (value) => {
    sfxVolume = value;
    Audio.play('bless');
  },

  getMusicVolume: () => {
    return musicVolume;
  },

  getSFXVolume: () => {
    return sfxVolume;
  },
};
export default Audio;
