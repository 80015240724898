import * as PIXI from 'pixi.js';
import Game from 'dt-common/constants/Game.js';
import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import getUnitState from 'dt-common/isomorphic-helpers/getUnitState';
import Audio from '~/Audio';
import EquipmentImgFactory from '~/components/common/EquipmentImgFactory';
import GravitySprite from '~/components/common/GravitySprite';
import Colors from '~/constants/Colors';
import CanvasTools from '~/view/CanvasTools';
import EquipmentDisplay from '../EquipmentDisplay';
import PuppetMovements from './puppet_movements/PuppetMovements';
import PuppetPart from './PuppetPart';

const LIMB_SCALE = 0.82;

const UnitPuppet = function({
  body_scale = 1.0,
  roster_hero,
  unit_build,
  unit_state,
  perma_vamp,
}) {
  PIXI.Container.call(this);

  this.actor = unit_state || getUnitState({ roster_hero, unit_build });

  const unit_handle = unit_state?.handle ?? unit_state?.hero_handle ?? roster_hero?.handle ?? roster_hero?.hero_handle;
  const _equipment = this.actor.equipment;
  const _movements = PuppetMovements(this);
  const _parts = {
    head: new PuppetPart(body_scale),
    shoulders: new PuppetPart(body_scale),
    torsoe: new PuppetPart(body_scale),
    leftHand: new PuppetPart(body_scale),
    rightHand: new PuppetPart(body_scale),
    leftFoot: new PuppetPart(body_scale * LIMB_SCALE),
    rightFoot: new PuppetPart(body_scale * LIMB_SCALE),
    weapon: new PuppetPart(body_scale),
    off_hand: new PuppetPart(body_scale),
    shield: new PuppetPart(body_scale),
  };

  const _faceNorthTextures = {
    head: _equipment.helmet? EquipmentImgFactory.getStandard(_equipment.helmet,Game.NORTH) : PIXI.utils.TextureCache['head_NORTH.png'],
    shoulders: _equipment.torsoe? EquipmentImgFactory.getShoulders(_equipment.torsoe,Game.NORTH) : PIXI.utils.TextureCache['underscore.png'],
    torsoe: EquipmentImgFactory.getStandard(_equipment.torsoe, Game.NORTH),
    leftHand: PIXI.utils.TextureCache['hand_NORTH.png'],
    rightHand: PIXI.utils.TextureCache['hand_NORTH.png'],
    leftFoot: EquipmentImgFactory.getStandard(_equipment.boots, Game.NORTH),
    rightFoot: EquipmentImgFactory.getStandard(_equipment.boots, Game.NORTH),
    weapon: _equipment.weapon? EquipmentImgFactory.getStandard(_equipment.weapon) : null,
    shield: _equipment.off_hand && _equipment.off_hand.type==='shield'? EquipmentImgFactory.getStandard(_equipment.off_hand, Game.NORTH) : null,
    off_hand: _equipment.off_hand && _equipment.off_hand.type !== 'shield'
      ? _equipment.off_hand?.type === 'bow'
        ? EquipmentImgFactory.getStandard(_equipment.off_hand, Game.SOUTH)
        : EquipmentImgFactory.getStandard(_equipment.off_hand)
      : null,
  };

  const _faceSouthTextures = {
    head: _equipment.helmet? EquipmentImgFactory.getStandard(_equipment.helmet,Game.SOUTH) : PIXI.utils.TextureCache['head_SOUTH.png'],
    shoulders: _equipment.torsoe? EquipmentImgFactory.getShoulders(_equipment.torsoe,Game.SOUTH) : PIXI.utils.TextureCache['underscore.png'],
    torsoe: EquipmentImgFactory.getStandard(_equipment.torsoe,Game.SOUTH),
    leftHand: PIXI.utils.TextureCache['hand_SOUTH.png'],
    rightHand: PIXI.utils.TextureCache['hand_SOUTH.png'],
    leftFoot: EquipmentImgFactory.getStandard(_equipment.boots, Game.SOUTH),
    rightFoot: EquipmentImgFactory.getStandard(_equipment.boots, Game.SOUTH),
    weapon: _equipment.weapon? EquipmentImgFactory.getStandard(_equipment.weapon) : null,
    shield: _equipment.off_hand && _equipment.off_hand.type==='shield'? EquipmentImgFactory.getStandard(_equipment.off_hand, Game.SOUTH) : null,
    off_hand: _equipment.off_hand && _equipment.off_hand.type !== 'shield'
      ? _equipment.off_hand?.type === 'bow'
        ? EquipmentImgFactory.getStandard(_equipment.off_hand, Game.SOUTH)
        : EquipmentImgFactory.getStandard(_equipment.off_hand)
      : null,
  };

  const _faceEastTextures = {
    head: _equipment.helmet? EquipmentImgFactory.getStandard(_equipment.helmet,Game.EAST) : PIXI.utils.TextureCache['head_EAST.png'],
    shoulders: _equipment.torsoe? EquipmentImgFactory.getShoulders(_equipment.torsoe,Game.EAST) : PIXI.utils.TextureCache['underscore_short.png'],
    torsoe: EquipmentImgFactory.getStandard(_equipment.torsoe,Game.EAST),
    leftHand: PIXI.utils.TextureCache['hand_SOUTH.png'],
    rightHand: PIXI.utils.TextureCache['hand_SOUTH.png'],
    leftFoot: EquipmentImgFactory.getStandard(_equipment.boots, Game.EAST),
    rightFoot: EquipmentImgFactory.getStandard(_equipment.boots, Game.EAST),
    weapon: _equipment.weapon? EquipmentImgFactory.getStandard(_equipment.weapon) : null,
    shield: _equipment.off_hand && _equipment.off_hand.type==='shield'? EquipmentImgFactory.getStandard(_equipment.off_hand, Game.EAST) : null,
    off_hand: _equipment.off_hand && _equipment.off_hand.type!=='shield'? EquipmentImgFactory.getStandard(_equipment.off_hand) : null,
  };

  this.inTransit = false;
  this.vampEnabled = true;
  this.getCooldown = () => BattleCalc.getCooldown(this.actor);
  this.getFacingDirection = () => _facingDirection;
  this.getMovements = () => _movements;
  this.getParts = () => _parts;

  let _disposed = false;
  let _facingDirection = null;
  let _flippedWest = false;

  this.doNormalTint = function() {
    if (_disposed || !_equipment) {
      return;
    }

    if (_equipment.helmet) {
      _parts.head.tint = EquipmentDisplay.PuppetTint.equipment(_equipment.helmet);
    } else {
      _parts.head.tint = Colors[unit_handle] || 0xffffff;
    }

    _parts.leftHand.tint = _parts.rightHand.tint = Colors[unit_handle] || 0xffffff;

    if (_equipment.torsoe) {
      _parts.shoulders.tint = _parts.torsoe.tint = EquipmentDisplay.PuppetTint.equipment(_equipment.torsoe);
    } else {
      _parts.shoulders.tint = _parts.torsoe.tint = 0xffffff;
    }

    if (_equipment.boots) {
      _parts.leftFoot.tint = _parts.rightFoot.tint = EquipmentDisplay.PuppetTint.equipment(_equipment.boots);
    } else {
      _parts.leftFoot.tint = _parts.rightFoot.tint = 0xffffff;
    }

    if (_equipment.weapon) {
      _parts.weapon.tint = EquipmentDisplay.PuppetTint.equipment(_equipment.weapon);
    }

    if (_equipment.off_hand) {
      if (_equipment.off_hand.type === 'shield') {
        _parts.shield.tint = EquipmentDisplay.PuppetTint.equipment(_equipment.off_hand);
      } else {
        _parts.off_hand.tint = EquipmentDisplay.PuppetTint.equipment(_equipment.off_hand);
      }
    }
  };
  this.doNormalTint();

  const changeTextures = function(textures) {
    _parts.head.texture = textures.head;
    _parts.shoulders.texture = textures.shoulders;
    _parts.torsoe.texture = textures.torsoe;
    _parts.leftHand.texture = textures.leftHand;
    _parts.rightHand.texture = textures.rightHand;
    _parts.leftFoot.texture = textures.leftFoot;
    _parts.rightFoot.texture = textures.rightFoot;

    if (textures.weapon) {
      _parts.weapon.texture = textures.weapon;
    }

    if (textures.off_hand) {
      _parts.off_hand.texture = textures.off_hand;
    }

    if (textures.shield) {
      _parts.shield.texture = textures.shield;
    }
  };

  const flipAllHorizontal = function() {
    CanvasTools.flipHorizontal(_parts.head);
    CanvasTools.flipHorizontal(_parts.shoulders);
    CanvasTools.flipHorizontal(_parts.torsoe);
    CanvasTools.flipHorizontal(_parts.leftHand);
    CanvasTools.flipHorizontal(_parts.rightHand);
    CanvasTools.flipHorizontal(_parts.leftFoot);
    CanvasTools.flipHorizontal(_parts.rightFoot);
    CanvasTools.flipHorizontal(_parts.weapon);
    CanvasTools.flipHorizontal(_parts.shield);
    CanvasTools.flipHorizontal(_parts.off_hand);

    _flippedWest = !_flippedWest;
  };

  this.face = (newDirection) => {
    if (_disposed || _facingDirection === newDirection) {
      return;
    }

    if (newDirection === Game.NORTH) {
      changeTextures(_faceNorthTextures);
      if (_parts.leftHand.scale.x > 0) {
        CanvasTools.flipHorizontal(_parts.leftHand);
        CanvasTools.flipHorizontal(_parts.leftFoot);
      }

      this.addChildAt(_parts.off_hand, 0);
      this.addChildAt(_parts.leftHand, 1);
      this.addChildAt(_parts.shield, 2);

      this.addChildAt(_parts.weapon, 3);
      this.addChildAt(_parts.rightHand, 4);

      this.addChildAt(_parts.leftFoot, 5);
      this.addChildAt(_parts.rightFoot, 6);
      this.addChildAt(_parts.torsoe, 7);
      this.addChildAt(_parts.shoulders, 8);
      this.addChildAt(_parts.head, 9);
    } else if (newDirection === Game.SOUTH) {
      changeTextures(_faceSouthTextures);
      if (_parts.leftHand.scale.x > 0) {
        CanvasTools.flipHorizontal(_parts.leftHand);
        CanvasTools.flipHorizontal(_parts.leftFoot);
      }

      this.addChildAt(_parts.leftFoot, 0);
      this.addChildAt(_parts.rightFoot, 1);
      this.addChildAt(_parts.torsoe, 2);
      this.addChildAt(_parts.shoulders, 3);
      this.addChildAt(_parts.head, 4);

      this.addChildAt(_parts.off_hand, 5);
      this.addChildAt(_parts.leftHand, 6);
      this.addChildAt(_parts.shield, 7);

      this.addChildAt(_parts.weapon, 8);
      this.addChildAt(_parts.rightHand, 9);
    } else if (newDirection === Game.EAST) {
      changeTextures(_faceEastTextures);

      if (_flippedWest)    {
        flipAllHorizontal();
      } else if (_parts.leftHand.scale.x < 0) {
        CanvasTools.flipHorizontal(_parts.leftHand);
        CanvasTools.flipHorizontal(_parts.leftFoot);
      }

      this.addChildAt(_parts.leftFoot, 0);

      this.addChildAt(_parts.shield, 1);
      this.addChildAt(_parts.leftHand, 2);
      this.addChildAt(_parts.off_hand, 3);

      this.addChildAt(_parts.torsoe, 4);
      this.addChildAt(_parts.shoulders, 5);
      this.addChildAt(_parts.head, 6);
      this.addChildAt(_parts.rightFoot, 7);

      this.addChildAt(_parts.weapon, 8);
      this.addChildAt(_parts.rightHand, 9);
    } else if (newDirection === Game.WEST) {
      changeTextures(_faceEastTextures);
      if (_parts.leftHand.scale.x < 0) {
        CanvasTools.flipHorizontal(_parts.leftHand);
        CanvasTools.flipHorizontal(_parts.leftFoot);
      }

      if (!_flippedWest) {
        flipAllHorizontal();
      }

      this.addChildAt(_parts.rightFoot, 0);
      this.addChildAt(_parts.rightHand, 1);
      this.addChildAt(_parts.weapon, 2);

      this.addChildAt(_parts.torsoe, 3);
      this.addChildAt(_parts.shoulders, 4);
      this.addChildAt(_parts.head, 5);
      this.addChildAt(_parts.leftFoot, 6);

      this.addChildAt(_parts.off_hand, 7);
      this.addChildAt(_parts.leftHand, 8);
      this.addChildAt(_parts.shield, 9);
    } else {
      throw new Error('Unhandled facing direction in UnitPuppet::face()');
    }

    _facingDirection = newDirection;
  };

  this.facePiece = (victim) => {
    if (victim.uid === this.actor.uid) {
      return;
    }

    var xDif = Math.abs(victim.x - this.actor.x);
    var yDif = Math.abs(victim.y - this.actor.y);

    if (yDif > xDif) {
      if (victim.y > this.actor.y) {
        this.face(Game.SOUTH);
      } else {
        this.face(Game.NORTH);
      }
    } else {
      if (victim.x > this.actor.x) {
        this.face(Game.EAST);
      }
      else {
        this.face(Game.WEST);
      }
    }
  };

  this.faceReverse = () => {
    switch (_facingDirection) {
    case Game.NORTH: this.face(Game.SOUTH); break;
    case Game.SOUTH: this.face(Game.NORTH); break;
    case Game.EAST: this.face(Game.WEST); break;
    case Game.WEST: this.face(Game.EAST); break;
    }
  };

  this.run = function(direction, transitTimeScale = 1) {
    this.face(direction);
    this.inTransit = true;
    const transitTime = this.getCooldown() * transitTimeScale;
    _movements.run.movePuppet({ transitTime });
    TweenMax.delayedCall(transitTime * 0.25, Audio.play, ['sandstone_step_6']);
    TweenMax.delayedCall(transitTime * 0.85, Audio.play, ['sandstone_step_46']);
  };

  // give initial textures to our body part sprites
  this.face(Game.SOUTH);

  this.hitArea = new PIXI.Rectangle(-this.width/2, -this.height * 0.8, this.width, this.height * 0.9);

  for (var prop in _parts) {
    _parts[prop].anchor.x = _parts[prop].anchor.y = 0.5;
  }

  // scale helmet gear down & adjust anchor
  if (_equipment.helmet) {
    _parts.head.scale.x *= 0.6;
    _parts.head.scale.y = _parts.head.scale.x;
    _parts.head.anchor.y *= 0.5;
  }

  // adjust shoulder anchor
  if (_equipment.torsoe) {
    _parts.shoulders.anchor.y = 0.25;
  }

  // scale boots
  if (_equipment.boots) {
    _parts.rightFoot.scale.x *= 0.7;
    _parts.rightFoot.scale.y *= 0.7;
    _parts.leftFoot.scale.x *= 0.7;
    _parts.leftFoot.scale.y *= 0.7;
  }

  const onTimelineComplete = () => {
    if (_disposed) {
      return;
    }

    // update unit state calculation as conditions, etc. may have changed
    const transitTime = this.getCooldown();
    if (this.inTransit) {
      _movements.run.movePuppet({ transitTime });
      TweenMax.delayedCall(transitTime * 0.25, Audio.play, ['sandstone_step_6']);
      TweenMax.delayedCall(transitTime * 0.85, Audio.play, ['sandstone_step_46']);
    } else if (this.vampEnabled && !this.actor.dead) {
      if (BattleCalc.getCondition(this.actor,'stunned') || perma_vamp === 'stunned') {
        _movements.stunned.movePuppet({ transitTime });
      } else if (BattleCalc.getCondition(this.actor,'blocking')) {
        _movements.shieldBlock.movePuppet({ transitTime });
      } else if (BattleCalc.getCondition(this.actor,'offensive_stance')) {
        _movements.offensiveStance.movePuppet({ transitTime });
      } else if (BattleCalc.getCondition(this.actor,'defensive_stance')) {
        _movements.defensiveStance.movePuppet({ transitTime });
      } else {
        _movements.vampStandard.movePuppet({ transitTime });
      }
    }
  };
  this.timeline = gsap.timeline({
    onComplete: onTimelineComplete.bind(this),
  });

  _movements.vampStandard.setPose(1);

  // the puppet's animation loop
  let _animationFrameRequestId;
  const onAnimationFrame = () => {
    if (_disposed) {
      return;
    }

    _animationFrameRequestId = requestAnimationFrame(onAnimationFrame);

    _parts.weapon.world_x = _parts.rightHand.world_x;
    _parts.weapon.world_y = _parts.rightHand.world_y;
    _parts.weapon.world_z = _parts.rightHand.world_z;
    _parts.off_hand.world_x = _parts.leftHand.world_x;
    _parts.off_hand.world_y = _parts.leftHand.world_y;
    _parts.off_hand.world_z = _parts.leftHand.world_z;
    _parts.shield.world_x = _parts.leftHand.world_x;
    _parts.shield.world_y = _parts.leftHand.world_y;
    _parts.shield.world_z = _parts.leftHand.world_z;

    _parts.rightHand.yaw = _parts.weapon.yaw;
    _parts.rightHand.pitch = _parts.weapon.pitch;
    _parts.leftHand.yaw = _parts.off_hand.yaw;
    _parts.leftHand.pitch = _parts.off_hand.pitch;
    _parts.rightHand.translateYawAndPitch(_facingDirection);
    _parts.leftHand.translateYawAndPitch(_facingDirection);
    _parts.weapon.translateYawAndPitch(_facingDirection);
    _parts.off_hand.translateYawAndPitch(_facingDirection);
    _parts.shield.translateYawAndPitch(_facingDirection);

    for (var prop in _parts) {
      var puppetPart = _parts[ prop ];
      puppetPart.translateWorldCoords(_facingDirection);
    }
  };
  requestAnimationFrame(onAnimationFrame);

  this.transitionIn = ({ type = 'sprint_forward', options = {} } = {}) => {
    switch (type) {
      case 'sprint_forward': {
        if (!options.no_xy_movement) {
          TweenMax.from(this, 0.3, { alpha: 0, ease: Quad.easeIn });
          TweenMax.from(this, 0.3, {
            x: -this.width + Math.random() * this.width * 2,
            y: -this.height * 1.5 + Math.random() * this.height,
            ease: Linear.easeNone,
          });
        }

        TweenMax.from(this.scale, 0.3, {
          x: 0,
          y: 0,
          ease: Quad.easeOut,
        }).delay(Math.random() * 0.3);

        // run animation
        const get_cooldown_buf = this.getCooldown;
        this.getCooldown = () => 0.3 + Math.random() * 0.2;
        this.inTransit = true;
        requestAnimationFrame(() => {
          this.getCooldown = get_cooldown_buf;
          this.inTransit = false;
        });
      }
    }
  };

  this.setTint = function(color, doHead, doHands) {
    _parts.head.tint = color;

    _parts.shoulders.tint = color;
    _parts.torsoe.tint = color;

    if (doHands) {
      _parts.leftHand.tint = color;
      _parts.rightHand.tint = color;
    }

    _parts.leftFoot.tint = color;
    _parts.rightFoot.tint = color;
    _parts.weapon.tint = color;
    _parts.off_hand.tint = color;
    _parts.shield.tint = color;
  };

  this.die = function() {
    cancelAnimationFrame(_animationFrameRequestId);

    this.interactive = false;

    for (var prop in _parts) {
      var part = _parts[ prop ];
      var gSprite = new GravitySprite(part.texture);
      gSprite.init();
      gSprite.interactive = false;
      gSprite.tint = part.tint;
      gSprite.x = part.x;
      gSprite.y = part.y;
      gSprite.scale = part.scale;
      gSprite.floorY = -20 + Math.random()*50;
      gSprite.yVelocity = -4.0 - Math.random()*2.5;
      gSprite.anchor.x = gSprite.anchor.y = 0.5;
      TweenMax.to(gSprite, 1.5+Math.random()*1.0, {
        x: gSprite.x-90 + Math.random()*180,
        rotation: Math.random()*Math.PI*3,
        onComplete: gSprite.dispose
      });
      this.addChild(gSprite);
      this.removeChild(part);
    }
  };

  this.dispose = () => {
    cancelAnimationFrame(_animationFrameRequestId);

    _movements.dispose();

    
    this.timeline.getChildren().forEach(tween => tween.kill());
    this.timeline?.kill();
    this.timeline = null;

    this.actor = null;

    for (const part of Object.values(_parts || {})) {
      gsap.killTweensOf(part);
      part.texture = null;
      part.removeChildren();
      part.parent?.removeChild(part);
    };

    TweenMax.killTweensOf(this);
    this.removeChildren();
    this.parent?.removeChild(this);

    _disposed = true;
  };
};
UnitPuppet.prototype = Object.create(PIXI.Container.prototype);
UnitPuppet.prototype.constructor = UnitPuppet;
export default UnitPuppet;
