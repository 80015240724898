import * as PIXI from 'pixi.js';
import Colors from '~/constants/Colors';
import CanvasTools from '~/view/CanvasTools';

const TutorialSpotlight = function( radius ) {
    PIXI.Container.call( this );

    this.transitionIn = ( onComplete ) => {
    	TweenMax.from( this, 0.2, {alpha:0} );
    	TweenMax.from( this.scale, 0.6, {x:5,y:5,onComplete} );
    };

    this.transitionOut = ( onComplete, onCompleteArg ) => {
    	TweenMax.to( this, 0.2, {alpha:0} );
    	TweenMax.to( this.scale, 0.6, {x:5,y:5,onComplete,onCompleteParams:[onCompleteArg]} );
    };

    this.dispose = () => {
		this.removeChildren();
	}; 

	const COLOR = 0x222222;
	
	var _invertedCircle = new PIXI.Sprite();
  _invertedCircle.texture = PIXI.utils.TextureCache['inverted_circle.png'];
	_invertedCircle.tint = COLOR;
	_invertedCircle.alpha = 0.75;
	_invertedCircle.width = _invertedCircle.height = radius*2;
	_invertedCircle.x = -radius;
	_invertedCircle.y = -radius;
	_invertedCircle.interactive = false;
	_invertedCircle.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
	this.addChild( _invertedCircle );

	var gfx = new PIXI.Graphics();
	gfx.beginFill( COLOR, 0.75 );
	gfx.drawRect( -window.innerWidth, -radius, window.innerWidth*2, -window.innerHeight );
	gfx.endFill();
	gfx.hitArea = new PIXI.Rectangle( -window.innerWidth, -radius, window.innerWidth*2, -window.innerHeight );
	gfx.interactive = true;
	gfx.mousemove = gfx.mousedown = gfx.touchstart = gfx.tap = gfx.click = ( event ) => { event.stopPropagation(); };
	this.addChild( gfx );
	
	gfx.beginFill( COLOR, 0.75 );
	gfx.drawRect( radius, -radius, window.innerWidth, radius*2 );
	gfx.endFill();
	gfx.hitArea = new PIXI.Rectangle( radius, -radius, window.innerWidth, radius*2 );
	gfx.interactive = true;
	gfx.mousemove = gfx.mousedown = gfx.touchstart = gfx.tap = gfx.click = ( event ) => { event.stopPropagation(); };
	this.addChild( gfx );
	
	gfx.beginFill( COLOR, 0.75 );
	gfx.drawRect( -radius, radius, window.innerWidth, window.innerHeight );
	gfx.endFill();
	gfx.hitArea = new PIXI.Rectangle( -radius, radius, window.innerWidth, window.innerHeight );
	gfx.interactive = true;
	gfx.mousemove = gfx.mousedown = gfx.touchstart = gfx.tap = gfx.click = ( event ) => { event.stopPropagation(); };
	this.addChild( gfx );
	
	gfx.beginFill( COLOR, 0.75 );
	gfx.drawRect( -radius, -radius, -window.innerWidth, window.innerHeight );
	gfx.endFill();
	gfx.hitArea = new PIXI.Rectangle( -radius, -radius, -window.innerWidth, window.innerHeight );
	gfx.interactive = true;
	gfx.mousemove = gfx.mousedown = gfx.touchstart = gfx.tap = gfx.click = ( event ) => { event.stopPropagation(); };
	this.addChild( gfx );

	this.interactive = true;
	this.mousemove = this.mousedown = this.touchstart = this.tap = this.click = ( event ) => { event.stopPropagation(); };
};
TutorialSpotlight.prototype = Object.create(PIXI.Container.prototype);
TutorialSpotlight.prototype.constructor = TutorialSpotlight;
export default TutorialSpotlight;