import * as PIXI from 'pixi.js';
import { EventEmitter } from 'events';
import Balance from 'dt-common/constants/Balance';
import XPBar from '~/components/common/XPBar';

const AnimatedLevelBar = function({ roster_hero, hero_before_battle }) {
  PIXI.Container.call(this);

  this.eventEmitter = new EventEmitter();

  this.xpDummy = hero_before_battle.xp;
  this.levelDummy = hero_before_battle.level;
  this.xpDummyCieling = Balance.getFibonacci(this.levelDummy + 1) - Balance.getFibonacci(this.levelDummy);

  TweenMax.delayedCall(1.5, TweenMax.to,
    [this, 3.0, {
      xpDummy: roster_hero.xp,
      onUpdate: ()=> {
        var levelProgress = Math.round(this.xpDummy - Balance.getFibonacci(this.levelDummy));
        this.xpBar.update(levelProgress, this.xpDummyCieling);
        if (levelProgress >= this.xpDummyCieling) {
          ++this.levelDummy;
          this.xpDummyCieling = Balance.getFibonacci(this.levelDummy - 1);

          this.eventEmitter.emit('ALB_SHOW_LEVEL_UP', {
            heroHandle: roster_hero.handle,
            levelShown: this.levelDummy,
          });
        }
      },
    },
    ]);

  var xpBar = this.xpBar = new XPBar({ roster_hero });
  xpBar.update(Math.round(this.xpDummy-Balance.getFibonacci(this.levelDummy)), Balance.getFibonacci(this.levelDummy-1));
  this.addChild(xpBar);
};
AnimatedLevelBar.prototype = Object.create(PIXI.Container.prototype);
AnimatedLevelBar.prototype.constructor = AnimatedLevelBar;
export default AnimatedLevelBar;
