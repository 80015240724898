import heroes from './heroes';
import ui from './ui';

const chat = {
  help_msg: 'Type /help to see a list of chat commands.',
  make_a_username: 'You must have a username before sending chat messages (click \'New Player\' in the upper-left).',

  help_info: [
    'CHAT COMMANDS:',
    '/challenge <player name> - Sets up a match between you and the specified player (in your most recent match type).',
    '/color - Change the color of your messages in chat.',
    '/emotes - List all your available emotes.',
    '/gender <subject>/<object>/<possessive> - Set the gender of your emote pronouns. You can also use the shortcuts \'/gender male\' or \'/gender female\'',
    '/online - See who\'s online with you.',
  ],
  players_online: 'Players online',
  your_emotes: 'YOUR EMOTES:',
  gender_changed: ({ subject, object, possessive }) => {
    return `Your gender pronouns have been changed to: ${subject}/${object}/${possessive}`;
  },
  male: 'male',
  female: 'female',

  notifications: {
    hero_unlocked: (userName, data) => {
      return userName + ' has unlocked the ' + heroes[data.heroHandle].name + '!';
    },

    hero_level_up: (userName, data) => {
      return userName + '\'s ' + heroes[data.heroHandle].name + ' just reached level ' + data.level + '!';
    },

    prayer_slot_unlocked: (userName, data) => {
      return  userName + ' has unlocked Prayer Slot #' + data.numPrayerSlots + '!';
    },

    altar_level_up: (userName, data) => {
      return 'Praise be the gods! ' + userName + '\'s altar just reached level ' + data.level + '!';
    },

    matchmaking_queue: ({ player_display_name, game_submode }) => `${player_display_name} just entered the queue for Arena ${ui.game_submodes[game_submode]}.`
  },

  emotes: {
    enter_channel: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      return `${userName} enters the room.`;
    },

    smile: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return `${actor.displayName} smiles.`;
      } else if (actor.displayName === target_displayName) {
        return userName + ' smiles inwardly.';
      } else {
        return userName + ' smiles at ' + target_displayName + '.';
      }
    },

    lol: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' laughs out loud!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' tries to contain a burst of laughter, but fails.';
      } else {
        return userName + ' laughs out loud at ' + target_displayName + '.';
      }
    },

    nod: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' nods emphatically.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' nods off to sleep...';
      } else {
        return userName + ' nods at ' + target_displayName + '.';
      }
    },

    headshake: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' shakes ' + possessive + ' head.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' curses under ' + possessive + ' breath, shaking ' + possessive + ' head.';
      } else {
        return userName + ' glances at ' + target_displayName + ' and shakes ' + possessive + ' head.';
      }
    },

    wave: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' waves hello.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' waves goodbye.';
      } else {
        return userName + ' waves at ' + target_displayName + '.';
      }
    },

    bow: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' bows politely.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' bows low to the ground.';
      } else {
        return userName + ' bows to ' + target_displayName + '.';
      }
    },

    dance: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' dances a little jig!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' dances like no one\'s watching.';
      } else {
        return userName + ' dances a circle around ' + target_displayName + '.';
      }
    },

    eyeroll: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' rolls ' + possessive + ' eyes in disgust.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' appears to be having a seizure.';
      } else {
        return userName + ' rolls ' + possessive + ' eyes at ' + target_displayName + '.';
      }
    },

    glare: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' glares.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' crosses ' + possessive + ' eyes.' ;
      } else {
        return userName + ' glares at ' + target_displayName + '.';
      }
    },

    snicker: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' snickers. What\'s so funny?';
      } else if (actor.displayName === target_displayName) {
        return userName + ' laughs so hard a snorting sound comes out.';
      } else {
        return userName + ' snickers at ' + target_displayName + '.';
      }
    },

    giggle: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' giggles like a little child.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' can\'t stop giggling!';
      } else {
        return userName + ' giggles at ' + target_displayName + '.';
      }
    },

    yawn: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' yawns.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' lets out a massive yawn.';
      } else {
        return userName + ' can\'t bear listening to ' + target_displayName + ' anymore, and lets out a yawn.';
      }
    },

    cry: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' begins to cry.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' weeps uncontrollably.';
      } else {
        return userName + ' flashes ' + target_displayName + ' a pained look, and lets out a sob.';
      }
    },

    cheer: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' cheers - hooray!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' beams proudly.';
      } else {
        return userName + ' cheers at ' + target_displayName + '!';
      }
    },

    scream: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' screams!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' throws back ' + possessive + ' shoulders and wails to the heavens!';
      } else {
        return userName + ' screams at ' + target_displayName + '!';
      }
    },

    cower: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' cowers.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' crumples into a little ball on the ground.';
      } else {
        return userName + ' cowers from ' + target_displayName + '.';
      }
    },

    cackle: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' cackles like a lunatic!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' cackles uncontrollably, and falls into a coughing fit...';
      } else {
        return userName + ' cackles madly at ' + target_displayName + '!';
      }
    },

    sigh: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' lets out a sigh.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' heaves a sigh of relief.';
      } else {
        return userName + ' gives ' + target_displayName + ' a sad look and sighs.';
      }
    },

    tap: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' begins tapping ' + possessive + ' feet.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' taps a finger to ' + possessive + ' forehead knowingly.';
      } else {
        return userName + ' taps ' + target_displayName + ' on the shoulder.';
      }
    },

    poke: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' seems to want to poke someone.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' pokes the ground with ' + possessive + ' toe. Awww shucks!';
      } else {
        return userName + ' pokes ' + target_displayName + ' in the ribs.';
      }
    },

    laugh: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' laughs!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' laughs at ' + `${object}self` + '.';
      } else {
        return userName + ' laughs at ' + target_displayName + '!';
      }
    },

    hug: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' folds ' + possessive + ' arms.';
      } else if (actor.displayName === target_displayName) {
        return 'Some people from the asylum come and carry ' + userName + ' off in a straight jacket!';
      } else {
        return userName + ' hugs ' + target_displayName + '. Awwwww...';
      }
    },

    taunt: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' offers to fight anyone.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' seems to be inwardly angry about something.';
      } else {
        return userName + ' taunts ' + target_displayName + ' with a rude gesture.';
      }
    },

    flex: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' flexes ' + possessive + ' muscles!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' stretches out like a cat waking up from a nap.';
      } else {
        return userName + ' makes a showy display of muscles, for ' + target_displayName + '\'s benefit.';
      }
    },

    grit: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + '\'s teeth start gritting together.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' looks to be in serious pain.';
      } else {
        return userName + ' grits ' + possessive + ' teeth at ' + target_displayName + '.';
      }
    },

    point: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' points up to the sky.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' points at ' + `${object}self` + '.';
      } else {
        return userName + ' points at ' + target_displayName + '.';
      }
    },

    scratch: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' scratches a line in the ground.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' scratches ' + possessive + ' head.';
      } else {
        return userName + ' scratches ' + target_displayName + '\'s back.';
      }
    },

    shrug: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' shrugs.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' shrugs. Who knows?';
      } else {
        return userName + ' looks at ' + target_displayName + ' and shrugs.';
      }
    },

    glance: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' glances at nothing in particular.';
      } else if (actor.displayName === target_displayName) {
        return userName + '\'s eyes flinch.';
      } else {
        return userName + ' glances at ' + target_displayName + '.';
      }
    },

    clap: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' claps loudly!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' claps a hand to ' + possessive + ' forehead.';
      } else {
        return userName + ' claps for ' + target_displayName + '!';
      }
    },

    applaud: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' applauds!';
      } else if (actor.displayName === target_displayName) {
        return userName + ' congratulates ' + `${object}self` + '.';
      } else {
        return userName + ' calls for a round of applause for ' + target_displayName + '!';
      }
    },

    wink: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      if (!target_displayName) {
        return userName + ' winks.';
      } else if (actor.displayName === target_displayName) {
        return userName + ' seems to have something in ' + possessive + ' eye.';
      } else {
        return userName + ' winks at ' + target_displayName + '.';
      }
    },


    ban: (actor, target_displayName) => {
      const userName = actor.displayName;
      const { subject='they', object='them', possessive='their' } = actor.gender || {};

      return 'The Manners Dragon swoops down from the sky and swallows '+target_displayName+' whole.';
    },
  },
};
export default chat;
