import { EventEmitter } from 'events'

import PlayerDispatcher from '~/flux/dispatchers/PlayerDispatcher'
import PaymentsDispatcher from '~/flux/dispatchers/PaymentsDispatcher'
import { awaitSocket, registerDispatchHandlers } from '~/Tools'

let _socket
let playerId

// the stuff we serve:
let card_declined_data
let payment_succeeded_data
let payment_requires_action_data
const stripe = Stripe(process.env.STRIPE_API_KEY)

const getLoggedInPlayer = () => all_players.find(p => p._id === loggedInPlayerId)

const PaymentsStore = Object.assign({}, EventEmitter.prototype, {
  PAYMENT_SUCCEEDED: 'PAYMENT_SUCCEEDED',
  CARD_DECLINED: 'CARD_DECLINED',
  PAYMENT_REQUIRES_ACTION: 'PAYMENT_REQUIRES_ACTION',
  PAYMENT_ERROR: 'PAYMENT_ERROR',

  getAll() {
    return {
      payment_succeeded_data,
      card_declined_data,
      payment_requires_action_data
    }
  }
})
export default PaymentsStore

PlayerDispatcher.register(registerDispatchHandlers({
  [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn
}))
PaymentsDispatcher.register(registerDispatchHandlers({
  [PaymentsDispatcher.CREATE_PAYMENT_INTENT]: requestNewPaymentIntent
}))

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  try {
    _socket = socket

    if (!_socket.has_PaymentsStore_listeners) {
      _socket.on('card_declined', onCardDeclined)
      _socket.on('payment_requires_action', onPaymentRequiresAction)
      _socket.on('payment_succeeded', onPaymentSucceeded)
      _socket.on('payment_error', onPaymentError)

      _socket.has_PaymentsStore_listeners = true
    }
  } catch (err) {
    logError(err, {
      module: 'PlayerStore',
      func: 'onSocketConnected'
    })
  }
}

function onPlayerLoggedIn(action) {
  try {
    playerId = action.player._id
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'onPlayerLoggedIn',
      action
    })
  }
}

function requestNewPaymentIntent(action) {
  const { purchase_selection, payment_method_id } = action
  _socket.emit('createPaymentIntent', { playerId, purchase_selection, payment_method_id })
}

function onCardDeclined(data) {
  try {
    if (data.playerId !== playerId) {
      throw new Error('got card_declined msg for wrong playerId')
    }

    card_declined_data = data
    PaymentsStore.emit(PaymentsStore.CARD_DECLINED, data)
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'onCardDeclined',
      data
    })
  }
}

function onPaymentRequiresAction(data) {
  try {
    if (data.playerId !== playerId) {
      throw new Error('got payment_requires_action msg for wrong playerId')
    }

    payment_requires_action_data = data
    PaymentsStore.emit(PaymentsStore.PAYMENT_REQUIRES_ACTION, data)
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'onPaymentRequiresAction',
      data
    })
  }
}

function onPaymentSucceeded(data) {
  try {
    if (data.playerId !== playerId) {
      throw new Error('got payment_succeeded msg for wrong playerId')
    }

    payment_succeeded_data = data
    PaymentsStore.emit(PaymentsStore.PAYMENT_SUCCEEDED, data)
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'onPaymentSucceeded',
      data
    })
  }
}
function onPaymentError(data) {
  try {
    if (data.playerId !== playerId) {
      throw new Error('got payment_error msg for wrong playerId')
    }

    PaymentsStore.emit(PaymentsStore.PAYMENT_ERROR)
  } catch (err) {
    logError(err, {
      module: 'PaymentsStore',
      func: 'onPaymentSucceeded',
      data
    })
  }
}
