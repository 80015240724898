import VoidSpikeEffect from '../../../effects/VoidSpikeEffect';
import Audio from '~/Audio';

const VoidSpikeRendering = {
  render: function(data, gameBoard, isCaverns) {
    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const victimSprite = gameBoard.getAllPieceSprites()[data.victimId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    if (!data.reflection && render_movement) {
      actorSprite.puppet.getMovements().magicProjectile.movePuppet({ transitTime });
    }

    TweenMax.delayedCall(transitTime*0.31, Audio.play, ['void_spike_03']);

    TweenMax.delayedCall(transitTime*0.5, ()=> {
      var effect = new VoidSpikeEffect(700);
      effect.rotation = Math.random()*2*Math.PI;
      effect.alpha = 0.6;
      effect.x = -8 + Math.random()*16;
      effect.y = -6 - Math.random()*16;
      victimSprite.addChild(effect);

      TweenMax.from(effect.scale, 0.45, { x: 0, y: 0, ease: Elastic.easeOut, onComplete: shrink, onCompleteParams: [effect] });

      function shrink(effect) {
        TweenMax.to(effect.scale, 0.25, { x: 0, y: 0, ease: Expo.easeIn });
      }
    });
  },
};
export default VoidSpikeRendering;