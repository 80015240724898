import * as PIXI from 'pixi.js';
import { ObjectPool } from '~/Tools'

const TILE_WIDTH = 15;
const TILE_HEIGHT = TILE_WIDTH  * 1.61803398875; // golden ratio
const STYLE = {
  fontFamily: 'Courier New',
  fontSize: 20,
  fill: 0xbbbbbb
}

let txt
const _txtPool = new ObjectPool(
  // create func
  () => {
    txt = new PIXI.Text('.', { ...STYLE })
    txt.anchor.x = txt.anchor.y = 0.5;
    txt.interactive = false;
    txt.interactiveChildren = false;
    return txt;
  },

  // reset func
  (txt) => {
    gsap.killTweensOf(txt);
    txt.removeChildren();
    txt.parent?.removeChild(txt);
    txt.x = 0;
    txt.y = 0;
    txt.visible = true;
    txt.alpha = 1;
    txt.text = '.';
    txt.style = { ...STYLE };
    txt.scale = { x: 1, y: 1 };
    txt.tint = 0xffffff;
    txt.anchor.x = txt.anchor.y = 0.5;
    txt.interactive = false;
    txt.interactiveChildren = false;
    txt.buttonMode = false;
    txt.mouseover = txt.mouseout = txt.touch = null;
    txt.hitArea = null;
  },

  // starting num
  500
)

const CavernsTileSprite = function(gamePiece) { 
  PIXI.Container.call(this);

  this.gamePiece = gamePiece;

  this.tileImg = _txtPool.get();
  this.tileImg.text = '.';
  this.txtPool = _txtPool;
  this.addChild(this.tileImg);

  const getStageX = board_x => Math.round(board_x * TILE_WIDTH * 1.5 + window.innerWidth * 0.019);
  const getStageY = board_y =>  Math.round(board_y * TILE_HEIGHT * 0.75 + DT_CANVAS_GLOBALS.spacing * 3);

  this.snapToBoardPosition = (board_x, board_y) => {
    this.x = getStageX(board_x)
    this.y = getStageY(board_y)
  };

  this.tweenToBoardPosition = (board_x, board_y, duration) => {			
    gsap.killTweensOf(this);
    TweenMax.to(this, duration, {
      x: getStageX(board_x, board_y), 
      y: getStageY(board_y),
      ease: Linear.easeNone
    });
  };
  
  this.dispose = () => {
    if (this.disposed) {
      return;
    }
    gsap.killTweensOf(this);
    gamePiece = null;

    if (this.lootImg) {
      _txtPool.put(this.lootImg);
      this.lootImg = null;
    }
    
    _txtPool.put(this.tileImg);

    this.removeChildren();
    this.parent?.removeChild(this);

    typeof this.disposeAsUnitSprite == 'function' && this.disposeAsUnitSprite();

    this.disposed = true;
  }
}
CavernsTileSprite.prototype = Object.create(PIXI.Container.prototype);
CavernsTileSprite.prototype.constructor = CavernsTileSprite;
export default CavernsTileSprite;
