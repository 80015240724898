import * as PIXI from 'pixi.js';
import CanvasTools from '~/view/CanvasTools';
import Colors from '~/constants/Colors';

const AbilityAspectField = function( labelText, valueText, calcText, color=0xffffff ) {
    PIXI.Container.call(this);
	
	var _labelField = new PIXI.Text( labelText, {
		fontFamily: 'Courier New',
		fontSize: CanvasTools.dynamicFontSizeString(12),
		fill: 0xffffff,
		align: 'right',
		lineHeight: window.innerWidth>800? 13 : 9,
		padding: 2
	});

	var _valueField = new PIXI.Text( valueText, {
		fontFamily: 'Courier New',
		fontSize: CanvasTools.dynamicFontSizeString(12),
		fill: color,
		lineHeight: window.innerWidth>800? 13 : 9,
		padding: 2
	});
	
	this.addChild( _labelField );

	_valueField.x = _labelField.x + _labelField.width;
	this.addChild( _valueField );

	if( calcText ) {
		var calc = this.calcField = new PIXI.Text( calcText, {
			fontFamily: 'Courier New',
			fontSize: CanvasTools.dynamicFontSizeString(9),
			fill: 0x999999,
			lineHeight: window.innerWidth>800? 9 : 7,
			padding: 2
		});

		calc.x = _labelField.x + 10;
		calc.y = _labelField.y + _labelField.height;
		this.addChild( calc );
	}

	this.dispose = () => {
		this.removeChildren();

		_labelField = null;
		_valueField = null;
		this.calcField = null;
	}
}

AbilityAspectField.prototype = Object.create( PIXI.Container.prototype );
AbilityAspectField.prototype.constructor = AbilityAspectField;
export default AbilityAspectField;