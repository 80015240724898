import firebase from 'firebase/compat/app';
import * as PIXI from 'pixi.js';
import Economy from 'dt-common/constants/Economy';
import getAbilityLevel from 'dt-common/isomorphic-helpers/getAbilityLevel';
import HeroBase from 'dt-common/models/game_state/heroes';
import AbilityTreeModels from 'dt-common/models/game_state/ability_trees';
import Audio from '~/Audio';
import TutorialBox from '~/components/common/TutorialBox';
import TutorialSpotlight from '~/components/common/TutorialSpotlight';
import { EditHeroActions } from '~/flux/actions';
import {
  CurrencyStore,
  FluxGetters,
  HeroBuildStore,
  TutorialStore,
  UIStore
} from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';
import AbilityTreePanelCarriage from './AbilityTreePanelCarriage';
import EquippedAbilityPanel from './EquippedAbilityPanel';
import AbilityPointsPanel from './AbilityPointsPanel';

const EQUIP_BTN_SIZE = 60;

const EditAbilities = function() {
  PIXI.Container.call(this);

  let _treePanelCarriage;
  let _equippedAbilityPanel;
  let _foregroundPanelIndex;
  let _tweenCanvasElements = true;
  let _hero = FluxGetters.getFocusedHeroBuild().built_hero;
  const abilityTrees = HeroBase[_hero.hero_handle].getAbilityTrees();
  let _abilityPointsPanel;
  let _equipBtn;
  let _unequipBtn;
  let _selected_ability;
  let _backfill;

  this.transitionIn = (duration) => {
    TweenMax.from(this, duration, {
      x: window.innerWidth,
    });
  };

  this.transitionOut = (duration, onComplete) => {
    _selected_ability = null;

    TweenMax.to(this, duration, {
      x: -window.innerWidth,
      onComplete,
    });
  };

  this.dispose = () => {
    try {
      TweenMax.killTweensOf(this);
      DT_CANVAS_GLOBALS.tooltips_disabled = false;

      HeroBuildStore.removeListener(HeroBuildStore.ABILITY_CHANGE, onAbilityChange);
      HeroBuildStore.removeListener(HeroBuildStore.RESET_ABILITIES_REQUESTED, onResetAbilitiesRequested);

      this.hidePopups();
      destroyTreePanelCarriage();
      destroyEquippedAbilityPanel();
      destroyAbilityPointsPanel();

      CanvasTools.hideFaerieSpinners(this.equip_icon_spinners);
      CanvasTools.hideFaerieSpinners(this.unequip_icon_spinners);
      CanvasTools.hideFaerieSpinners(this.empty_slot_spinners);

      _backfill?.destroy();
      this.destroy();

      spotlight?.destroy();
      cycleTip?.destroy();
      abilityInfoTip?.destroy();
      finalTip?.destroy();
    } catch (err) {
      logError(err, {
        module: 'components/ui_screens/edit_hero/edit_abilities/EditAbilities',
        func: 'dispose',
      });
    }
  };

  this.hidePopups = () => {
    hideEquipBtn();
    hideUnequipBtn();
  };

  const addEquipBtn = (event) => {
    var btn = _equipBtn = new PIXI.Sprite();
    btn.texture = PIXI.utils.TextureCache['armory/equip_arrow.png'];
    btn.anchor.x = btn.anchor.y = 0.5;

    btn.width = EQUIP_BTN_SIZE;
    btn.scale.y = btn.scale.x;

    var equipText = new PIXI.Text(text('ui.equip'), {
      fontFamily: 'Courier New',
      fontWeight: 'bold',
      fontSize: '48px',
      fill: 0x00ff00,
      dropShadow: true,
      dropShadowDistance: 4,
      align: 'center',
    });
    equipText.anchor.x = equipText.anchor.y = 0.5;
    btn.addChild(equipText);

    TweenMax.from(btn.scale, 0.1, { x: 0, y: 0 });

    event.target.addChild(btn);

    btn.interactive = btn.buttonMode = true;
    btn.tap = btn.click = (event) => {
      try {
        event.stopPropagation();
        this.hidePopups();

        const { built_hero, hero_handle } = FluxGetters.getFocusedHeroBuild();
        EditHeroActions.equipAbility({
          ability_handle: _selected_ability.handle,
          hero_handle,
          hero_build_id: built_hero._id,
        });

        const empty_slot_icon = _equippedAbilityPanel.getIcons().find(icon => !icon.getAbility());
        if (empty_slot_icon) {
          CanvasTools.hideFaerieSpinners(this.equip_icon_spinners);
          this.equip_icon_spinners = CanvasTools.makeFaerieSpinners(event.target);

          CanvasTools.hideFaerieSpinners(this.empty_slot_spinners);
          this.empty_slot_spinners = CanvasTools.makeFaerieSpinners(empty_slot_icon);
        }
      } catch (err) {
        logError(err, {
          module: 'components/ui_screens/edit_hero/edit_abilities/EditAbilities',
          func: 'equipBtn.click',
        });
      }
    };
  };

  const hideEquipBtn = () => {
    if (!_equipBtn) {
      return;
    }

    TweenMax.to(_equipBtn.scale, 0.15, {
      x: 0,
      y: 0,
      onComplete: destroyBtn,
      onCompleteParams: [_equipBtn],
    });
  };

  const addUnequipBtn = (event) => {
    var btn = _unequipBtn = new PIXI.Sprite();
    btn.texture = PIXI.utils.TextureCache['armory/equip_arrow.png'];
    btn.anchor.x = btn.anchor.y = 0.5;

    btn.width = EQUIP_BTN_SIZE;
    btn.scale.y = btn.scale.x;

    // flip it horizontally, so it points right
    btn.scale.y *= -1;

    var unequipText = new PIXI.Text(text('ui.unequip'), {
      fontFamily: 'Courier New',
      fontWeight: 'bold',
      fontSize: '48px',
      fill: 0xff0000,
      dropShadow: true,
      dropShadowDistance: 4,
      align: 'center',
    });
    unequipText.anchor.x = unequipText.anchor.y = 0.5;
    btn.addChild(unequipText);

    // apparently we have to flip this too
    unequipText.scale.y *= -1;

    TweenMax.from(btn.scale, 0.1, { x: 0, y: 0 });

    event.target.addChild(btn);
    event.target.parent.addChild(event.target);

    btn.interactive = true;
    btn.buttonMode = true;
    btn.tap = btn.click = (event) => {
      event.stopPropagation();
      this.hidePopups();

      if (_selected_ability) {
        EditHeroActions.unequipAbility({
          ability_handle: _selected_ability.handle,
          hero_handle: UIStore.getAll().focused_hero_handle,
          hero_build_id: FluxGetters.getFocusedHeroBuild().built_hero._id,
        });
      }

      CanvasTools.hideFaerieSpinners(this.unequip_icon_spinners);
      this.unequip_icon_spinners = CanvasTools.makeFaerieSpinners(event.target);
    };
  };

  const hideUnequipBtn = () => {
    if (!_unequipBtn) {
      return;
    }

    TweenMax.to(_unequipBtn.scale, 0.15, {
      x: 0,
      y: 0,
      onComplete: this.destroyBtn,
      onCompleteParams: [_equipBtn],
    });
  };

  const destroyBtn = (deadBtn) => {
    if (deadBtn && deadBtn.parent) {
      deadBtn.tap = deadBtn.click = null;
      deadBtn.parent.removeChild(deadBtn);
    }
  };

  const onAbilityIconTouch = (event) => {
    try {
      event.stopPropagation();
      this.hidePopups();

      const ability =  event.target.getAbility();
      _selected_ability = ability;

      const ability_tree_model = Object.values(AbilityTreeModels).find(({ abilities }) => !!abilities[ability.handle])
      const is_passive_ability = !!ability_tree_model.abilities[ability.handle].passive;

      const { built_hero } = FluxGetters.getFocusedHeroBuild();
      const current_ability_level = getAbilityLevel({
        ability_handle: ability.handle,
        unit_build: built_hero,
      });

      if (
        current_ability_level > 0
        && !is_passive_ability
        && !built_hero.equipped_abilities.includes(ability.handle)
      ) {
        addEquipBtn(event);
      }
    } catch (err) {
      logError(err, {
        module: 'components/ui_screens/edit_hero/edit_abilities/EditAbilities',
        func: 'onAbilityIconTouch',
      });
    }
  };

  const onEquippedAbilityIconTouch = (event) => {
    event.stopPropagation();

    this.hidePopups();
    // EditHeroActions.selectAbility(event.target.getAbility());
    _selected_ability = event.target.getAbility();
    addUnequipBtn(event);
  };

  const makeTreePanelCarriage = () => {
    if (_treePanelCarriage) {
      _foregroundPanelIndex = _treePanelCarriage.foregroundPanelIndex;
      destroyTreePanelCarriage();
    }

    var tpc = _treePanelCarriage = new AbilityTreePanelCarriage({
      hero_handle: UIStore.getAll().focused_hero_handle,
      hidePopupsFunc: hideEquipBtn,
      doTweening: _tweenCanvasElements,
      foregroundIndex: _foregroundPanelIndex,
    });
    tpc.height = Math.min(tpc.height, window.innerHeight*0.34);
    tpc.scale.x = tpc.scale.y;
    tpc.x = Math.round(window.innerWidth/2);
    tpc.y = Math.round(window.innerHeight*0.29);
    if (!_tweenCanvasElements) {
      for (var i = 0; i < tpc.getTreePanels().length; ++i) {
        tpc.getTreePanels()[i].updatePinwheelObjects();
      }
    }
    this.addChild(tpc);

    var panels = tpc.getTreePanels();
    for (var i = 0; i < panels.length; ++i) {
      var elements = panels[i].abilityElements;
      for (var j = 0; j < elements.length; ++j) {
        var icon = elements[j].getIcon();
        icon.tap = icon.click = onAbilityIconTouch;
      }
    }
  };

  const makeEquippedAbilityPanel = () => {
    if (_equippedAbilityPanel) {
      destroyEquippedAbilityPanel();
    }

    var eap = _equippedAbilityPanel = new EquippedAbilityPanel(_hero);
    eap.height = Math.min(eap.height, window.innerHeight*0.09);
    eap.scale.x = eap.scale.y;
    eap.x = window.innerWidth / 2 - eap.width / 2;
    eap.y = window.innerHeight * 0.48;
    this.addChild(eap);

    for (var i = 0; i < eap.getIcons().length; ++i) {
      var icon = eap.getIcons()[i];
      icon.tap = icon.click = onEquippedAbilityIconTouch;
    }
  };

  const makeAbilityPointsPanel = () => {
    if (_abilityPointsPanel) {
      destroyAbilityPointsPanel();
    }

    _abilityPointsPanel = new AbilityPointsPanel();
    _abilityPointsPanel.x = Math.round(window.innerWidth * 0.24 - _abilityPointsPanel.width / 2);
    _abilityPointsPanel.y = Math.round(window.innerHeight * 0.465 - _abilityPointsPanel.height / 2);
    this.addChild(_abilityPointsPanel);
  };

  const onAbilityChange = () => {
    var heroBuffer = _hero;
    _hero = FluxGetters.getFocusedHeroBuild().built_hero;

    // check for ability level-ups & play a sound
    // for (let i = 0; i < abilityTrees.length; ++i) {
    //   for (var prop in abilityTrees[i].abilities) {
    //     if (abilityTrees[i].abilities[prop].level > heroBuffer.abilityTrees[i].abilities[prop].level) {
    //       Audio.play('star_twinkle');
    //       break;
    //     }
    //   }
    // }

    // check for newly-equipped/unequipped ability & play a sound
    for (let i = 0; i < _hero.equipped_abilities.length; ++i) {
      if (
        (_hero.equipped_abilities[i] && !heroBuffer.equipped_abilities[i]) ||
              (!_hero.equipped_abilities[i] && heroBuffer.equipped_abilities[i])
      ) {
        Audio.play('star_twinkle');
        Audio.play('click');
        break;
      }
    }

    // check for reset && play a sound
    // let allLevelZero = true;
    // for (let i = 0; i < abilityTrees.length; ++i) {
    //   if (abilityTrees[i].handle === 'standard') {
    //     continue;
    //   }

    //   for (let prop in abilityTrees[i].abilities) {
    //     if (abilityTrees[i].abilities[prop].level > 0) {
    //       allLevelZero = false;
    //       break;
    //     }
    //   }

    //   if (!allLevelZero) {
    //     break;
    //   }
    // }

    // if (allLevelZero) {
    //   Audio.play('electric_smash');
    // }

    makeTreePanelCarriage();
    makeEquippedAbilityPanel();
    makeAbilityPointsPanel();

    if (abilityInfoTip) {
      TweenMax.to(abilityInfoTip, 0.5, {
        alpha: 0,
        onComplete: () => {
          try {
            abilityInfoTip.dispose();
            DT_CANVAS_GLOBALS.stage.removeChild(abilityInfoTip);
            abilityInfoTip = null;

            finalTip = new TutorialBox(text('tutorial.final_tip'));
            finalTip.x = Math.round(window.innerWidth/2 - finalTip.width/2);
            finalTip.y = Math.round(window.innerHeight*0.6);
            TweenMax.from(finalTip, 0.5, { alpha: 0 });
            DT_CANVAS_GLOBALS.stage.addChild(finalTip);

            $('#chat_root').css('visibility', 'visible');

            DT_CANVAS_GLOBALS.tooltips_disabled = false;
          } catch (err) {
            logError(err, {
              module: 'components/ui_screens/edit_hero/edit_abilities/EditAbilities',
              func: 'showFinalTutorialTip',
            });
          }
        },
      });
    }
  };

  const destroyTreePanelCarriage = () => {
    _treePanelCarriage?.dispose();
    _treePanelCarriage = null;
  };

  const destroyEquippedAbilityPanel = () => {
    _equippedAbilityPanel?.dispose();
    _equippedAbilityPanel = null;
  };

  const destroyAbilityPointsPanel = () => {
    TweenMax.killTweensOf(_abilityPointsPanel);
    this.removeChild(_abilityPointsPanel);
    _abilityPointsPanel.dispose();
    _abilityPointsPanel = null;
  };

  const onTreePanelsTweened = () => {
    try {
      if (_treePanelCarriage) {
        _treePanelCarriage.eventEmitter.removeListener(AbilityTreePanelCarriage.TREE_PANELS_TWEEENED, onTreePanelsTweened);
      }

      if (spotlight) {
        spotlight.transitionOut((deadSpotlight) => {
          DT_CANVAS_GLOBALS.stage.removeChild(deadSpotlight);
        }, spotlight);
      }

      if (cycleTip) {
        TweenMax.to(cycleTip, 0.5, {
          alpha: 0,
          onComplete: () => {
            cycleTip.dispose();
            DT_CANVAS_GLOBALS.stage.removeChild(cycleTip);
            cycleTip = null;
          },
        });
      }

      var abilitiesBtnPos;

      TweenMax.delayedCall(1.5, () => {
        try {
          if (_treePanelCarriage) {
            var centerPanelPos = _treePanelCarriage.centerPanel.toGlobal({ x: 0, y: 0 });

            spotlight = new TutorialSpotlight(64);
            spotlight.x = centerPanelPos.x;
            spotlight.y = centerPanelPos.y;
            spotlight.transitionIn();
            DT_CANVAS_GLOBALS.stage.addChild(spotlight);
          }
        } catch (err) {
          logError(err, {
            module: 'components/ui_screens/edit_hero/edit_abilities/EditAbilities',
            func: 'onTreePanelsTweened.delay_1',
          });
        }
      });

      TweenMax.delayedCall(1.6, () => {
        try {
          abilityInfoTip = new TutorialBox(text('tutorial.get_ability_info'), 'up');
          abilityInfoTip.x = Math.round(window.innerWidth / 2 - abilityInfoTip.width / 2);
          abilityInfoTip.y = Math.round(window.innerHeight / 2 - DT_CANVAS_GLOBALS.spacing * 2);
          TweenMax.from(abilityInfoTip, 0.5, { alpha: 0 });
          DT_CANVAS_GLOBALS.stage.addChild(abilityInfoTip);
        } catch (err) {
          logError(err, {
            module: 'components/ui_screens/edit_hero/edit_abilities/EditAbilities',
            func: 'onTreePanelsTweened.delay_2',
          });
        }
      });
    } catch (err) {
      logError(err, {
        module: 'components/ui_screens/edit_hero/edit_abilities/EditAbilities',
        func: 'onTreePanelsTweened',
      });
    }
  };

  var spotlight;
  var cycleTip;
  var abilityInfoTip;
  var finalTip;

  if (TutorialStore.getAll().show_equip_ability_tutorial) {
    DT_CANVAS_GLOBALS.tooltips_disabled = true;

    firebase.analytics().logEvent('onboard_step:14', {
      name: 'start_ability_phase',
    });

    TweenMax.delayedCall(1.5, () => {
      if (!_treePanelCarriage) {
        return;
      }

      var rightPanelPos = _treePanelCarriage.rightPanel.toGlobal({ x: 0, y: 0 });

      spotlight = new TutorialSpotlight(120);
      spotlight.x = rightPanelPos.x;
      spotlight.y = rightPanelPos.y;
      spotlight.transitionIn();
      DT_CANVAS_GLOBALS.stage.addChild(spotlight);

      _treePanelCarriage.eventEmitter.on(AbilityTreePanelCarriage.TREE_PANELS_TWEEENED, onTreePanelsTweened);
    });

    TweenMax.delayedCall(2.0, () => {
      cycleTip = new TutorialBox(text('tutorial.cycle_ability_trees_tip'), 'right');
      cycleTip.x = Math.round(window.innerWidth * 0.6 - cycleTip.width / 2 - DT_CANVAS_GLOBALS.spacing * 5);
      cycleTip.y = Math.round(window.innerHeight * 0.32);
      TweenMax.from(cycleTip, 0.5, { alpha: 0 });
      DT_CANVAS_GLOBALS.stage.addChild(cycleTip);
    });
  }

  const onResetAbilitiesRequested = () => {
    if (CurrencyStore.getAll().pixieDust >= Economy.ABILITY_RESET_COST && _equippedAbilityPanel) {
      for (const eap_icon of _equippedAbilityPanel.getIcons()) {
        if (eap_icon) {
          CanvasTools.makeFaerieSpinners(eap_icon, 3);
        }
      }
    }
  };

  HeroBuildStore.on(HeroBuildStore.ABILITY_CHANGE, onAbilityChange);
  HeroBuildStore.on(HeroBuildStore.RESET_ABILITIES_REQUESTED, onResetAbilitiesRequested);

  // init
  makeTreePanelCarriage();
  makeEquippedAbilityPanel();
  makeAbilityPointsPanel();
  _tweenCanvasElements = false;

  _backfill = new PIXI.Graphics();
  _backfill.beginFill(0x000000, 0.01);
  _backfill.drawRect(0, 0, DT_CANVAS_GLOBALS.stage.width, DT_CANVAS_GLOBALS.stage.height);
  _backfill.endFill();
  _backfill.interactive = true;
  _backfill.tap = _backfill.click = () => {
    this.hidePopups();
  }
  DT_CANVAS_GLOBALS.stage.addChildAt(_backfill, 0);
};
EditAbilities.prototype = Object.create(PIXI.Container.prototype);
EditAbilities.prototype.constructor = EditAbilities;
export default EditAbilities;
