import * as PIXI from 'pixi.js';
import text from '~/text';
import Balance from 'dt-common/constants/Balance';
import CanvasTools from '~/view/CanvasTools';
import GameItemIcon from '~/components/common/GameItemIcon';

const XP_POT_ICON_SIZE_PX = 32;

const XPBar = function({ roster_hero, onXpPotSlotMouseup }) {
  PIXI.Container.call(this);

  this.dispose = () => {
    _base = null;
    _fullBar = null;
    _fullBarMask = null;
    _label = null;
    _valueText = null;
  
    _xp_pot_slot?.fg?.dispose();
    _xp_pot_slot?.destroy();
    _xp_pot_slot = null;
    
    this.destroy();
  };

  this.update = (value, maxValue) => {
    _valueText.text = CanvasTools.getBriefString(value) + ' / ' + CanvasTools.getBriefString(maxValue);
    _valueText.x = Math.round(_base.x + _base.width / 2 - _valueText.width / 2);

    _fullBarMask.clear();
    _fullBarMask.beginFill(0xff0000);
    _fullBarMask.drawRect(0, 0, _base.width*(value/maxValue), _base.height);
    _fullBarMask.endFill();

    if (!_fullBar.mask) {
      _fullBar.mask = _fullBarMask;
    }
  };

  let _base;
  let _fullBar;
  let _fullBarMask;
  let _label
  let _valueText;
  let _xp_pot_slot;

  _label = new PIXI.Text(text('game.XP'), {
    fontFamily: 'Courier New',
    fontSize: '12px',
    fontWeight: 'bold',
    fill: 0xffffff,
  });
  this.addChild(_label);

  _base = new PIXI.Sprite(PIXI.utils.TextureCache['window_border_horiz.png']);
  _base.scale.x = 1.5;
  _base.x = _label.x + _label.width + DT_CANVAS_GLOBALS.spacing * 0.3;
  _base.y = _label.x + _label.height / 2 - _base.height / 2;
  this.addChild(_base);

  _fullBarMask = new PIXI.Graphics();
  _fullBar = new PIXI.Graphics();
  _fullBar.beginFill(0xffffff);
  _fullBar.drawRect(0, 0, _base.width, _base.height);
  _fullBar.endFill();
  _fullBar.addChild(_fullBarMask);
  _fullBar.x = _base.x;
  _fullBar.y = _base.y;
  this.addChild(_fullBar);

  _valueText = new PIXI.Text('', {
    fontFamily: 'Courier New',
    fontSize: '10px',
    fontWeight: 'bold',
    fill: 0xffffff,
  });
  _valueText.x = Math.round(_base.x + _base.width / 2 - _valueText.width / 2);
  _valueText.y = Math.round(_base.y + _base.height * 1.5);
  this.addChild(_valueText);

  // make XP pot slot
  _xp_pot_slot = new PIXI.Container();
  _xp_pot_slot.bg = new GameItemIcon(
    {
      // dummy xp pot for silhouette
      type: 'hero_xp_boost',
      xp_bonus: 1,
      duration:1,
      pd_price: 1,
      uid: '1',
    },
    {
      animate: false,
      show_inner_img: false,
    }
  );
  _xp_pot_slot.bg.buttonMode = _xp_pot_slot.bg.interactive = false;
  _xp_pot_slot.bg.setScale(XP_POT_ICON_SIZE_PX);
  _xp_pot_slot.bg.img.tint = 0x444444;
  _xp_pot_slot.addChild(_xp_pot_slot.bg);
  _xp_pot_slot.x = _base.x + _base.width + _xp_pot_slot.width / 2 + DT_CANVAS_GLOBALS.spacing * 1;
  _xp_pot_slot.y = _base.y;
  this.addChild(_xp_pot_slot);
  _xp_pot_slot.interactive = true;
  _xp_pot_slot.mouseup = _xp_pot_slot.touchend = onXpPotSlotMouseup;

  // make active xp boost icon
  if (roster_hero?.xp_boost) {
    _xp_pot_slot.fg = new GameItemIcon({
      type: 'hero_xp_boost',
      xp_bonus: roster_hero.xp_boost.xp_bonus,
      duration: roster_hero.xp_boost.start_time + roster_hero.xp_boost.duration - Date.now(), // time left
      pd_price: 0,
      uid: '1',
    })
    _xp_pot_slot.fg.buttonMode = _xp_pot_slot.bg.interactive = false;
    _xp_pot_slot.fg.setScale(XP_POT_ICON_SIZE_PX);
    _xp_pot_slot.addChild(_xp_pot_slot.fg);
  }

  this.update(
    Math.round(roster_hero.xp - Balance.getFibonacci(roster_hero.level)),
    Balance.getFibonacci(roster_hero.level - 1),
  );
};
XPBar.prototype = Object.create(PIXI.Container.prototype);
XPBar.prototype.constructor = XPBar;
export default XPBar;
