<!-- eslint-disable-line vue/multi-word-component-names -->
<template>
  <div
    id="arena_lobby_root"
    class="px-[5vh] pb-[3vh] bg-transparent flex flex-col justify-center items-center"
  >
    <!-- stats from competition profile per game submode-->
    <!-- rank -->
    <div class="-mt-[1%] bg-zinc-700 py-0 px-2 border border-white rounded-sm text-lg text-yellow-500">
      {{ text('ui.rank') }}: <span class="font-bold">#{{ state.submode_global_rank || '???' }}</span>
    </div>
    <!-- elo, win/loss, avg opponent elo -->
    <div class="mt-6 flex text-xs">
      <div class="mx-3 text-center">
        <div class="font-bold">Rating:</div>
        <div>{{ state.submode_competition_profile.elo_rating }}</div>
      </div>
      <div class="mx-3 text-center">
        <div class="font-bold">Win|Loss:</div>
        <div>
          <span class="text-green-500">
            {{ state.submode_competition_profile.num_won }}
          </span>
          <span> | </span>
          <span class="text-red-500">
            {{ state.submode_competition_profile.num_matches - state.submode_competition_profile.num_won}}
          </span>
        </div>
      </div>
      <div class="mx-3 text-center">
        <div class="font-bold">Avg. Opponent:</div>
        <div>{{ state.submode_competition_profile?.avg_opponent_elo?.toFixed(0) }}</div>
      </div>
    </div>

    <!-- placeholder for canvas rendering -->
    <div
      id="arena_lobby_squad_view"
      class="mt-[5%] w-full h-[200px] bg-transparent"
    ></div>
    <!-- history menu button -->
    <DTButton
      id="arena_random_matchup_btn"
      type="warning"
      class="mt-[1%] py-2 px-8"
      @click="onQuickMatchupClick"
    >
      <div
        v-if="state.is_in_match_queue"
        class="w-full h-full flex justify-center items-center"  
      >
        <img
            :src="`${Config.DOM_IMG_PATH}/refresh.png`"
            class="h-7 w-auto spin"
          />
      </div>
      <span v-else>
        {{ text('ui.fight') }}
      </span>
    </DTButton>
  </div>
</template>

<script setup>
import { nextTick, onMounted, onBeforeUnmount, reactive } from 'vue';
import Game from 'dt-common/constants/Game';
import getNumHeroesInSubmode from 'dt-common/isomorphic-helpers/getNumHeroesInSubmode';
import Audio from '~/Audio';
import { Config } from '~/constants';
import { ArenaLobbyActions, HeroBuildActions } from '~/flux/actions';
import { ArenaLobbyStore, GameStateStore, HeroBuildStore, UIStore } from '~/flux/stores';
import text from '~/text';
import { EngagedSquadView, GameSubmodeSelector, SquadPuppetMouseoverPanel } from '~/view/components/common/canvas';
import { DTButton } from '~/view/components/common/DOM';

let _engaged_squad_view;
let _gameSubmodeSelector;
let _squad_puppet_mouseover_panel;

const state = reactive({
  is_in_match_queue: false,
  pending_inn_state_data: true,
  submode_state: null,
  submode_competition_profile: {},
});

onMounted(() => {
  state.submode_competition_profile = ArenaLobbyStore.getAll().competition_profiles[
    UIStore.getAll().current_game_submode
  ];

  ArenaLobbyActions.fetchLeaderboardRank();

  makeGameSubmodeSelector();
  makeEngagedSquadView();
  ArenaLobbyStore.on(ArenaLobbyStore.LEADERBOARD_RANK, onLeaderboardRank)
  GameStateStore.on(GameStateStore.LOADOUT_UPDATED, onLoadoutUpdated);  
  UIStore.on(UIStore.GAME_SUBMODE_SELECTION, onGameSubmodeSelection);
  Audio.setBGTrack('menu_music');
});

onBeforeUnmount(() => {
  destroyEngagedSquadView();
  destroyGameSubmodeSelector();
  ArenaLobbyStore.removeListener(ArenaLobbyStore.LEADERBOARD_RANK, onLeaderboardRank)
  GameStateStore.removeListener(GameStateStore.LOADOUT_UPDATED, onLoadoutUpdated);  
  UIStore.removeListener(UIStore.GAME_SUBMODE_SELECTION, onGameSubmodeSelection);
});

const destroyEngagedSquadView = () => {
  if (_engaged_squad_view) {
    DT_CANVAS_GLOBALS.stage.removeChild(_engaged_squad_view);
    _engaged_squad_view.dispose();
    _engaged_squad_view = null;
  }
};

function destroySquadPuppetMouseoverPanel() {
    _squad_puppet_mouseover_panel?.destroy();
    _squad_puppet_mouseover_panel = null;
}

function destroyGameSubmodeSelector() {
  _gameSubmodeSelector?.dispose();
  _gameSubmodeSelector = null;
}

const makeEngagedSquadView = ({ do_transition_in = true } = {}) => {
  destroyEngagedSquadView();

  const dom_anchor = document.getElementById('arena_lobby_squad_view');
  const { x, y, width, height } = dom_anchor.getBoundingClientRect();

  const { loadouts } = GameStateStore.getAll().gameState;
  const { current_game_mode, current_game_submode } = UIStore.getAll();
  const num_heroes = getNumHeroesInSubmode(current_game_submode);
  const puppet_scale = num_heroes < 3
    ? 1.6
    : num_heroes === 3
      ? 1.4
      : num_heroes === 5
        ? 1.2
        : 1.3;

  _engaged_squad_view = new EngagedSquadView({
    hero_builds: HeroBuildStore.getAll().hero_builds,
    loadout_squad_list: loadouts[current_game_mode][current_game_submode].filter(hero => !!hero.engagement),
    roster_heroes: GameStateStore.getAll().gameState.hero_roster,
    puppet_scale,
    do_transition_in,
    face_direction: Game.EAST,
    getHorizontalPuppetSpacing: ({ puppet_index }) => {
      switch (num_heroes) {
        case 1:
          return 0;
        case 2:
          return puppet_scale * -50 + puppet_index * puppet_scale * 100;
        case 3:
          return puppet_scale * -100 + puppet_index * puppet_scale * 100;
        case 5: {
          const START = puppet_scale * -100;
          const SCALE = 115;
          switch (puppet_index) {
            case 0: return START + SCALE * 1.8 * puppet_scale;
            case 1: return START + SCALE * 1.35 * puppet_scale;
            case 2: return START + SCALE * 0.9 * puppet_scale;
            case 3: return START + SCALE * 0.45 * puppet_scale;
            case 4: return START;
          }
        }
        case 7: {
          const START = 0; //  puppet_scale * -50;
          const SCALE = 95;
          switch (puppet_index) {
            case 0: return START - SCALE * 1.35 * puppet_scale;
            case 1: return START - SCALE * 0.9 * puppet_scale;
            case 2: return START - SCALE * 0.45 * puppet_scale;
            case 3: return START;
            case 4: return START + SCALE * 0.45 * puppet_scale;
            case 5: return START + SCALE * 0.9 * puppet_scale;
            case 6: return START + SCALE * 1.35 * puppet_scale;
          }
        }
        default: throw new Error(`Unsupported num_heroes: $${num_heroes}`)
      }
    },
    getVerticalPuppetSpacing: ({ puppet_index, y_drift }) => {
      switch(num_heroes) {
        case 1: 
        case 2:
        case 3:
        default:
          return DT_CANVAS_GLOBALS.spacing * 3.75 + Math.round(y_drift * 10)
        case 5: {
          switch (puppet_index) {
            case 0: return puppet_scale * 40;
            case 1: return puppet_scale * -25;
            case 2: return puppet_scale * 40;
            case 3: return puppet_scale * -25;
            case 4: return puppet_scale * 40;

          }
        }
        case 7: {
          switch (puppet_index) {
            case 0: return puppet_scale * 40;
            case 1: return puppet_scale * -35;
            case 2: return puppet_scale * 40;
            case 3: return puppet_scale * -35;
            case 4: return puppet_scale * 40;
            case 5: return puppet_scale * -35;
            case 6: return puppet_scale * 40;
          }
        }
      }
    },
  });
  // z-depth of nameplate puppets
  const puppets = _engaged_squad_view.getPuppets();
  puppets[0] && puppets[0].parent.addChild(puppets[0]);
  puppets[2] && puppets[2].parent.addChild(puppets[2]);
  puppets[4] && puppets[4].parent.addChild(puppets[4]);

  _engaged_squad_view.x = x + width / 2;
  _engaged_squad_view.y = y + height / 2 - DT_CANVAS_GLOBALS.spacing * 2;
  DT_CANVAS_GLOBALS.stage.addChild(_engaged_squad_view);

  // add squad puppet interaction listeners
  for (const puppet of puppets) {
    puppet.mouseover = puppet.touchstart = onSquadPuppetMouseover.bind(null, puppet);
    puppet.mouseout = puppet.touchend = onSquadPuppetMouseout;
  }
};

function makeGameSubmodeSelector() {
  destroyGameSubmodeSelector();
  _gameSubmodeSelector = new GameSubmodeSelector();
  _gameSubmodeSelector.x = window.innerWidth / 2 - _gameSubmodeSelector.width / 2;
  _gameSubmodeSelector.y = Math.min(50, DT_CANVAS_GLOBALS.spacing * 7);
  DT_CANVAS_GLOBALS.stage.addChild(_gameSubmodeSelector);
}

function makeSquadPuppetMouseoverPanel(puppet) {
  const out_build = puppet.actor;
  const { current_game_mode, current_game_submode } = UIStore.getAll();
  const loadout = GameStateStore.getAll().gameState.loadouts[current_game_mode][current_game_submode];

  _squad_puppet_mouseover_panel = new SquadPuppetMouseoverPanel({
    onHeroBuildSelected: ({ hero_build_id, hero_handle }) => {
      HeroBuildActions.engageHeroBuild({
        hero_build_id,
        hero_handle,
        game_mode: UIStore.getAll().current_game_mode,
        game_submode: UIStore.getAll().current_game_submode,
        target_slot_index: loadout.find(({ hero_build_id: id }) => id === out_build._id).engagement.slot,
      });
    },
    out_build,
  });
  _squad_puppet_mouseover_panel.y += 5;
  puppet.addChild(_squad_puppet_mouseover_panel);
}

function onGameSubmodeSelection() {
  const { current_game_mode, current_game_submode } = UIStore.getAll()
  if (current_game_mode !== Game.GAME_MODES.GAME_MODE_pvpLive) {
    return;
  }
  state.submode_competition_profile = ArenaLobbyStore.getAll().competition_profiles[current_game_submode];
  state.is_in_match_queue = false;
  nextTick(makeEngagedSquadView);

  ArenaLobbyActions.fetchLeaderboardRank();
}

function onLeaderboardRank(data) {
  state.submode_global_rank = data.rank;
}

function onLoadoutUpdated() {
  makeEngagedSquadView();
}

function onSquadPuppetMouseover(puppet) {
  makeSquadPuppetMouseoverPanel(puppet);
}

function onSquadPuppetMouseout() {
  destroySquadPuppetMouseoverPanel();
}

function onQuickMatchupClick() {
  ArenaLobbyActions.enterStandardMatchmakingQueue();
  state.is_in_match_queue = true;
}
</script>
