<template>
  <Dialog
    class="relative"
    :open="true"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4">
      <DialogPanel class="border border-white w-[800px] max-h-[90vh] flex flex-col">
        <div class="p-6">
          <div class="email-login-header">
            <h4>Login with email</h4>
          </div>
          <form
            class="mt-4"
            @submit="submitEmailForm"
          >
            <div class="text-sm">
              <!-- <div
                class="w-36 text-right mr-2"
              >
                {{ text('ui.email_label') }}
              </div> -->
              <input
                id="email"
                type="text"
                name="email"
                class="w-64 pl-2 text-black"
                :placeholder="text('ui.enter_email')"
                @change="handleEmailFormChange"
              >

              <div
                class="h-2 text-xs text-red-500"
              >
                {{ state.errors.email }}
              </div>
            </div>

            <div class="mt-2 text-sm">
              <input
                id="password"
                type="password"
                name="password"
                class="w-64 pl-2 text-black"
                :placeholder="text('ui.enter_password')"
                @change="handleEmailFormChange"
              >

              <div
                class="h-2 text-red-500 text-xs"
              >
                {{ state.errors.password }}
              </div>
            </div>

            <div
              v-if="state.is_creating_new_account"
              class="mt-2 text-sm"
            >
              <input
                id="confirm_password"
                type="password"
                name="confirm_password"
                :value="state.input.confirm_password"
                class="w-64 pl-2 text-black"
                placeholder="confirm password"
                @change="handleEmailFormChange"
              >

              <div class="h-2 text-red-500 text-xs">
                {{ state.errors.confirm_password }}
              </div>
            </div>

            <div
              class="h-2 text-red-500 text-xs"
            >
              {{ state.errors.firebase }}
            </div>

            <div
              v-if="state.is_creating_new_account"
              class="email-login-btns-row"
            >
              <DTButton
                type="success"
                class="px-3 py-1"
                @click="submitEmailForm"
              >
                Create
              </DTButton>
              <DTButton
                type="error"
                class="px-3 py-1"
                @click="toggleCreateNewAcctButton(false)"
              >
                Cancel
              </DTButton>
            </div>
            <div v-else>
              <div class="mt-2 email-login-btns-row">
                <DTButton
                  :disabled="state.pending_login_request"
                  type="success"
                  class="px-3 py-1"
                  @click="submitEmailForm"
                >
                  <div
                    v-if="state.pending_login_request"
                    class="spinner_wrapper"
                  >
                    <div
                      class="dt-spinner"
                      style="font-size: 16px;"
                    />
                  </div>
                  <span v-else>Log In</span>
                </DTButton>
                <DTButton
                  :disabled="state.pending_login_request"
                  type="info"
                  class="px-3 py-1"
                  @click="toggleCreateNewAcctButton(true)"
                >
                  <span class="text-base">Create New Account</span>
                </DTButton>
              </div>
              <div class="mt-1">
                <a
                  class="cursor-pointer text-xs"
                  @click="onResetPasswordClick"
                >
                  Reset Password
                </a>
              </div>
            </div>
          </form>

          <hr class="my-6 border border-zinc-700">

          <div class="oath-section">
            <h4>Or use one of these:</h4>
            <div class="py-3 oath-btn-container">
              <button
                class="btn btn-success oauth-btn"
                @click="logInWithGoogle"
              >
                <img
                  :src="`${DOM_IMG_PATH}/google-logo-circle.png`"
                >
              </button>
              <button
                class="btn btn-success oauth-btn"
                @click="logInWithFacebook"
              >
                <img
                  :src=" `${DOM_IMG_PATH}/f_logo_RGB-Blue_144.png`"
                >
              </button>
            </div>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { reactive } from 'vue';
import {
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import firebase from 'firebase/compat/app';
import text from '~/text';
import { DTButton } from '~/view/components/common/DOM';

const { ASSETS_PATH = 'assets' } = process.env;
const DOM_IMG_PATH = `${ASSETS_PATH}/img/DOM`;

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const state = reactive({
  is_creating_new_account: false,
  input: {
    email: '',
    password: '',
    confirm_password: '',
  },
  errors: {},
});

async function onResetPasswordClick() {
  if (validateEmailForm(true)) {
    try {
      const { email } = state.input;
      await firebase.auth().sendPasswordResetEmail(email);
      state.errors = {
        firebase: `A password reset link has been mailed to ${email}`,
      };
    } catch (err) {
      state.errors = {
        firebase: err.message,
      };
    }
  }
}

function toggleCreateNewAcctButton(val) {
  state.is_creating_new_account = val;
}

function handleEmailFormChange(event) {
  state.input[event.target.name] = event.target.value;
}

async function submitEmailForm(event) {
  event.preventDefault();

  if (validateEmailForm()) {
    const { email, password } = state.input;

    state.pending_login_request = true,
    state.input = {
      email: '',
      password: '',
      confirm_password: '',
    };

    if (state.is_creating_new_account) {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
    } else try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
      state.errors = {
        firebase: err.message,
      };
      logError(err);
    } finally {
      state.pending_login_request = false;
    }
    props.onClose();
  }
}

function validateEmailForm(email_only) {
  let input = state.input;
  let errors = {};
  let isValid = true;

  if (!input['email']) {
    isValid = false;
    errors['email'] = 'Please enter your email Address.';
  }

  if (typeof input['email'] !== 'undefined') {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(input['email'])) {
      isValid = false;
      errors['email'] = 'Please enter a valid email address.';
    }
  }

  if (!email_only) {
    if (!input['password']) {
      isValid = false;
      errors['password'] = 'Please enter your password.';
    }

    if (typeof input['password'] !== 'undefined') {
      if (input['password'].length < 6) {
        isValid = false;
        errors['password'] = 'Please add at least 6 characters.';
      }
    }

    if (state.is_creating_new_account) {
      if (!input['confirm_password']) {
        isValid = false;
        errors['confirm_password'] = 'Please enter your confirm password.';
      }

      if (typeof input['password'] !== 'undefined' && typeof input['confirm_password'] !== 'undefined') {
        if (input['password'] != input['confirm_password']) {
          isValid = false;
          errors['password'] = 'Passwords don\'t match.';
        }
      }
    }
  }

  state.errors = errors;
  return isValid;
}

async function logInWithFacebook() {
  try {
    const fb_auth_provider = new firebase.auth.FacebookAuthProvider();
    await firebase.auth().signInWithPopup(fb_auth_provider);
  } catch (err) {
    state.errors = {
      firebase: err.message,
    };
    console.error(err);
  }
  props.onClose();
}

async function logInWithGoogle () {
  try {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(googleAuthProvider);
  } catch (err) {
    state.errors = {
      firebase: err.message,
    };
    console.error(err);
  }
  props.onClose();
}
</script>

<style>
.email-login-header {
  width: 100%;
  text-align: center;
}
.email-login-header > h4 {
  color: yellow;
}

.email-login-btns-row {
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.email-login-btn {
  border-radius: 5px;
}

.oath-section {
  width: 100%;
  text-align: center;
}
.oath-section > h4 {
  color: yellow;
}

.oauth-btn {
  width: 52px;
  height: 48px;
  border-radius: 24px !important;
  border: none !important;
  outline: none !important;
  margin-right: 10px;
  padding: 0
}
.oauth-btn > img {
  width: 100%;
  height: 100%;
}

.spinner_wrapper {
  width: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
