import * as PIXI from 'pixi.js';
import TileSprite from './TileSprite';
import UnitPuppet from '~/components/common/unit_puppets/UnitPuppet';
import UnitStatBar from './UnitStatBar';
import APMeter from './APMeter';

const UnitSprite = function(unit_state) {
  const _tileSprite = new TileSprite(unit_state);

  _tileSprite.updateUnit = (new_unit_state) => {
    if (new_unit_state.dead || !new_unit_state.inPlay) {
      return;
    }

    unit_state = new_unit_state;
    _tileSprite.gamePiece = unit_state;
    _tileSprite.puppet.actor = unit_state;
    _tileSprite.apMeter?.update(unit_state);
    _tileSprite.statBar?.update(unit_state);
  };

  _tileSprite.tileImg.texture = PIXI.utils.TextureCache['tile_highlight_'+unit_state.team+'.png'];
  _tileSprite.tileImg.width = _tileSprite.TILE_WIDTH;
  _tileSprite.tileImg.height = _tileSprite.TILE_HEIGHT;
  _tileSprite.tileImg.alpha = 0.01;

  // make the stats bar
  var sb = _tileSprite.statBar = new UnitStatBar(unit_state);
  sb.interactive = false;
  _tileSprite.addChild(sb);
  sb.init(); // must be called after addChild(), as it uses the tile sprite's width for drawing
  sb.x = -sb.width/2;
  sb.y = -_tileSprite.height * 0.8;
  sb.update(unit_state);

  // make the action points meter
  var apm = _tileSprite.apMeter = new APMeter(unit_state);
  _tileSprite.addChild(apm);
  apm.update(unit_state);

  // make the unit_state puppet
  _tileSprite.puppet = new UnitPuppet({ 
    unit_state,
    body_scale: unit_state.handle === 'faerie'
      ? 0.001
      : 0.7,
  });
  _tileSprite.puppet.y = _tileSprite.height * 0.25;
  _tileSprite.addChild(_tileSprite.puppet);
  _tileSprite.puppet.interactive = false;
  _tileSprite.puppet.transitionIn();

  // initialize the text queue timer
  _tileSprite.textQueue = [];
  _tileSprite.textQueueTimer = setInterval(()=> {
    if (_tileSprite.textQueue.length > 0) {
      const textEffect = _tileSprite.textQueue.shift();
      textEffect.scale.x = Math.min(1.7, 1 / _tileSprite.scale.x);
      textEffect.scale.y = Math.min(1.7, 1 / _tileSprite.scale.y);
      textEffect.y = (_tileSprite?.puppet?.height * -0.25) / textEffect.scale.y;
      textEffect.init();
      _tileSprite.addChild(textEffect);
    }
  }, 300);

  _tileSprite.onDeath = () => {
    TweenMax.to(_tileSprite.statBar, 0.5, { alpha: 0,onComplete: ()=>{_tileSprite.statBar.visible=false;} });
    TweenMax.to(_tileSprite.tileImg, 0.5, { alpha: 0,onComplete: ()=>{_tileSprite.tileImg.visible=false;} });
    TweenMax.to(_tileSprite.apMeter, 0.5, { alpha: 0,onComplete: ()=>{_tileSprite.apMeter.visible=false;} });
  };

  _tileSprite.dispose = () => {
    if (_tileSprite.disposed) {
      return;
    }

    clearInterval(_tileSprite.textQueueTimer);

    _tileSprite.textQueue = null;

    if (_tileSprite.puppet) {
      _tileSprite.puppet.dispose();
      _tileSprite.puppet = null;
    }

    if (_tileSprite.faerieSprite) {
      _tileSprite.faerieSprite.dispose();
      _tileSprite.faerieSprite = null;
    }

    _tileSprite.parent?.removeChild(_tileSprite);
    _tileSprite.removeChildren();
    _tileSprite.disposed = true;
  };

  return _tileSprite;
};
export default UnitSprite;
