import * as PIXI from 'pixi.js';
import NavMenu from './NavMenu';
import BattleHUD from './battle_HUD/BattleHUD';
import { BattleStore, CavernsStore } from '~/flux/stores';
import UIStore from '~/flux/stores/UIStore';
import Screens from '~/constants/Screens';

const Footer = function({ isResize = false } = {}) {
  PIXI.Container.call(this);

  let _navMenu;
  let _infoPanel;
  let _cancelBtn;
  let _battleHUD;

  this.transitionIn = () => {
    // TweenMax.from(this, 1.0, { y: window.innerHeight });
  };

  this.transitionOut = (onComplete) => {
    TweenMax.to(this, 1.0, { y: window.innerHeight });
  };

  this.dispose = () => {
    if (_cancelBtn) {
      _cancelBtn.dispose();
      _cancelBtn = null;
    }

    destroyNavMenu();
    destroyBattleHUD();
    destroyInfoPanel(_infoPanel);

    BattleStore.removeListener(BattleStore.BATTLE_INITIALIZED, onBattleInitialized);
    BattleStore.removeListener(BattleStore.CHALLENGE_RESOLVED, onChallengeResolved);
    CavernsStore.removeListener(CavernsStore.ENTERED_CAVERNS, onEnteredCaverns);
    CavernsStore.removeListener(CavernsStore.RETURNED_TO_SURFACE, onReturnedToSurface);
    UIStore.removeListener(UIStore.UI_NAV, onUINav);

    document.removeEventListener('keydown', onKeydown);

    this.destroy();
  };

  const destroyNavMenu = () => {
    if(_navMenu) {
      TweenMax.killTweensOf(_navMenu);
      this.removeChild(_navMenu);
      _navMenu.dispose();
      _navMenu = null;
    }        
  };

  const destroyBattleHUD = () => {
    if(_battleHUD) {
      TweenMax.killTweensOf(_battleHUD);
      this.removeChild(_battleHUD);
      _battleHUD.dispose();
      _battleHUD = null;
    }
  };

  const destroyInfoPanel = (deadPanel) => {
    if (deadPanel) {
      TweenMax.killlTweensOf(deadPanel);
      if (deadPanel.cancelBtn) {
        deadPanel.cancelBtn.tap = deadPanel.cancelBtn.click = null;
      }
        
      deadPanel.dispose();
      this.removeChild(deadPanel);
    }
  };

  const hideInfoPanel = (deadPanel) => {
    if (deadPanel) {
      TweenMax.to(deadPanel, 0.35, {
        alpha: 0,
        ease: Expo.easeIn,
        onComplete: destroyInfoPanel,
        onCompleteParams: [deadPanel]
      });
    }
  };

  // initialize by making our chat box (actually this is just a dummy, as chat is rendered in the DOM)
  const _chat = { x: DT_CANVAS_GLOBALS.spacing, width: window.innerWidth / 2 };

  // initialize with nav menu
  const makeNavMenu = (tweenIn) => {
    destroyNavMenu();
    _navMenu = new NavMenu(window.innerWidth-_chat.width-DT_CANVAS_GLOBALS.spacing*2);
    _navMenu.x = _chat.x + _chat.width;
    this.addChild(_navMenu);

    if(tweenIn) {
      // TweenMax.from(_navMenu, 0.4, {y:window.innerHeight});
    }
  };

  // switch to the battle HUD when starting a new battle
  const onBattleInitialized = this.onBattleInitialized = () => {
    if (_navMenu) {
      TweenMax.to(_navMenu, 0.23, { y: window.innerHeight, onComplete:destroyNavMenu });
    }

    // make the battle HUD
    _battleHUD = new BattleHUD(window.innerWidth - _chat.width - DT_CANVAS_GLOBALS.spacing * 2);
    _battleHUD.x = _chat.x + _chat.width;
    this.addChild(_battleHUD);
    if (!isResize) {
      TweenMax.from(_battleHUD, 0.4, { y: window.innerHeight });
    }
  };

  // switch back to nav menu when the battle is resolved
  const onChallengeResolved = () => {
    if (_battleHUD) {
      TweenMax.to(_battleHUD, 0.23, {
        y: window.innerHeight,
        onComplete: destroyBattleHUD
      });
    }

    makeNavMenu(false);

    if (_infoPanel) {
      hideInfoPanel(_infoPanel);
    }
  };

  const onEnteredCaverns = () => {
    destroyNavMenu();
  }

  const onReturnedToSurface = () => {
    makeNavMenu();
  }

  const onUINav = () => {
    if(UIStore.getAll().current_ui_screen !== Screens.BATTLE) {
      onChallengeResolved();
    }
  };

  const onKeydown = (event) => {
    if (event.key === 'Escape') {
      if (_cancelBtn && _cancelBtn.click) {
        _cancelBtn.click();
      }
    }
  };

  // init
  const { current_ui_screen } = UIStore.getAll();
  if (current_ui_screen === Screens.BATTLE) {
    onBattleInitialized();
  } else if (
    current_ui_screen !== Screens.CAVERNS
    || !CavernsStore.getAll().battleState
  ) {
    makeNavMenu(!isResize);
  }

  document.addEventListener('keydown', onKeydown);
  BattleStore.on(BattleStore.BATTLE_INITIALIZED, onBattleInitialized);
  BattleStore.on(BattleStore.CHALLENGE_RESOLVED, onChallengeResolved);
  CavernsStore.on(CavernsStore.ENTERED_CAVERNS, onEnteredCaverns);
  CavernsStore.on(CavernsStore.RETURNED_TO_SURFACE, onReturnedToSurface);
  UIStore.on(UIStore.UI_NAV, onUINav);
};
Footer.prototype = Object.create(PIXI.Container.prototype);
Footer.prototype.constructor = Footer;
export default Footer;
