<template>
  <div id="Footer_DOM" class="w-full flex pointer-events-none">
    <Chat class="pointer-events-auto" />
    <CavernsBattleMenu v-if="state.show_caverns_battle_menu"  />
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, reactive } from 'vue';
import { Screens } from '~/constants';
import { CavernsStore, UIStore } from '~/flux/stores';
import Chat from './chat/Chat.vue';
import CavernsBattleMenu from './CavernsBattleMenu.vue';

const state = reactive({
  show_caverns_battle_menu: UIStore.getAll().current_ui_screen === Screens.CAVERNS,
});

onMounted(() => {
  CavernsStore.on(CavernsStore.ENTERED_CAVERNS, onEnteredCaverns);
  CavernsStore.on(CavernsStore.RETURNED_TO_SURFACE, onReturnedToSurface);
});

onBeforeUnmount(() => {
  CavernsStore.removeListener(CavernsStore.ENTERED_CAVERNS, onEnteredCaverns);
  CavernsStore.removeListener(CavernsStore.RETURNED_TO_SURFACE, onReturnedToSurface);
});

function onEnteredCaverns() {
  state.show_caverns_battle_menu = true;
}

function onReturnedToSurface() {
  state.show_caverns_battle_menu = false;
}
</script>
