import * as PIXI from 'pixi.js';
import getAbilityLevel from 'dt-common/isomorphic-helpers/getAbilityLevel';
import getUnitState from 'dt-common/isomorphic-helpers/getUnitState';
import Colors from '~/constants/Colors';
import { FluxGetters } from '~/flux/stores';
import text from '~/text';
import CanvasTools from '~/view/CanvasTools';

const wordWrapWidth = 420;

const AbilityTooltip = function({
  ability_handle,
  locked,
  show_calc_text = true,
}) {
  try {
    PIXI.Container.call(this);

    const { built_hero, roster_hero } = FluxGetters.getFocusedHeroBuild();
    const hero_state = getUnitState({
      roster_hero,
      unit_build: built_hero,
    });
    const ability_state = (() => {
      for (const ability_tree of hero_state.abilityTrees) {
        for (const [handle, ability] of Object.entries(ability_tree.abilities)) {
          if (handle == ability_handle) {
            return ability;
          }
        }
      }
    })();

    // generate the title text
    const level_text = ['melee_attack', 'move'].includes(ability_handle)
      ? ''
      : ' - ' + (
        locked
          ? text('ui.locked')
          : text('game.level.brief') + getAbilityLevel({
            ability_handle,
            unit_build: built_hero,
          })
      );
    const title_text = text(`abilities.${ability_handle}.name`) + level_text;

    // add the title sprite
    const _title = new PIXI.Text(title_text, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(18),
      fontWeight: 'bold',
      fill: Colors.BRIGHT_YELLOW,
      wordWrap: true,
      wordWrapWidth,
    });
    this.addChild(_title);

    // add the ability's blurb
    const blurb_text = text(`abilities.${ability_handle}.blurb`);
    const _blurb = new PIXI.Text(blurb_text, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(14),
      fontStyle: 'italic',
      fill: 0xbababa,
      wordWrap: true,
      wordWrapWidth,
    });
    _blurb.y = Math.round(_title.y + _title.height + DT_CANVAS_GLOBALS.spacing / 2);
    this.addChild(_blurb);

    // add ability aspect fields
    const _aspect_fields = [];
    const calc_text = text(`abilities.${ability_handle}.calc`);

    // range
    if (ability_state.range) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.range')}: `,
          valueText: `${ability_state.range.min}-${ability_state.range.max}`,
          calcText: show_calc_text && calc_text.range,
        }),
      );
    }

    // hp cost
    if (ability_state.hpCost) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.hp_cost')}: `,
          valueText: ability_state.hpCost,
          calcText: show_calc_text && calc_text.hpCost,
          color: Colors.HP_RED,
        }),
      );
    }

    // mp cost
    if (ability_state.mpCost) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.mp_cost')}: `,
          valueText: ability_state.mpCost,
          calcText: show_calc_text && calc_text.mpCost,
          color: Colors.MP_PURPLE,
        }),
      );
    }

    // ap cost
    if (ability_state.apCost) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.ap_cost')}: `,
          valueText: ability_state.apCost,
          calcText: show_calc_text && calc_text.apCost,
          color: Colors.AP_ORANGE,
        }),
      );
    }

    // charges
    if (ability_state.charges) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.charges')}: `,
          valueText: ability_state.charges,
          calcText: show_calc_text && calc_text.charges,
          color: Colors.AP_ORANGE,
        }),
      );
    }

    // damage
    if (ability_state.damage) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.damage')}: `,
          valueText: ability_state.damage,
          calcText: show_calc_text && calc_text.damage,
          color: Colors.HP_RED,
        }),
      );
    }

    // healing
    if (ability_state.healing) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.healing')}: `,
          valueText: ability_state.healing,
          calcText: show_calc_text && calc_text.healing,
          color: Colors.AP_GREEN,
        }),
      );
    }

    // potency
    if (ability_state.potency) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.potency')}: `,
          valueText: (ability_state.potency % 1) === 0 ? ability_state.potency : ability_state.potency.toFixed(2),
          calcText: show_calc_text && calc_text.potency,
        }),
      );
    }

    // duration
    if (ability_state.duration) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.duration')}: `,
          valueText: (ability_state.duration / 1000).toFixed(1) + 's',
          calcText: show_calc_text && calc_text.duration,
        }),
      );
    }

    // effect range
    if (ability_state.effectRange) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.effect_range')}: `,
          valueText: ability_state.effectRange,
          calcText: show_calc_text && calc_text.effectRange,
        }),
      );
    }

    // bonus to the mana attribute
    if (ability_state.maBonus) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.ma_bonus')}: `,
          valueText: ability_state.maBonus,
          calcText: show_calc_text && calc_text.maBonus,
        }),
      );
    }

    // bonus to magical gravity
    if (ability_state.mgBonus) {
      _aspect_fields.push(
        new AbilityAspectField({
          labelText: `${text('game.mg_bonus')}: `,
          valueText: ability_state.mgBonus.toFixed(2),
          calcText: show_calc_text && calc_text.mgBonus,
        }),
      );
    }

    // add & position aspect fields
    if (_aspect_fields.length) {
      for (let i = 0; i < _aspect_fields.length; ++i) {
        const af = _aspect_fields[i];

        if (i === 0) {
          af.y = Math.round(_blurb.y + _blurb.height + 28);
        } else {
          const prev_af = _aspect_fields[i - 1];
          af.y = Math.round(prev_af.y + prev_af.height + 12);
        }

        this.addChild(af);
      }
    }

    // horizontal line under the blurb
    const hl = new PIXI.Graphics();
    hl.beginFill(Colors.BRIGHT_YELLOW, 0.2);
    hl.drawRect(0, 0, this.width, 2);
    hl.y = Math.round(_blurb.y + _blurb.height + 12);
    this.addChild(hl);

    this.dispose = () => {
      if (_aspect_fields) {
        for (const af of _aspect_fields) {
          af.dispose();
        }
      }

      this.removeChildren();
    };
  } catch (err) {
    logError(err, Object.assign(
      {
        module: 'components/tooltips/AbilityTooltip',
        func: 'constructor',
        show_calc_text,
      },
    ));
  }
};
AbilityTooltip.prototype = Object.create(PIXI.Container.prototype);
AbilityTooltip.prototype.constructor = AbilityTooltip;
export default AbilityTooltip;

const AbilityAspectField = function({ labelText, valueText, calcText, color }) {
  PIXI.Container.call(this);

  const _labelField = new PIXI.Text(labelText, {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(14),
    fill: 0xffffff,
    align: 'right',
    lineHeight: window.innerWidth > 800 ? 13 : 9,
    padding: 2,
  });
  this.addChild(_labelField);

  const _valueField = new PIXI.Text(valueText, {
    fontFamily: 'Courier New',
    fontSize: CanvasTools.dynamicFontSizeString(14),
    fill: color || Colors.BRIGHT_YELLOW,
    lineHeight: window.innerWidth > 800 ? 13 : 9,
    padding: 2,
  });
  _valueField.x = Math.round(_labelField.x + _labelField.width);
  this.addChild(_valueField);

  if (calcText) {
    const _calcField = new PIXI.Text(calcText, {
      fontFamily: 'Courier New',
      fontSize: CanvasTools.dynamicFontSizeString(12),
      fill: 0x999999,
      lineHeight: window.innerWidth > 800 ? 9 : 7,
      padding: 2,
    });

    _calcField.x = Math.round(_labelField.x + 10);
    _calcField.y = Math.round(_labelField.y + _labelField.height + 3);
    this.addChild(_calcField);
  }

  this.dispose = () => {
    this.removeChildren();
  };
};
AbilityAspectField.prototype = Object.create(PIXI.Container.prototype);
AbilityAspectField.prototype.constructor = AbilityAspectField;
