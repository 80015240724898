import { Dispatcher } from 'flux';

const TutorialDispatcher = Object.assign(new Dispatcher(), {
  NAME: 'TUTORIAL_DISPATCHER',

  START_TUTORIAL_BATTLE_01: 'START_TUTORIAL_BATTLE_01',

  handleTutorialAction: function(action) {
    this.dispatch({
      source: TutorialDispatcher.NAME,
      action
    });
  }
});
export default TutorialDispatcher;
