import { EventEmitter } from 'events';
import { io } from 'socket.io-client';
import Colors from '~/constants/Colors';
import { ApplicationDispatcher } from '~/flux/dispatchers';
import { registerDispatchHandlers } from '~/Tools';

// the stuff we serve:
let socket = null;
let playersOnline = 0;
let window_has_focus = true;

const ApplicationStore = Object.assign({}, EventEmitter.prototype, {
  ASSETS_LOADED: 'ASSETS_LOADED',
  CONNECT_ERROR_INVALID_TOKEN: 'CONNECT_ERROR_INVALID_TOKEN',
  GAMESPARKS_ERROR: 'GAMESPARKS_ERROR',
  NUM_PLAYERS_ONLINE: 'NUM_PLAYERS_ONLINE',
  SOCKET_CONNECTED: 'SOCKET_CONNECTED',
  SOCKET_DISCONNECTED: 'SOCKET_DISCONNECTED',
  WINDOW_LOST_FOCUS: 'WINDOW_LOST_FOCUS',
  WINDOW_GOT_FOCUS: 'WINDOW_GOT_FOCUS',

  getAll() {
    return {
      socket,
      playersOnline,
      window_has_focus,
    };
  }
});
export default ApplicationStore;

ApplicationDispatcher.register(registerDispatchHandlers({
  [ApplicationDispatcher.CONNECT_API_SOCKET]: connectApiSocket,
  [ApplicationDispatcher.ASSETS_LOADED]: onAssetsLoaded,
}));

function connectApiSocket({ firebase_token, kong_token, kong_userId, steam_token, is_token_refresh }) {
  try {
    socket?.close();
    socket = io(process.env.DT_API_ADDRESS || 'http://localhost:3001', {
      auth: {
        firebase_token,
        kong_token,
        kong_userId,
        steam_token
      },
      secure: true,
      timeout: 3000,
    });

    socket.on('connected', onSocketConnected);
    socket.on("connect_error", (err) => {
      console.error(`connect_error due to ${err.message}`);
    });
    socket.io.on("reconnect_error", (err) => {
      console.error(`reconnect_error due to ${err.message}`);
    });
    socket.io.on("reconnect_failed", () => {
      console.error('reconnect_failed');
    });
    socket.on("disconnect", (reason) => {
      console.warn('socket disconnect. reason: ', reason);
      if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });
    socket.on('error', onSocketError);
    socket.on('api_server_error', onApiServerError);
    socket.on('noRedlock', onNoRedlock);
    socket.on('numPlayersOnline', onNumPlayersOnline);
    socket.on('battleEngineError', onBattleEngineError);

    if (is_token_refresh) {
      socket.do_reconnect_player = true;
    }
  } catch (err) {
    logError(err, {
      module: 'ApplicationStore',
      func: 'connectApiSocket'
    });
  } 
}

function onSocketError(err) {
  logError(err);
}

function onApiServerError(data) {
  const { requestName, reqData, message, stack } = data;

  // log error data to the console
  console.group('************************\nAPI Server Error');
  console.error(stack);
  console.error('requestName', requestName);
  console.error('reqData', reqData);
  console.groupEnd();

  // surface to the player
  $addMessageLogMessage(`API server error [${requestName}]: ${message}`, Colors.RED);
}

function onNoRedlock(lock_name) {
  console.warn('no redlock for request:', lock_name);
}

function onAssetsLoaded() {
  ApplicationStore.emit(ApplicationStore.ASSETS_LOADED);
}

function onSocketConnected() {
  console.info('socket connected');
  ApplicationStore.emit(ApplicationStore.SOCKET_CONNECTED, socket);
}

function onNumPlayersOnline(data) {
  playersOnline = data.num;
  ApplicationStore.emit(ApplicationStore.NUM_PLAYERS_ONLINE, playersOnline);
}

function onBattleEngineError(err) {
  logError(err, {
    module: 'ApplicationStore',
    func: 'onBattleEngineError'
  });
}

window.addEventListener('focus', onWindowActive);
window.addEventListener('blur', onWindowInactive);
function onWindowInactive() {
  window_has_focus = false;
  ApplicationStore.emit(ApplicationStore.WINDOW_LOST_FOCUS);
}
function onWindowActive() {
  window_has_focus = true;
  ApplicationStore.emit(ApplicationStore.WINDOW_GOT_FOCUS);
}