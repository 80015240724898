import BattleCalc from 'dt-common/battle_engine/BattleCalc';
import Audio from '~/Audio';

const SleightOfHandRendering = {
	render: function( data, gameBoard, isCaverns ) {
		const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
		const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

		if (!data.reflection && render_movement) {
			actorSprite.puppet.getMovements().magicUpGentle.movePuppet({ transitTime });
		}

		TweenMax.delayedCall( transitTime*0.31, Audio.play, ['dodge'] );
		TweenMax.delayedCall( transitTime*0.68, Audio.play, ['dodge'] );
	}
}
export default SleightOfHandRendering;